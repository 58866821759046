import { Button } from 'react-bootstrap';

type TransportOrderButtonsProps = {
    disabled?: boolean;
    onCancelButtonClick: () => void
    onSubmitButtonClick: () => void
}

export const TransportOrderButtons = ({
        disabled = true,
        onCancelButtonClick,
        onSubmitButtonClick,
    }: TransportOrderButtonsProps) => (
    <div
        className={`d-flex flex-row justify-content-end align-items-baseline py-3 ${!disabled && 'sticky-bottom bg-body-tertiary'}`}>
        {!disabled && <p className="me-auto mb-0">Wprowadzono zmiany</p>}
        {/*<Button className="mx-2" variant="danger" disabled={disabled} onClick={onCancelButtonClick}>Anuluj</Button>*/}
        <Button variant="primary" disabled={disabled} onClick={onSubmitButtonClick}>Zapisz</Button>
    </div>
);
