import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { CompanyDetails } from '../../domain/companyDetails';
import { TransportOrderConfig } from '../../domain/transportOrderConfig';
import { TransportOrderPolicy } from '../../domain/transportOrderPolicy';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

type CombinedSettingsTypes = {
    companyDetails: CompanyDetails;
    transportOrderPolicy: TransportOrderPolicy;
    transportOrderConfig: TransportOrderConfig;
}

export const useSettingsRepository = () => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['companySettings'],
        placeholderData: keepPreviousData,
        queryFn: async (): Promise<CombinedSettingsTypes> => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/settings`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return await response.json() as CombinedSettingsTypes;
        },
    });

    return {
        ...query,
    };
};
