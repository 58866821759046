import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { CreateContractorDto } from './dto/createContractorDto';

export const useCreateContractorRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const createContractor = useMutation({
        mutationFn: (newContractor: CreateContractorDto) => createContractorFunction(newContractor),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contractors'] });
            queryClient.refetchQueries({ queryKey: ['contractors'] });
        }
    });
    const createContractorFunction = async (newContractor: CreateContractorDto): Promise<string> => {
        const token = await getJwtToken();

        const response = await fetch(`${BACKEND_URL}/contractors`, {
            method: 'POST',
            body: JSON.stringify(newContractor),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        return await response.text(); // returns id
    };

    return {
        createContractor: (newContractor: CreateContractorDto) => createContractor.mutateAsync(newContractor),
    };
};