import { ReactNode } from 'react';
import { Button, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { Notification, NotificationType } from '../../edit/useTransportOrderNotifications';
import { Contractor } from '../../../../domain/contractor';
import { TransportOrder } from '../../../../domain/transportOrder';

type ChangedContractorDetailsType = Omit<NonNullable<TransportOrder['contractor']>, 'contact'>;

const ChangedContractorDetails = ({ contractor }: { contractor: ChangedContractorDetailsType }) => {
    const { name, nip, address, email, phoneNumber } = contractor;

    return <Form.Group className="d-flex flex-column me-4">
        <Form.Label className="m-0">Aktualne dane podwykonawcy:</Form.Label>
        <Form.Text>{name}</Form.Text>
        <Form.Text>{address}</Form.Text>
        <Form.Text>NIP: {nip}</Form.Text>
        <Form.Text>email: {email}</Form.Text>
        <Form.Text>tel: {phoneNumber}</Form.Text>
    </Form.Group>;
};

type ChangedContactDetailsType = NonNullable<NonNullable<TransportOrder['contractor']>['contact']>;

const ChangedContactDetails = ({ contact }: { contact: ChangedContactDetailsType }) => {
    const { name, email, phoneNumber } = contact;

    return <Form.Group className="d-flex flex-column me-4">
        <Form.Label className="m-0">Aktualne dane osoby kontaktowej:</Form.Label>
        <Form.Text>{name}</Form.Text>
        <Form.Text>email: {email}</Form.Text>
        <Form.Text>tel: {phoneNumber}</Form.Text>
    </Form.Group>;
};

const ChangedContractorAndContactDetails = ({ contractor, contact }: {
    contractor: ChangedContractorDetailsType,
    contact: ChangedContactDetailsType
}) => {
    return <div className="d-flex flex-row">
        <ChangedContractorDetails contractor={contractor} />
        <ChangedContactDetails contact={contact} />
    </div>;
};

type NotificationMessageProps = {
    message: string;
    updateActionLabel?: string;
    changeActionLabel?: string;
    updateAction?: () => Promise<void> | void;
    changeAction?: () => void;
    details?: ReactNode;
};


const NotificationMessage = ({
    message,
    updateActionLabel = 'Zaktualizuj',
    changeActionLabel = 'Zmień',
    updateAction,
    changeAction,
    details,
}: NotificationMessageProps) => <>
    <p>{message}</p>
    {details}
    <div className="mt-2">
        {updateAction && <Button variant="primary" className="me-4" onClick={updateAction}>{updateActionLabel}</Button>}
        {changeAction && <Button variant="primary" className="" onClick={changeAction}>{changeActionLabel}</Button>}
    </div>
</>;

export const checkContractor = (
    contractors: Array<Contractor>,
    addNotification: (notification: Notification) => void,
    handleContractorChange: (contractor?: TransportOrder['contractor']) => void,
    updateContractorDetails: (newContractor: { id: string; contactId?: string } | undefined) => void,
) => {
    const didContractorDetailsChanged = (assignedContractor: NonNullable<TransportOrder['contractor']>, sourceContractor: Contractor) =>
        assignedContractor.name !== sourceContractor.name ||
        assignedContractor.nip !== sourceContractor.nip ||
        assignedContractor.phoneNumber !== sourceContractor.phoneNumber ||
        assignedContractor.address !== sourceContractor.address ||
        assignedContractor.email !== sourceContractor.email;


    const didContactChange = (assignedContact: NonNullable<NonNullable<TransportOrder['contractor']>['contact']>, foundContact: Contractor['contacts']['0']) =>
        assignedContact.name !== foundContact.name ||
        assignedContact.email !== foundContact.email ||
        assignedContact.phoneNumber !== foundContact.phoneNumber;

    const buildNotification = (title: string, message: string | ReactNode, type: NotificationType = 'ContractorChangeNotification'): Notification => ({
        id: uuidv4(),
        variant: 'warning',
        type,
        title,
        message,
    });

    return (assignedContractor: NonNullable<TransportOrder['contractor']>) => {
        const sourceContractor = contractors?.find(({ id }) => assignedContractor.id === id);

        if (!sourceContractor) {
            addNotification(buildNotification(
                'Przypisany podwykonawca nie istnieje',
                <NotificationMessage
                    message="Przypisany podwykonawca nie istnieje."
                    changeActionLabel="Dodaj podwykonawce"
                    changeAction={() => handleContractorChange(undefined)}
                />,
            ));
            return;
        }

        const isBlacklisted = sourceContractor.blacklist;

        if (isBlacklisted) {
            addNotification(buildNotification(
                'Podwykonawca na czarnej liście',
                <NotificationMessage
                    message="Podwykonawca znajduje się na czarnej liście."
                />,
                'ContractorOnBlacklist'
            ))
        }

        const assignedContact = assignedContractor.contact;
        const sourceContact = assignedContact && sourceContractor.contacts.find(({ id }) => assignedContact.id === id);

        const handleUpdateAction = () => updateContractorDetails({
            id: assignedContractor.id,
            contactId: assignedContractor.contact?.id,
        });

        if (didContractorDetailsChanged(assignedContractor, sourceContractor)) {
            if (assignedContact) {
                if (!sourceContact) {
                    addNotification(buildNotification(
                        'Dane przypisanego podwykonawcy zmieniły się.',
                        <NotificationMessage
                            message="Dane przypisanego podwykonawcy zmieniły się. Dodatkowo, wybrany kontakt nie jest juz dostepny."
                            updateAction={handleUpdateAction}
                            changeAction={() => handleContractorChange({
                                id: sourceContractor.id,
                                nip: sourceContractor.nip,
                                name: sourceContractor.name,
                                address: sourceContractor.address,
                                email: sourceContractor.email,
                                phoneNumber: sourceContractor.phoneNumber,
                                contact: undefined,
                            })}
                            details={<ChangedContractorDetails contractor={sourceContractor} />}
                        />,
                    ));
                    return;
                }

                if (didContactChange(assignedContact, sourceContact)) {
                    addNotification(buildNotification(
                        'Dane przypisanego podwykonawcy zmieniły się.',
                        <NotificationMessage
                            message="Dane przypisanego podwykonawcy oraz osoby kontaktowej zmieniły się."
                            updateAction={handleUpdateAction}
                            changeAction={() => handleContractorChange({
                                id: sourceContractor.id,
                                nip: sourceContractor.nip,
                                name: sourceContractor.name,
                                address: sourceContractor.address,
                                email: sourceContractor.email,
                                phoneNumber: sourceContractor.phoneNumber,
                                contact: sourceContact,
                            })}
                            details={<ChangedContractorAndContactDetails contractor={sourceContractor}
                                                                         contact={sourceContact} />}
                        />,
                    ));
                    return;
                }

            }
            addNotification(buildNotification(
                'Dane przypisanego podwykonawcy zmieniły się.',
                <NotificationMessage
                    message="Dane przypisanego podwykonawcy zmieniły się."
                    updateAction={handleUpdateAction}
                    changeAction={() => handleContractorChange({
                        id: sourceContractor.id,
                        nip: sourceContractor.nip,
                        name: sourceContractor.name,
                        address: sourceContractor.address,
                        email: sourceContractor.email,
                        phoneNumber: sourceContractor.phoneNumber,
                        contact: assignedContact,
                    })}
                    details={<ChangedContractorDetails contractor={sourceContractor} />}
                />,
            ));
            return;
        }

        if (!assignedContact) {
            return;
        }

        if (!sourceContact) {
            addNotification(buildNotification(
                'Przypisany kontakt nie istnieje',
                <NotificationMessage
                    message="Przypisany kontakt nie istnieje."
                    updateAction={handleUpdateAction}
                    changeAction={() => handleContractorChange({
                        ...assignedContractor,
                        contact: undefined,
                    })}
                />,
            ));
            return;
        }

        if (didContactChange(assignedContact, sourceContact)) {
            addNotification(buildNotification(
                'Dane przypisanego kontaktu zmienił się',
                <NotificationMessage
                    message="Dane przypisanego kontaktu zmieniły się."
                    updateAction={handleUpdateAction}
                    changeAction={() => handleContractorChange({
                        ...assignedContractor,
                        contact: sourceContact,
                    })}
                    details={<ChangedContactDetails contact={sourceContact} />}
                />,
            ));

            return;
        }
    };
};
