import React, { ReactElement } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PageTitle } from '../../components/PageTitle';
import { RefreshButton } from '../../components/RefreshButton';
import { Client as ClientType } from '../../domain/client';
import { useClientRepository } from '../../repository/clients/useClientRepository';
import { useModifyClientRepository } from '../../repository/clients/useModifyClientRepository';
import { useRefetchClientData } from '../../repository/clients/useRefetchClientData';
import { Error } from '../Error';
import { ClientActionsCard } from './ClientActionsCard';
import { ClientButtons } from './ClientButtons';
import { ClientContacts } from './ClientContacts';
import { ClientDetails } from './ClientDetails';
import { ClientLoadingSkeleton } from './ClientLoadingSkeleton';
import { useClientForm } from './useClientForm';
import { useClientChanged } from './useClientFormChange';

export const ClientWrapper = () => {
    const { clientId } = useParams<{ clientId: string }>();

    const {
        data: client,
        isPending,
        isLoading,
        isRefetching,
        isError,
    } = useClientRepository(clientId!);

    const refetchClient = useRefetchClientData();

    if (isPending) {
        return <ClientLoadingSkeleton />;
    }

    if (isError || !clientId) {
        return <Error message={`Klient o id ${clientId} nie istnieje`} />;
    }

    return <Client
        fetchedClient={client}
        reloadClient={() => refetchClient(clientId)}
        isRefreshing={isLoading || isRefetching}
    />;
};

const tabIds = ['details'];
type TabId = 'details'

const tabIdFromString = (potentialTabId?: string | null): TabId => {
    if (!potentialTabId || !tabIds.includes(potentialTabId)) {
        return 'details';
    }

    return potentialTabId as TabId;
};

type TabItem = {
    id: TabId;
    text: string;
    content: ReactElement;
};


type ClientProps = {
    fetchedClient: ClientType;
    isRefreshing: boolean;
    reloadClient: () => void;
}

const Client = ({ fetchedClient, isRefreshing, reloadClient }: ClientProps) => {
    const { hash } = useLocation();
    const navigate = useNavigate();

    const activeTabId = tabIdFromString(hash.replaceAll('#', ''));

    const tabs: Array<TabItem> = [
        {
            id: 'details',
            text: 'Szczegóły',
            content: <ClientDetailsUnderTab fetchedClient={fetchedClient} />,
        },
        // pliki
        // szablony
    ];

    return <>
        <div className="d-flex flex-row">
            <PageTitle className="me-2">Klient</PageTitle>
            <RefreshButton isRefreshing={isRefreshing} refreshFunction={reloadClient} />
        </div>
        <Tabs
            mountOnEnter
            unmountOnExit
            className="border-0"
            activeKey={activeTabId}
            onSelect={(eventKey) => navigate(`#${tabIdFromString(eventKey)}`)}
        >
            {tabs.map((tabItem) => (
                <Tab key={tabItem.id} eventKey={tabItem.id} title={tabItem.text}>
                    {tabItem.content}
                </Tab>
            ))}
        </Tabs>
    </>;
};

export const ClientDetailsUnderTab = ({ fetchedClient }: { fetchedClient: ClientType }) => {
    const clientForm = useClientForm(fetchedClient);
    const clientFormChanges = useClientChanged(clientForm, fetchedClient);

    const { modifyClient } = useModifyClientRepository();

    const handleSubmitClientClick = async () => {
        await modifyClient({
            id: (clientForm.id.value as string),
            name: clientForm.name.value,
            nip: clientForm.nip.value,
            address: clientForm.address.value,
            email: clientForm.email.value,
            phoneNumber: clientForm.phoneNumber.value,
            additionalInfo: clientForm.additionalInfo.value,
            contacts: clientForm.contacts.value,
        });

        toast.success('Edycja klienta powiodła się');
    };

    return (
        <div className="d-flex flex-row">
            <div className="d-flex flex-column flex-grow-1 me-4">
                <ClientDetails client={clientForm} />
                <ClientContacts contacts={clientForm.contacts} />
                <ClientButtons
                    disabled={!clientFormChanges.anyChanges}
                    onSubmitButtonClick={handleSubmitClientClick}
                    onCancelButtonClick={() => alert('okodować wycofanie zmian na fomularzu')}
                />
            </div>
            <ClientActionsCard client={fetchedClient} />
        </div>
    );
};