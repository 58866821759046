import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { Client } from '../../domain/client';
import { BACKEND_URL } from '../backend-url';

export const useClientRepository = (clientId: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['clients', { id: clientId }],
        staleTime: 45 * 60 * 1000, // 45 minutes
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/clients/${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            return await response.json() as Client;
        },
    });

    return {
        ...query,
    };
};