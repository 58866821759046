import { Form } from 'react-bootstrap';

import { TransportOrder } from '../../../../domain/transportOrder';
import { FormElement } from '../../../../components/FormElement';
import { EditableLink } from '../../../../components/EditableLink';

type ContractorType = TransportOrder['contractor'];

type TransportOrderContractorReadonlyProps = {
    contractor: NonNullable<ContractorType>;
    onContractorEditClick: () => void;
}

export const TransportOrderContractorReadonly = ({
        contractor,
        onContractorEditClick,
    }: TransportOrderContractorReadonlyProps) => (
    <>
        <FormElement headingText="Podwykonawca">
            <EditableLink
                to={`/contractors/${contractor.id}`}
                onEditClick={onContractorEditClick}>{contractor.name}
            </EditableLink>
        </FormElement>
        <div className="d-flex flex-row">
            <Form.Group className="d-flex flex-column me-4">
                <Form.Label className="m-0">Szczegóły</Form.Label>
                <Form.Text>{contractor.name}</Form.Text>
                <Form.Text>{contractor.address}</Form.Text>
                <Form.Text>NIP: {contractor.nip}</Form.Text>
                <Form.Text>email: {contractor.email}</Form.Text>
                <Form.Text>tel: {contractor.phoneNumber}</Form.Text>
            </Form.Group>
            {contractor.contact && <Form.Group className="d-flex flex-column">
                <Form.Label className="m-0">Osoba kontaktowa</Form.Label>
                <Form.Text>{contractor.contact.name}</Form.Text>
                <Form.Text>email: {contractor.contact.email}</Form.Text>
                <Form.Text>tel: {contractor.contact.phoneNumber}</Form.Text>
            </Form.Group>}
        </div>
    </>
);
