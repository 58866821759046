import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { TransportOrderPolicy } from '../../../domain/transportOrderPolicy';

export type TransportOrderPolicyFormItem = TransportOrderPolicy[0] & {
    editMode: boolean;
    id: string;
}

export const useTransportOrderPolicyForm = (initialPolicy: TransportOrderPolicy) => {
    const [transportOrderPolicy, setTransportOrderPolicy] = useState<Array<TransportOrderPolicyFormItem>>(initialPolicy.map((item) => ({
        ...item,
        editMode: false,
        id: uuidv4(),
    })) || []);

    return {
        transportOrderPolicy,
        setTransportOrderPolicy: (newTransportOrderPolicy: Array<TransportOrderPolicyFormItem>) => setTransportOrderPolicy(newTransportOrderPolicy),
    };
};
