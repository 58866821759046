import { Card } from 'react-bootstrap';
import { Ban, Copy } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

import { ActionItem } from '../../components/ActionItem';
import { Contractor } from '../../domain/contractor';
import { useContractorBlacklistRepository } from '../../repository/contractors/useContractorBlacklistRepository';

type ContractorActionsCardProps = {
    contractor: Contractor;
    onCreateNewTransportOrder: () => void;
}

export const ContractorActionsCard = (props: ContractorActionsCardProps) => {
    const { contractor, onCreateNewTransportOrder } = props;

    const { addContractorToBlacklist, removeContractorFromBlacklist} = useContractorBlacklistRepository();

    const addToBlacklist = async () => {
        await addContractorToBlacklist(contractor.id);
        toast.success('Pomyślnie dodano podwykonawcę do czarnej listy');
    }

    const removeFromBlacklist = async () => {
        await removeContractorFromBlacklist(contractor.id);
        toast.success('Pomyślnie usunięto podwykonawcę z czarnej listy');
    }

    return (
        <Card className="mb-auto">
            <Card.Body>
                <ul className="list-unstyled">
                    <li>
                        <ActionItem onClick={onCreateNewTransportOrder}>
                            <Copy />{' '}Nowe zlecenie
                        </ActionItem>
                    </li>
                    {!contractor.blacklist && <li>
                        <ActionItem onClick={addToBlacklist}>
                            <Ban />{' '}Dodaj do czarnej listy
                        </ActionItem>
                    </li>}
                    {contractor.blacklist && <li>
                        <ActionItem onClick={removeFromBlacklist}>
                            <Ban />{' '}Usuń z czarnej listy
                        </ActionItem>
                    </li>}

                </ul>
            </Card.Body>
        </Card>
    );
}
