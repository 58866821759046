import { useState } from 'react';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';

import { FormElement } from '../../../components/FormElement';
import { TransportOrder } from '../../../domain/transportOrder';

type ShipmentLoadingType = TransportOrder['loading'];
type ShipmentLoadingItemType = ShipmentLoadingType[0];

type ShipmentLoadingProps = {
    shipmentLoading: ShipmentLoadingType;
    setShipmentLoading: (newValue: ShipmentLoadingType) => void;
    context: {
        isTransportOrderEdit: boolean;
    };
}

const DEFAULT_ACTIVE_TAB = '0-loading-tab';

export const ShipmentLoading = ({ shipmentLoading, setShipmentLoading, context }: ShipmentLoadingProps) => {
    const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);

    const handleLoadingItemChange = (changedShipmentItem: ShipmentLoadingItemType, index: number) => {
        setShipmentLoading(shipmentLoading.map((loadingItem, itemIndex) => {
            if (index === itemIndex) {
                return changedShipmentItem;
            }

            return loadingItem;
        }));
    };

    const handleAddItemClick = () => {
        const shipmentLoadingLength = shipmentLoading.length;

        setShipmentLoading([...shipmentLoading, {
            address: '',
            date: new Date(),
            endDate: new Date(),
            time: '',
            loadingNumber: '',
            additionalInfo: '',
            completed: false,
        }]);

        setActiveTab(`${shipmentLoadingLength}-loading-tab`);
    };

    const handleRemoveItemClick = (index: number) => {
        setShipmentLoading(shipmentLoading.filter((_, itemIndex) => itemIndex !== index));
        setActiveTab(DEFAULT_ACTIVE_TAB);
    };

    const TabTitle = ({ index }: { index: number }) => <>
        {index + 1}. załadunek <XLg className="icon-link-hover size-20-hover" onClick={() => handleRemoveItemClick(index)} />
    </>;

    return (
        <FormElement headingText="Załadunek">
            {shipmentLoading.length === 1 ?
                <ShipmentLoadingItem
                    loadingItem={shipmentLoading[0]}
                    onChange={(changedItem) => handleLoadingItemChange(changedItem, 0)}
                    showCompleted={context.isTransportOrderEdit}
                /> :
                <Tabs
                    className="mb-2"
                    activeKey={activeTab}
                    onSelect={(tabKey) => setActiveTab(tabKey || DEFAULT_ACTIVE_TAB)}
                >
                    {shipmentLoading.map((loadingItem, index) => (
                        <Tab title={<TabTitle index={index} />} eventKey={`${index}-loading-tab`}>
                            <ShipmentLoadingItem
                                loadingItem={loadingItem}
                                onChange={(changedItem) => handleLoadingItemChange(changedItem, index)}
                                showCompleted={context.isTransportOrderEdit}
                            />
                        </Tab>
                    ))}
                </Tabs>
            }
            <div className="d-flex justify-content-center">
                <Button variant="link" onClick={handleAddItemClick} className="text-decoration-none">
                    + dodaj kolejny załadunek
                </Button>
            </div>
        </FormElement>
    );
};

const ShipmentLoadingItem = ({ loadingItem, onChange, showCompleted }: {
    loadingItem: ShipmentLoadingItemType;
    onChange: (changedItem: ShipmentLoadingItemType) => void;
    showCompleted: boolean;
}) => {
    const { date, endDate, time, address, loadingNumber, additionalInfo } = loadingItem;
    const dateAsString = date.toISOString().split('T')[0];
    const endDateAsString = endDate.toISOString().split('T')[0];

    const handleShipmentChange = (newShipment: ShipmentLoadingItemType) => {
        onChange(newShipment);
    };

    const handleLoadingDateChange = (newDate: Date) => {
        if (newDate.getTime() > endDate.getTime()) {
            handleShipmentChange({
                ...loadingItem,
                date: newDate,
                endDate: newDate,
            });

            return;
        }

        handleShipmentChange({
            ...loadingItem,
            date: newDate,
        });
    };

    const handleLoadingCompletedChange = (completed: boolean) => {
        handleShipmentChange({
            ...loadingItem,
            completed: completed,
        })
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <Form.Group className="mb-3">
                    <Form.Label className="m-0 fw-bold">Data</Form.Label>
                    <div className="d-flex flex-row align-items-baseline">
                        <Form.Control
                            className="w-auto"
                            type="date"
                            placeholder="Data załadunku (od)"
                            value={dateAsString}
                            onChange={(e) => handleLoadingDateChange(new Date(e.target.value))}
                        />
                        <span className="mx-1">-</span>
                        <Form.Control
                            className="w-auto"
                            type="date"
                            placeholder="Data załadunku (do)"
                            value={endDateAsString}
                            onChange={(e) => handleShipmentChange({
                                ...loadingItem,
                                endDate: new Date(e.target.value),
                            })}
                        />
                    </div>
                </Form.Group>
                {showCompleted && <Form.Group className="mb-3">
                    <Form.Label className="m-0 fw-bold">Status</Form.Label>
                    <Form.Check
                        type="checkbox"
                        label="Załadowane"
                        onChange={(e) => handleLoadingCompletedChange(e.target.checked)}
                        checked={loadingItem.completed}
                    />
                </Form.Group>}
            </div>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bold">Godziny załadunku</Form.Label>
                <Form.Control
                    className="w-auto"
                    type="text"
                    placeholder="Godziny załadunku"
                    value={time}
                    onChange={(e) => handleShipmentChange({
                        ...loadingItem,
                        time: e.target.value,
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bold">Adres</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Adres załadunku"
                    value={address}
                    onChange={(e) => handleShipmentChange({
                        ...loadingItem,
                        address: e.target.value,
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bold">Numer załadunku</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Numer załadunku"
                    value={loadingNumber}
                    onChange={(e) => handleShipmentChange({
                        ...loadingItem,
                        loadingNumber: e.target.value,
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bold">Uwagi</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Uwagi do załadunku"
                    value={additionalInfo}
                    onChange={(e) => handleShipmentChange({
                        ...loadingItem,
                        additionalInfo: e.target.value,
                    })}
                />
            </Form.Group>
        </>
    );
};