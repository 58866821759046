import { Form, InputGroup } from 'react-bootstrap';

import { FormElement } from '../../../components/FormElement';
import { TransportOrder } from '../../../domain/transportOrder';

type FreightType = TransportOrder['freight'];

type FreightProps = {
    freight: FreightType;
    setFreight: (newValue: FreightType) => void;
}

export const Freight = ({ freight, setFreight }: FreightProps) => {
    const handleFreightChange = (newFreight: FreightType) => {
        setFreight(newFreight);
    };

    const handleValueChange = (newValue: FreightType['value']) => handleFreightChange({
        ...freight,
        value: newValue,
    });
    const handleCurrencyChange = (newCurrency: FreightType['currency']) => handleFreightChange({
        ...freight,
        currency: newCurrency,
    });
    const handleVatRateChange = (newVatRate: number) => handleFreightChange({
        ...freight,
        vatRate: newVatRate,
    });

    const handlePaymentDaysRateChange = (newPaymentDays: number) => handleFreightChange({
        ...freight,
        paymentDays: newPaymentDays,
    });

    return (
        <FormElement className="d-inline-block" headingText="Fracht">
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bolder">Kwota</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        value={freight.value}
                        onChange={(e) => handleValueChange(e.target.value)}
                    />
                    <Form.Select
                        value={freight.currency}
                        onChange={(e) => handleCurrencyChange(e.target.value as FreightType['currency'])}
                    >
                        <option value="EUR">EUR</option>
                        <option value="PLN">PLN</option>
                    </Form.Select>
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bolder">VAT</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="number"
                        value={freight.vatRate}
                        onChange={(e) => handleVatRateChange(Number(e.target.value))}/>
                    <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label className="m-0 fw-bolder">Termin płatności</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="number"
                        value={freight.paymentDays}
                        onChange={(e) => handlePaymentDaysRateChange(Number(e.target.value))}/>
                    <InputGroup.Text>dni</InputGroup.Text>
                </InputGroup>
            </Form.Group>
        </FormElement>
    );
};

type FreightProfitProps = {
    freight: FreightType;
    setFreight: (newValue: FreightType) => void;
}

export const FreightProfit = ({ freight, setFreight }: FreightProfitProps) => {
    const handleClientValueChange = (newValue: FreightType['clientValue']) => setFreight({
        ...freight,
        clientValue: newValue === '' ? undefined : newValue,
    });

    const profit = Number(freight.clientValue) - Number(freight.value);

    return (
        <FormElement className="d-inline-block" headingText="Oferta klienta*">
            <Form.Label className="fst-italic text-secondary">(*) Niewidoczny na wygenerowanym pdf</Form.Label>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bolder">Kwota</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        value={freight.clientValue}
                        onChange={(e) => handleClientValueChange(e.target.value)}
                    />
                    <InputGroup.Text>{freight.currency}</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <span>
                Marża: {isNaN(profit) ? '?' : <span className={profit > 0 ? 'text-success' : 'text-danger'}>{profit} {freight.currency}</span>}
            </span>
        </FormElement>
    );
}