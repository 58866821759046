import { Button } from 'react-bootstrap';

type ClientButtonsProps = {
    disabled?: boolean;
    onCancelButtonClick: () => void
    onSubmitButtonClick: () => void
}

export const ClientButtons = ({
        disabled = false,
        onCancelButtonClick,
        onSubmitButtonClick,
    }: ClientButtonsProps) => (
    <div className="d-flex flex-row justify-content-end">
        {/*<Button className="mx-2" variant="danger" disabled={disabled} onClick={onCancelButtonClick}>Anuluj</Button>*/}
        <Button variant="primary" disabled={disabled} onClick={onSubmitButtonClick}>Zapisz</Button>
    </div>
);
