import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { EditableComponent } from './EditableComponent';

type EditableLinkProps = {
    children: ReactNode
    to: string;
    onEditClick?: () => void;
}

const noop = () => {
};

export const EditableLink = ({ children, to, onEditClick = noop }: EditableLinkProps) => (
    <EditableComponent onEditClick={onEditClick}>
        <Link className="text-decoration-none"
              onClick={e => e.stopPropagation()}
              to={to}>
            {children}
        </Link>
    </EditableComponent>
);
