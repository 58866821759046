import Table from 'react-bootstrap/Table';

import { ContractorTransportOrdersTableHeader } from './ContractorTransportOrdersTableHeader';
import { ContractorTransportOrdersTableRow } from './ContractorTransportOrdersTableRow';
import { TransportOrder } from '../../../domain/transportOrder';

type ContractorTransportOrdersTableProps = {
    transportOrders: TransportOrder[];
}

export const ContractorTransportOrdersTable = ({ transportOrders }: ContractorTransportOrdersTableProps) => {
    return <Table striped bordered hover>
        <ContractorTransportOrdersTableHeader />
        <tbody>
        {transportOrders.map((transportOrder) => <ContractorTransportOrdersTableRow
            key={`contractor-transport-order-${transportOrder.id}`}
            transportOrdersItem={transportOrder}
        />)}
        </tbody>
    </Table>;
};
