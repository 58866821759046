import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { CreateClientDto } from './dto/createClientDto';

export const useCreateClientRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const createClient = useMutation({
        mutationFn: (newClient: CreateClientDto) => createClientFunction(newClient),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['clients'] });
            queryClient.refetchQueries({ queryKey: ['clients'] });
        }
    });
    const createClientFunction = async (newClient: CreateClientDto): Promise<string> => {
        const token = await getJwtToken();

        const response = await fetch(`${BACKEND_URL}/clients`, {
            method: 'POST',
            body: JSON.stringify(newClient),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        return await response.text(); // returns id
    };

    return {
        createClient: (newClient: CreateClientDto) => createClient.mutateAsync(newClient),
    };
};