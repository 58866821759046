import { Client } from '../../domain/client';
import { ClientType } from './useClientForm';

type ClientChanges = {
    anyChanges: boolean;
    changes: Array<keyof ClientType>;
}
export const useClientChanged = (client: ClientType, originalClient: Client): ClientChanges => {
    const checkStringField = (a: string, b: string, name: keyof ClientType): Array<keyof ClientType> => a !== b ? [name] : [];

    const checkContacts = (a: Client['contacts'], b: Client['contacts']): Array<keyof ClientType> => {
        const contactsChanged = (() => {
            if (a.length !== b.length) {
                return true;
            }

            return a.some((aItem, index) => {
                const bItem = b[index];

                return aItem.name !== bItem.name || aItem.email !== bItem.email || aItem.phoneNumber !== bItem.phoneNumber;
            });
        })();

        return contactsChanged ? ['contacts'] : [];
    };

    const changes: Array<keyof ClientType> = [
        ...checkStringField(client.name.value, originalClient.name, 'name'),
        ...checkStringField(client.address.value, originalClient.address, 'address'),
        ...checkStringField(client.nip.value, originalClient.nip, 'nip'),
        ...checkStringField(client.phoneNumber.value, originalClient.phoneNumber, 'phoneNumber'),
        ...checkStringField(client.email.value, originalClient.email, 'email'),
        ...checkStringField(client.additionalInfo.value, originalClient.additionalInfo, 'additionalInfo'),
        ...checkContacts(client.contacts.value, originalClient.contacts),
    ];

    return {
        anyChanges: changes.length > 0,
        changes,
    };
};
