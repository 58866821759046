import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { EditableTransportOrderBadge } from '../../../components/EditableTransportOrderBadge';

import { TransportOrder, TransportOrderStatus } from '../../../domain/transportOrder';
import {
    useChangeTransportOrderStatusRepository,
} from '../../../repository/transport-orders/useChangeTransportOrderStatusRepository';

const TransportOrderAddressCell = ({ address, isShortened }: { address: string; isShortened: boolean }) => (
    <td className={`${isShortened ? `text-truncate` : ''}`} style={{ minWidth: 250, maxWidth: 250 }}>
        {address}
    </td>
);

const dateToString = (date: Date) =>
    date.toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

const buildAddress = (items: TransportOrder['loading'] | TransportOrder['unloading']) => {
    if (items.length === 1) {
        return items[0].address;
    }

    return items.map(item => item.address).join(', ');
}

const buildDate = (items: TransportOrder['loading'] | TransportOrder['unloading']) => {
    const buildText = (from: Date, to: Date) => {
        const fromText = dateToString(from);
        const toText = dateToString(to);

        return `${fromText}${fromText === toText ? '' : `-${toText}`}`;
    }

    if (items.length === 1) {
        return buildText(items[0].date, items[0].endDate);
    }

    const firstDateFrom = [...items].sort((a, b) => a.date.getTime() - b.date.getTime())[0];
    const lastDateTo = [...items].sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0];

    return buildText(firstDateFrom.date, lastDateTo.endDate);
}

type ContractorTransportOrdersTableRowProps = {
    transportOrdersItem: TransportOrder;
}

export const ContractorTransportOrdersTableRow = ({ transportOrdersItem }: ContractorTransportOrdersTableRowProps) => {
    const [rowHovered, setRowHovered] = useState(false);
    const navigate = useNavigate();
    const { changeTransportOrderStatus } = useChangeTransportOrderStatusRepository();

    const { id, orderId, status, loading, unloading, creator } = transportOrdersItem;

    const onStatusChange = async (newStatus: TransportOrderStatus) => {
        await changeTransportOrderStatus({ transportOrderId: id, newStatus });

        toast.success('Pomyślnie zmieniono status');
    };

    const handleMouseEnterRow = () => setRowHovered(true);
    const handleMouseLeavesRow = () => setRowHovered(false);
    const handleRowClick = () => navigate(`/transport-orders/${id}`);

    const loadingAddress = buildAddress(loading);
    const unloadingAddress = buildAddress(unloading);

    const loadingDateText = buildDate(loading);
    const unloadingDateText = buildDate(unloading);

    return (
        <tr className={`${rowHovered ? 'cursor-pointer' : ''}`} onClick={handleRowClick}
            onMouseEnter={handleMouseEnterRow} onMouseLeave={handleMouseLeavesRow}>
            <td className="width-0"><Button className="p-0 text-decoration-none" variant="link">{orderId}</Button></td>
            <td className="width-0 text-center">
                <EditableTransportOrderBadge
                    status={status}
                    onStatusChange={onStatusChange}
                />
            </td>
            <TransportOrderAddressCell isShortened={!rowHovered} address={loadingAddress} />
            <TransportOrderAddressCell isShortened={!rowHovered} address={unloadingAddress} />
            <td className="width-0">{loadingDateText}</td>
            <td className="width-0">{unloadingDateText}</td>
            <td className="width-0 text-nowrap">{creator.name}</td>
        </tr>
    );
};
