import { Button } from 'react-bootstrap';

type ContractorButtonsProps = {
    disabled?: boolean;
    onCancelButtonClick: () => void
    onSubmitButtonClick: () => void
}

export const ContractorButtons = ({
        disabled = false,
        onCancelButtonClick,
        onSubmitButtonClick,
    }: ContractorButtonsProps) => (
    <div className="d-flex flex-row justify-content-end">
        {/*<Button className="mx-2" variant="danger" disabled={disabled} onClick={onCancelButtonClick}>Anuluj</Button>*/}
        <Button variant="primary" disabled={disabled} onClick={onSubmitButtonClick}>Zapisz</Button>
    </div>
);
