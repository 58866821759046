import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';

import { Client } from '../../domain/client';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useClientsRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ['clients'],
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/clients`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return await response.json() as Array<Client>;
        },
    });

    const refetch = () => {
        queryClient.invalidateQueries({
            queryKey: ['clients']
        });
    };

    return {
        ...query,
        refetch
    };
};