import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Client } from '../../domain/client';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { onSuccessfulClientChange } from './onSuccessfulClientChange';

export const useModifyClientRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const modifyClient = useMutation({
        mutationFn: (editedClient: Client) => modifyClientFunction(editedClient),
        onSuccess: (changedClient) => onSuccessfulClientChange(changedClient, queryClient),
    });

    const modifyClientFunction = async (editedClient: Client) => {
        const token = await getJwtToken();

        const result = await fetch(`${BACKEND_URL}/clients/${editedClient.id}`, {
            method: 'PUT',
            body: JSON.stringify(editedClient),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        return await result.json() as Client;
    };

    return {
        modifyClient: (editedClient: Client) => modifyClient.mutateAsync(editedClient),
    };
};