import { Variant } from 'react-bootstrap/types';
import { TransportOrderStatus } from '../domain/transportOrder';

export const transportOrderStatusesMapping: {
    [Status in TransportOrderStatus]: {
        variant: Variant,
        message: string
    }
} = {
    DRAFT: { variant: 'secondary', message: 'szkic' },
    CREATED: { variant: 'info', message: 'w trakcie' },
    LOADED: { variant: 'warning', message: 'załadowane' },
    UNLOADED: { variant: 'success', message: 'rozładowane' },
    DONE: { variant: 'primary', message: 'zakończone' },
    STORNO: { variant: 'danger', message: 'storno' },
};
