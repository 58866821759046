import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';

import { Contractor } from '../../domain/contractor';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useContractorsRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ['contractors'],
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/contractors`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const body = await response.json() as Contractor[];

            return body.sort((a, b) => {
                if (a.blacklist && !b.blacklist) {
                    return 1;
                }
                if (!a.blacklist && b.blacklist) {
                    return -1;
                }

                return 0;
            });
        },
    });

    const refetch = () => {
        queryClient.invalidateQueries({
            queryKey: ['contractors']
        });
    };

    return {
        ...query,
        refetch
    };
};