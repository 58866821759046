import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { ToastContainer } from 'react-toastify';

import { Layout } from './layout/Layout';
import { AuthenticationProvider } from './modules/auth/AuthenticationProvider';
import { LoggedInUserProvider } from './modules/users/LoggedInUserProvider';

const MAX_RETRIES = 2;
const HTTP_STATUS_TO_NOT_RETRY = ['400', '401', '403', '404', '422'];

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // staleTime: 15 * 60 * 1000, // 15 minutes
            retry: (failureCount, error) => {
                if (failureCount > MAX_RETRIES) {
                    return false;
                }

                if (HTTP_STATUS_TO_NOT_RETRY.includes(error.message)) {
                    return false; // Aborting retry due to status code
                }

                return true;
            },
        },
    },
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <AuthenticationProvider>
                <LoggedInUserProvider>
                    <Layout />{/* routes are here */}
                </LoggedInUserProvider>
            </AuthenticationProvider>
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
}

export default App;
