import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Contractor } from '../../domain/contractor';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { onSuccessfulContractorChange } from './onSuccessfulContractorChange';

export const useModifyContractorRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const modifyContractor = useMutation({
        mutationFn: (editedContractor: Contractor) => modifyContractorFunction(editedContractor),
        onSuccess: (changedContractor) => onSuccessfulContractorChange(changedContractor, queryClient),
    });

    const modifyContractorFunction = async (editedContractor: Contractor) => {
        const token = await getJwtToken();

        const result = await fetch(`${BACKEND_URL}/contractors/${editedContractor.id}`, {
            method: 'PUT',
            body: JSON.stringify(editedContractor),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        return await result.json() as Contractor;
    };

    return {
        modifyContractor: (editedContractor: Contractor) => modifyContractor.mutateAsync(editedContractor),
    };
};