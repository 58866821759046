import { CognitoUser } from 'amazon-cognito-identity-js';
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useAuthenticationRepository } from '../../repository/auth/useAuthenticationRepository';
import { LoginWrapper } from '../login/LoginWrapper';

type AuthenticationContextValue = {
    currentUser: CognitoUser | null;
    logIn: (username: string, password: string) => Promise<unknown>;
    logout: () => void;
    sendForgottenPasswordLink: (email: string) => Promise<void>;
    changePassword: (email: string, confirmationCode: string, newPassword: string) => Promise<void>;
    getJwtToken: () => Promise<string>;
}

const AuthenticationContext = createContext<AuthenticationContextValue | null>(null);

type AuthenticationProviderProps = {
    children: ReactNode;
}

export const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
    const { signIn, signOut, sendForgottenPasswordLink, changePassword, getCurrentUser, getJwtToken, refreshSession } = useAuthenticationRepository();
    const currentUser = useMemo(() => getCurrentUser(), [getCurrentUser]);

    useEffect(() => {
        if (currentUser) {
            refreshSession()
                .catch((e) => {
                    console.log('error while refreshing session - signing out', e);
                    logout();
                })
        }
    }, []);

    const logout = () => {
        signOut();

        window.location.href = '/';
    }

    const getCurrentUserSub = () => {
        if (currentUser) {
            return currentUser.getUsername();
        }
        return null;
    }

    const value = {
        currentUser,
        logIn: signIn,
        logout,
        sendForgottenPasswordLink,
        changePassword,
        getJwtToken,
        getCurrentUserSub,
    }

    return (
        <AuthenticationContext.Provider value={value}>
            {!currentUser && <LoginWrapper />}
            {currentUser && children}
        </AuthenticationContext.Provider>
    );
}

export const useAuthentication = () => {
    const context = useContext(AuthenticationContext);

    if (!context) {
        throw new Error('useAuthentication must be used within an AuthContextProvider');
    }

    return context;
}