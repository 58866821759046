import React from 'react';
import { Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export const TransportOrdersFeedLoadingSkeleton = () => (
    <Card>
        <Card.Header className="border-white d-flex justify-content-between">
            <Skeleton />
        </Card.Header>
        <Card.Body className="position-relative">
            <Skeleton count={3} />
        </Card.Body>
    </Card>
);
