import { useState } from 'react';
import { CompanyDetails } from '../../../domain/companyDetails';

export const useCompanyDetailsForm = (initialCompanyDetails: CompanyDetails) => {
    const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(initialCompanyDetails || {});

    return {
        companyDetails,
        setCompanyDetails: (newCompanyDetails: CompanyDetails) => setCompanyDetails(newCompanyDetails),
    }
}
