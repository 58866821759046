import { Button, Card, Form, Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { FormElement } from '../../../components/FormElement';
import { ContractorType } from '../useContractorForm';

type ContractorTrailer = ContractorType['trailers'];
type ContractorTrailerValues = ContractorTrailer['value'];
type ContractorTrailerValue = ContractorTrailerValues['0'];

type ContractorTrailersProps = {
    trailers: ContractorTrailer;
}

export const ContractorTrailers = ({ trailers }: ContractorTrailersProps) => {
    const handleTrailersChange = (changedTrailers: ContractorTrailerValues) => {
        trailers.setValue(changedTrailers);
    };
    const handleTrailerChange = (trailerIndex: number, newTrailer: ContractorTrailerValue) => {
        const updatedTrailers: ContractorTrailerValues = [
            ...trailers.value.filter((_, index) => index < trailerIndex),
            newTrailer,
            ...trailers.value.filter((_, index) => index > trailerIndex),
        ];

        handleTrailersChange(updatedTrailers);
    };

    const handleTrailerRemoval = (trailerIndex: number) => {
        const updatedTrailers: ContractorTrailerValues = [
            ...trailers.value.filter((_, index) => index < trailerIndex),
            ...trailers.value.filter((_, index) => index > trailerIndex),
        ];

        handleTrailersChange(updatedTrailers);
    };

    const handleAddNewTrailer = () => {
        handleTrailersChange([
            ...trailers.value,
            { id: uuidv4(), licensePlate: '' },
        ]);
    };

    return <Card className="mb-4">
        <Card.Body>
            <FormElement headingText="Naczepy">
                <Table striped bordered hover>
                    <ContractorTrailersTableHead />
                    <tbody>
                    {trailers.value.map((trailer, index) => (
                        <ContractorTrailerItem
                            key={trailer.id}
                            trailerIndex={index}
                            trailer={trailer}
                            onChange={(newTrailer) => handleTrailerChange(index, newTrailer)}
                            onRemove={() => handleTrailerRemoval(index)}
                        />)
                    )}
                    </tbody>
                </Table>
                <div className="text-center">
                    <Button
                        className="p-0 text-center text-decoration-none"
                        variant="link"
                        onClick={handleAddNewTrailer}>
                        + Dodaj naczepę
                    </Button>
                </div>
            </FormElement>
        </Card.Body>
    </Card>;
};

type ContractorTrailerItemProps = {
    trailerIndex: number;
    trailer: ContractorTrailerValue;
    onChange: (newTrailer: ContractorTrailerValue) => void;
    onRemove: () => void
};

const ContractorTrailerItem = ({ trailerIndex, trailer, onChange, onRemove }: ContractorTrailerItemProps) => {
    const handleLicensePlateChange = (value: string) => {
        onChange({ ...trailer, licensePlate: value });
    };

    const handleRemoveButtonClick = () => {
        onRemove();
    };

    return (
        <tr>
            <td className="text-center align-middle width-0">
                {trailerIndex + 1}
            </td>
            <td>
                <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Numer rejestracyjny"
                    value={trailer.licensePlate}
                    onChange={(e) => handleLicensePlateChange(e.target.value)} />
            </td>
            <td className="width-0">
                <Button
                    className="text-danger text-decoration-none"
                    variant="link"
                    onClick={handleRemoveButtonClick}>
                    Usuń
                </Button>
            </td>
        </tr>
    );
};

const ContractorTrailersTableHead = () => (
    <thead>
    <tr>
        <th className="text-center"><span className="px-1">#</span></th>
        <th>Numer rejestracyjny</th>
        <th className="width-0"></th>
    </tr>
    </thead>
);
