import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';
import { onSuccessfulTransportOrderChange } from './onSuccessfulTransportOrderChange';

type ChangeTransportOrderContractorMutationType = {
    transportOrderId: string;
    newContractor?: {
        id: string,
        contactId?: string
    };
}

export const useChangeTransportOrderContractorRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changeTransportOrderContractor = useMutation({
        mutationFn: (variable: ChangeTransportOrderContractorMutationType) => changeTransportOrderContractorFunction(variable.transportOrderId, variable.newContractor),
        onSuccess: (changedTransportOrder) => onSuccessfulTransportOrderChange(changedTransportOrder, queryClient),
    });

    const changeTransportOrderContractorFunction = async (transportOrderId: string, newContractor?: {
        id: string,
        contactId?: string
    }) => {
        const token = await getJwtToken();

        const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}/${transportOrderId}`, {
            method: 'PATCH',
            body: JSON.stringify({
                contractor: newContractor,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const dto = await response.json() as GetTransportOrderDto;

        return toDomain(dto)
    };

    return {
        changeTransportOrderContractor: (variables: ChangeTransportOrderContractorMutationType) => changeTransportOrderContractor.mutateAsync(variables),
    };
};