import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageTitle } from '../../components/PageTitle';
import { useAuthentication } from '../auth/AuthenticationProvider';

export const SendForgottenPasswordLink = () => {
    const [email, setEmail] = useState('');
    const { sendForgottenPasswordLink } = useAuthentication();
    const navigate = useNavigate();

    const handleEmailChange = (newEmail: string) => {
        setEmail(newEmail);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await sendForgottenPasswordLink(email);
            toast.success('Pomyśłnie wysłano kod. Sprawdź skrzynkę mailową.');
            navigate(`/change-password?email=${email}`);
        } catch (error) {
            toast.error('Nieprawidłowy adres email');
            console.error('Error while sending forgotten password link', error);
        }
    };

    return <Card className="border-0">
        <Card.Body>
            <h6>
                <Link className="text-decoration-none" to="/">
                    {'<'} Powrót do strony logowania
                </Link>
            </h6>
            <PageTitle>Nie pamiętasz hasła?</PageTitle>
            <Form className="w-25" onSubmit={handleSubmit}>
                <Form.Group className="mb-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" value={email} onChange={(e) => handleEmailChange(e.target.value)} />
                </Form.Group>
                <Button type="submit">Wyślij kod</Button>
            </Form>
        </Card.Body>
    </Card>;
};