import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateClientRepository } from '../../repository/clients/useCreateClientRepository';
import { ClientButtons } from '../client/ClientButtons';
import { ClientContacts } from '../client/ClientContacts';
import { ClientDetails } from '../client/ClientDetails';
import { useClientForm } from '../client/useClientForm';

type NewClientModalProps = {
    show: boolean;
    onClose: () => void;
}
export const NewClientModal = ({ show, onClose }: NewClientModalProps) => {
    const navigate = useNavigate();
    const client = useClientForm();

    const { createClient } = useCreateClientRepository();

    const handleNewClientFormSubmit = async () => {
        const newClientId = await createClient({
            name: client.name.value,
            address: client.address.value,
            nip: client.nip.value,
            phoneNumber: client.phoneNumber.value,
            email: client.email.value,
            additionalInfo: client.additionalInfo.value,
            contacts: client.contacts.value,
        });
        toast.success('Klient utworzony');

        return navigate(`/clients/${newClientId}`);
    };

    return (
        <Modal show={show} size="xl" onHide={onClose}>
            <Modal.Header closeButton className="p-3">
                <Modal.Title>Nowy klient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ClientDetails client={client} direction="column" />
                <ClientContacts contacts={client.contacts} />
            </Modal.Body>
            <Modal.Footer>
                <ClientButtons onSubmitButtonClick={handleNewClientFormSubmit} onCancelButtonClick={onClose} />
            </Modal.Footer>
        </Modal>
    );
};