import React from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { TwoColumns } from '../../../components/TwoColumns';
import { TransportOrder } from '../../../domain/transportOrder';
import {
    useChangeTransportOrderContractorRepository,
} from '../../../repository/transport-orders/useChangeTransportOrderContractorRepository';
import {
    useModifyTransportOrderRepository,
} from '../../../repository/transport-orders/useModifyTransportOrderRepository';
import { AdditionalInfo } from '../components/AdditionalInfo';
import { Comment } from '../components/Comment';
import { TransportOrderContractor } from '../components/contractor/TransportOrderContractor';
import { CreatedBy } from '../components/CreatedBy';
import { Driver } from '../components/Driver';
import { Freight, FreightProfit } from '../components/Freight';
import { LoadDetails } from '../components/LoadDetails';
import { ShipmentLoading } from '../components/ShipmentLoading';

import { ShipmentUnloading } from '../components/ShipmentUnloading';
import { Vehicle } from '../components/Vehicle';
import { TransportOrderButtons } from './TransportOrderButtons';
import { toDomain as mapTransportFormToDomainType, useTransportOrderForm } from './useTransportOrderForm';
import { useTransportOrderChanged } from './useTransportOrderFormChange';
import { Notification } from './useTransportOrderNotifications';

type TransportOrderBodyProps = {
    fetchedTransportOrder: TransportOrder;
    addNotification: (notification: Notification) => void;
    context: {
        isTransportOrderEdit: boolean;
    }
}

export const TransportOrderBody = ({
   fetchedTransportOrder,
   addNotification,
   context,
}: TransportOrderBodyProps) => {
    const transportOrderForm = useTransportOrderForm(fetchedTransportOrder);
    const transportOrderFormChanges = useTransportOrderChanged(transportOrderForm, fetchedTransportOrder);
    const { changeTransportOrderContractor } = useChangeTransportOrderContractorRepository();
    const { modifyTransportOrder } = useModifyTransportOrderRepository();

    const handleSubmitTransportOrderClick = async () => {
        await modifyTransportOrder(mapTransportFormToDomainType(transportOrderForm));
        toast.success('Edycja zlecenia powiodła się');
    };

    return (
        <div className="d-flex flex-column flex-grow-1 pe-4">
            <div className="d-flex flex-row mb-3">
                <Card className="flex-grow-1 me-2">
                    <Card.Body>
                        <CreatedBy
                            creator={transportOrderForm.creator.value}
                            setCreator={transportOrderForm.creator.setValue} />
                    </Card.Body>
                </Card>
                <Card className="flex-grow-2 ms-2">
                    <Card.Body>
                        <TransportOrderContractor
                            contractor={transportOrderForm.contractor.value}
                            setContractor={transportOrderForm.contractor.setValue}
                            addNotification={addNotification}
                            onUpdateContractorDetails={async (newContractor) => await changeTransportOrderContractor({
                                transportOrderId: fetchedTransportOrder.id,
                                newContractor,
                            })} />
                    </Card.Body>
                </Card>
            </div>
            <TwoColumns>
                <Card className="mb-3">
                    <Card.Body>
                        <ShipmentLoading
                            shipmentLoading={transportOrderForm.loading.value}
                            setShipmentLoading={transportOrderForm.loading.setValue}
                            context={context} />
                    </Card.Body>
                </Card>
                <Card className="mb-3">
                    <Card.Body>
                        <ShipmentUnloading
                            shipmentUnloading={transportOrderForm.unloading.value}
                            setShipmentUnloading={transportOrderForm.unloading.setValue}
                            context={context} />
                    </Card.Body>
                </Card>
            </TwoColumns>
            <Card className="mb-3">
                <Card.Body>
                    <LoadDetails
                        loadDetails={transportOrderForm.loadDetails.value}
                        setLoadDetails={transportOrderForm.loadDetails.setValue} />
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <TwoColumns>
                        <Driver
                            driver={transportOrderForm.driver.value}
                            setDriver={transportOrderForm.driver.setValue} />
                        <Vehicle
                            vehicle={transportOrderForm.vehicle.value}
                            setVehicle={transportOrderForm.vehicle.setValue} />
                    </TwoColumns>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <TwoColumns>
                        <Freight
                            freight={transportOrderForm.freight.value}
                            setFreight={transportOrderForm.freight.setValue} />
                        <FreightProfit
                            freight={transportOrderForm.freight.value}
                            setFreight={transportOrderForm.freight.setValue} />
                    </TwoColumns>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <AdditionalInfo
                        className="w-50"
                        additionalInfo={transportOrderForm.additionalInfo.value}
                        setAdditionalInfo={transportOrderForm.additionalInfo.setValue} />
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Comment
                        comment={transportOrderForm.comment.value}
                        setComment={transportOrderForm.comment.setValue} />
                </Card.Body>
            </Card>
            <TransportOrderButtons
                disabled={!transportOrderFormChanges.anyChanges}
                onSubmitButtonClick={handleSubmitTransportOrderClick}
                onCancelButtonClick={() => alert('okodować wycofanie zmian na fomularzu')} />
        </div>
    );
};
