import { useQueryClient } from '@tanstack/react-query';

export const useRefetchTransportOrderData = () => {
    const queryClient = useQueryClient();

    return async (contractorId: string) => {
        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ['transportOrders', { id: contractorId }]}),
            queryClient.invalidateQueries({ queryKey: ['transportOrders', 'files', { id: contractorId }]}),
        ])
    }
}