import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { UserAccount } from '../../domain/userAccount';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useFindUserDetailsRepository = () => {
    const { currentUser, getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['userDetails'],
        placeholderData: keepPreviousData,
        queryFn: async () => {
            if (!currentUser) {
                throw new Error('No current user');
            }
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/user-details`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return await response.json() as UserAccount;
        },
    });

    return {
        ...query,
    }
};