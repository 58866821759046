import { Form } from 'react-bootstrap';

type TextareaFromGroupProps = {
    label: string | React.ReactNode;
    value: string;
    rows: number;
    placeholder?: string;
    onChange?: (value: string) => void;
    className?: string;
    inputClassName?: string;
}

const noop = () => {
};

export const TextareaFormGroup = ({
        label,
        value,
        rows,
        placeholder = '',
        onChange = noop,
        className,
        inputClassName
    }: TextareaFromGroupProps) => {
    const handleChange = (value: string) => {
        onChange(value);
    };

    return <Form.Group className={`${className} mb-3`}>
        <Form.Label className="m-0 fw-bolder">{label}</Form.Label>
        <Form.Control
            className={inputClassName}
            as="textarea"
            rows={rows}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
        />
    </Form.Group>;
};