import { useQueryClient } from '@tanstack/react-query';

export const useRefetchClientData = () => {
    const queryClient = useQueryClient();

    return async (clientId: string) => {
        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ['clients', { id: clientId }]}),
        ])
    }
}
