import { Card, Form } from 'react-bootstrap';
import { FormElement } from '../../components/FormElement';
import { OneOrTwoColumns } from '../../components/OneOrTwoColumns';
import { TextFormGroup } from '../../components/TextFormGroup';
import { ClientType } from './useClientForm';

type ClientDetailsProps = {
    client: ClientType;
    direction?: 'column' | 'row'
}

export const ClientDetails = ({ client, direction = 'row' }: ClientDetailsProps) => (
    <Card className="flex-grow-1 mb-4">
        <Card.Body>
            <OneOrTwoColumns mode={direction}>
                <FormElement headingText="Szczegóły">
                    <TextFormGroup label="Nazwa" value={client.name.value} onChange={client.name.setValue} />
                    <Form.Group className="mb-3">
                        <Form.Label className="m-0 fw-bolder">Adres</Form.Label>
                        <Form.Control as="textarea" rows={8} placeholder="Adres" defaultValue={client.address.value} onChange={(e) => client.address.setValue(e.target.value)} />
                    </Form.Group>
                    <TextFormGroup className="w-50" label="Nip" value={client.nip.value} onChange={client.nip.setValue} />
                    <TextFormGroup className="w-50" label="Email" value={client.email.value} onChange={client.email.setValue} />
                    <TextFormGroup className="w-50" label="Numer telefonu" value={client.phoneNumber.value} onChange={client.phoneNumber.setValue} />
                </FormElement>
                <FormElement headingText="Dodatkowe informacje">
                    <Form.Control as="textarea" rows={8} placeholder="Dodatkowe informacje" defaultValue={client.additionalInfo.value} onChange={(e) => client.additionalInfo.setValue(e.target.value)} />
                </FormElement>
            </OneOrTwoColumns>
        </Card.Body>
    </Card>
);