import { useRoutes } from 'react-router-dom';
import { ChangePassword } from './ChangePassword';
import { SendForgottenPasswordLink } from './SendForgottenPasswordLink';
import { LoginForm } from './LoginForm';

export const LoginWrapper = () => {
    const authRoutes = useRoutes([
        { path: '/', element: <LoginForm /> },
        { path: '/forgotten-password', element: <SendForgottenPasswordLink /> },
        { path: '/change-password', element: <ChangePassword /> },
    ]);

    return <>{authRoutes}</>;
}