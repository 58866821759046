import React, { useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { FormElement } from '../../../components/FormElement';
import { Contractor } from '../../../domain/contractor';
import {
    useListFilesByContractorIdRepository,
} from '../../../repository/contractor-files/useListFilesByContractorIdRepository';

import {
    useUploadFilesToContractorRepository,
} from '../../../repository/contractor-files/useUploadFilesToContractorRepository';
import { LoadingSkeletonTableRows } from '../../../components/LoadingSkeletonTableRows';
import { ContractorFilesRow } from './ContractorFilesRow';

type DropzoneAreaProps = {
    onNewFileAdded: (numberOfFiles: number) => Promise<void>;
    contractorId: string;
    className?: string;
}

const DropzoneArea = ({ onNewFileAdded, contractorId, className }: DropzoneAreaProps) => {
    const [isUploading, setIsUploading] = useState(false);
    const { uploadFiles } = useUploadFilesToContractorRepository();

    const onDrop = async (acceptedFiles: Array<File>) => {
        try {
            setIsUploading(true);
            await uploadFiles({ contractorId, files: acceptedFiles });
            await onNewFileAdded(acceptedFiles.length);
        } catch (e) {
            console.log('Error while uploading files', e);
        }
        setIsUploading(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <div
            className={`d-flex justify-content-center border-1 border-secondary border-dotted p-2 py-4 ${!isUploading && 'cursor-pointer'} ${className || ''}`} {...getRootProps()}>
            {isUploading && <Spinner className="text-center" animation="border" role="status" />}
            {!isUploading && <>
                <input {...getInputProps()} />
                <p className="d-flex m-0">Przeciągnij pliki, lub kliknij + aby dodać</p>
            </>}
        </div>
    );
};

type ContractorFilesProps = {
    fetchedContractor: Contractor;
}

export const ContractorFiles = ({ fetchedContractor }: ContractorFilesProps) => {
    const { data: files } = useListFilesByContractorIdRepository(fetchedContractor.id);

    const filesList = files?.map((file) => ({
        name: file.name,
        path: file.path,
        date: file.date,
        byteSize: file.byteSize,
        checked: false,
    }));

    const handleNewFilesAdded = async (numberOfFiles: number) => {
        toast.success(`Pomyślnie dodano ${numberOfFiles > 1 ? 'pliki' : 'plik'}`);
    };

    return (
        <Card>
            <Card.Body>
                <FormElement headingText="Pliki">
                    <>
                        <Table bordered hover striped>
                            <thead>
                            <tr>
                                <th>Dodane</th>
                                <th>Rozmiar</th>
                                <th className="w-100">Nazwa</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {filesList === undefined ?
                                <LoadingSkeletonTableRows rows={5} columns={5} /> :
                                filesList.map((file) => (
                                    <ContractorFilesRow
                                        contractorId={fetchedContractor.id}
                                        file={file}
                                    />
                                ))}
                            </tbody>
                        </Table>
                        {filesList && <DropzoneArea
                            onNewFileAdded={handleNewFilesAdded}
                            className="mb-3"
                            contractorId={fetchedContractor.id} />}
                    </>
                </FormElement>
            </Card.Body>
        </Card>
    );
};