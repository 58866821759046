export const ContractorTransportOrdersTableHeader = () => (
    <thead className="text-center">
    <tr>
        <th className="align-bottom" rowSpan={2}>ID zlecenia</th>
        <th className="align-bottom" rowSpan={2}>Status</th>
        <th className="align-bottom text-nowrap" rowSpan={2}>Adres(y) załadunku</th>
        <th className="align-bottom" rowSpan={2}>Adres(y) rozładunku</th>
        <th colSpan={2}>Data</th>
        <th className="align-bottom" rowSpan={2}>Zleceniodawca</th>
    </tr>
    <tr>
        <th>Załadunku</th>
        <th>Rozładunku</th>
    </tr>
    </thead>
);
