import { Button } from 'react-bootstrap';
import { Copy, Trash } from 'react-bootstrap-icons';
import { ActionItem } from '../../../components/ActionItem';
import { FormElement } from '../../../components/FormElement';
import { TextFormGroup } from '../../../components/TextFormGroup';
import { TransportOrder } from '../../../domain/transportOrder';

type LoadDetailsProps = {
    loadDetails: TransportOrder['loadDetails'];
    setLoadDetails: (newLoadDetails: TransportOrder['loadDetails']) => void;
}

export const LoadDetails = ({ loadDetails = [], setLoadDetails }: LoadDetailsProps) => {
    const handleItemNameChange = (index: number, changedName: string) => {
        setLoadDetails(loadDetails.map((item, itemIndex) => {
            if (itemIndex === index) {
                return {
                    ...item,
                    name: changedName,
                };
            }
            return item;
        }));
    };

    const handleItemValueChange = (index: number, changedValue: string) => {
        setLoadDetails(loadDetails.map((item, itemIndex) => {
            if (itemIndex === index) {
                return {
                    ...item,
                    value: changedValue,
                };
            }
            return item;
        }));
    };

    const handleAddItemClick = () => {
        setLoadDetails([...loadDetails, { name: '', value: '' }]);
    };

    const handleItemDuplicateClick = (cloneItemIndex: number) => {
        const newItem = loadDetails[cloneItemIndex];

        setLoadDetails([
            ...loadDetails.slice(0, cloneItemIndex),
            newItem,
            ...loadDetails.slice(cloneItemIndex),
        ]);
    };

    const handleItemRemoveClick = (removeItemIndex: number) => {
        setLoadDetails(loadDetails.filter((_, index) => index !== removeItemIndex));
    };

    return (
        <FormElement headingText="Informacje o ładunku">
            {loadDetails.length > 0 && <ul className="list-unstyled">
                {loadDetails.map(({ name, value }, index) => (
                        <li className="mb-2 d-flex flex-row">
                            <TextFormGroup
                                className="w-25 pe-2"
                                label="Nazwa"
                                value={name}
                                onChange={(value) => handleItemNameChange(index, value)}
                            />
                            <TextFormGroup
                                className="w-75"
                                label="Wartość"
                                value={value}
                                onChange={(value) => handleItemValueChange(index, value)}
                            />
                            <ActionItem onClick={() => handleItemDuplicateClick(index)} className="ps-2">
                                <Copy />
                            </ActionItem>
                            <ActionItem onClick={() => handleItemRemoveClick(index)} className="ps-2">
                                <Trash />
                            </ActionItem>
                        </li>
                    ),
                )}
            </ul>}
            {loadDetails.length === 0 && (
                <p className="fst-italic text-center">
                    Brak informacji o ładunku.
                </p>
            )}
            <div className="d-flex justify-content-center">
                <Button variant="link" onClick={handleAddItemClick} className="text-decoration-none">
                    + dodaj
                </Button>
            </div>

        </FormElement>
    );
};