import { QueryClient } from '@tanstack/react-query';
import { Client } from '../../domain/client';

export const onSuccessfulClientChange = async (changedClient: Client, queryClient: QueryClient) => {
    await queryClient.invalidateQueries({ queryKey: ['clients', { id: changedClient.id }] });
    queryClient.setQueryData(['clients'], (originalClients?: Array<Client>) => {
        if (!originalClients) {
            return
        }
        return originalClients.map((originalClient) => {
            if (originalClient.id !== changedClient.id) {
                return originalClient;
            }
            return changedClient;
        });
    })
}