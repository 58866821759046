import moment from 'moment/moment';
import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CalendarEvent, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

import './MonthPicker.scss';
import { useOutsideAlerter } from './useOutsideAlerter';

const now = moment();
const currentMonth = now.month() + 1;
const currentYear = now.year();

type MonthPickerProps = {
    month: number;
    year: number;
    onChange: (month: number, year: number) => void;
}

export const MonthPicker = ({ month, year, onChange }: MonthPickerProps) => {
    const [showPicker, setShowPicker] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowPicker(false));

    const value = month && year ? `${month >= 10 ? month : `0${month}`}-${year}` : '';

    const handleMonthYearChange = (month: number, year: number) => {
        onChange(month, year);
        setShowPicker(false);
    }

    return (
        <div ref={wrapperRef} className="d-inline-flex flex-column position-relative">
            <input className="search-input form-control" placeholder="wybierz miesiąc" type="text" defaultValue={value} onFocus={() => setShowPicker(true)} />
            <CalendarEvent onClick={() => setShowPicker(true)} className="position-absolute cursor-pointer" style={{ top: 12, right: 9 }} />
            {showPicker &&
                <MonthPickerPopup
                    month={month}
                    year={year}
                    onChange={handleMonthYearChange}
                />}
        </div>
    );
};

type MonthPickerPopupProps = {
    month: number;
    year: number;
    onChange: (month: number, year: number) => void;
}

export const MonthPickerPopup = ({ month, year, onChange }: MonthPickerPopupProps) => {
    const [yearToDisplay, setYearToDisplay] = useState(year || currentYear);

    const markedMonth = month || currentMonth;

    const handlePrevYear = () => {
        setYearToDisplay(yearToDisplay - 1);
    };

    const handleNextYear = () => {
        setYearToDisplay(yearToDisplay + 1);
    };

    const handleMonthSelect = (newMonth: number) => {
        onChange(newMonth, yearToDisplay);
    };

    const handleClear = () => {
        onChange(0, 0);
    }

    const handleThisMonth = () => {
        onChange(currentMonth, currentYear);
    }

    const months = [
        'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec',
        'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień',
    ];

    return <div className="month-picker-popup p-2 shadow bg-white position-absolute">
        <div className="mt-1 mb-3 d-flex flex-row justify-content-between align-items-baseline">
            <Button variant="link" className="p-0 px-2 text-decoration-none text-black">
                <ChevronLeft className="pointer-event" onClick={handlePrevYear} width="20" height="20" />
            </Button>
            <h4 className="m-0">{yearToDisplay}</h4>
            <Button variant="link" className="p-0 px-2 text-decoration-none text-black">
                <ChevronRight className="pointer-event" onClick={handleNextYear} width="20" height="20" />
            </Button>
        </div>
        <div className="months-grid">
            {months.map((monthName, index) => <Button
                key={monthName}
                variant="link"
                onClick={() => handleMonthSelect(index + 1)}
                className={`p-0 text-black text-decoration-none ${index + 1 === markedMonth ? 'fw-bolder' : ''}`}>
                {monthName}
            </Button>)}
        </div>
        <hr className="mt-2 mb-1" />
        <div className="d-flex justify-content-between">
            <Button className="text-decoration-none" variant="link" onClick={handleClear}>
                Wyczyść
            </Button>
            <Button className="text-decoration-none" variant="link" onClick={handleThisMonth}>
                Ten miesiąc
            </Button>
        </div>
    </div>;
};