import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransportOrder } from '../../domain/transportOrder';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';

export const useRemoveTransportOrderRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const removeTransportOrder = useMutation({
        mutationFn: (transportOrderId: string) => removeTransportOrderFunction(transportOrderId),
        onSuccess: (removedTransportOrderId: string) => {
            queryClient.removeQueries({ queryKey: ['transportOrders', { id: removedTransportOrderId }] });
            queryClient.setQueryData(['transportOrders'], (originalTransportOrders?: Array<TransportOrder>) => {
                if (!originalTransportOrders) {
                    return;
                }
                return originalTransportOrders.filter((originalTransportOrder) => originalTransportOrder.id !== removedTransportOrderId);
            });
        },
    });

    const removeTransportOrderFunction = async (transportOrderId: string) => {
        const token = await getJwtToken();

        await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}/${transportOrderId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return transportOrderId;
    };

    return {
        removeTransportOrder: (transportOrderId: string) => removeTransportOrder.mutateAsync(transportOrderId),
    };
};