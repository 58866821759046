import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransportOrderPolicy } from '../../domain/transportOrderPolicy';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useChangeTransportOrderPolicyRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changePolicy = useMutation({
        mutationFn: (newPolicy: TransportOrderPolicy) => changePolicyFunction(newPolicy),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['companySettings'] });
        }
    });

    const changePolicyFunction = async (newPolicy: TransportOrderPolicy) => {
        const token = await getJwtToken();

        await fetch(`${BACKEND_URL}/settings`, {
            method: 'PATCH',
            body: JSON.stringify({
                transportOrderPolicy: newPolicy,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
    };

    return {
        changeTransportOrderPolicy: (newPolicy: TransportOrderPolicy) => changePolicy.mutateAsync(newPolicy),
    };
};