import { QueryClient } from '@tanstack/react-query';
import { Contractor } from '../../domain/contractor';

export const onSuccessfulContractorChange = async (changedContractor: Contractor, queryClient: QueryClient) => {
    await queryClient.invalidateQueries({ queryKey: ['contractors', { id: changedContractor.id }] });
    queryClient.setQueryData(['contractors'], (originalContractors?: Array<Contractor>) => {
        if (!originalContractors) {
            return
        }
        return originalContractors.map((originalContractor) => {
            if (originalContractor.id !== changedContractor.id) {
                return originalContractor;
            }
            return changedContractor;
        });
    })
}