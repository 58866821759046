import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransportOrderConfig } from '../../domain/transportOrderConfig';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useChangeTransportOrderConfigRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changeTransportOrderConfig = useMutation({
        mutationFn: (newTransportOrderConfig: TransportOrderConfig) => changeTransportOrderConfigFunction(newTransportOrderConfig),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['companySettings'] });
        }
    });

    const changeTransportOrderConfigFunction = async (newTransportOrderConfig: TransportOrderConfig) => {
        const token = await getJwtToken();

        await fetch(`${BACKEND_URL}/settings`, {
            method: 'PATCH',
            body: JSON.stringify({
                transportOrderConfig: newTransportOrderConfig,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
    };

    return {
        changeTransportOrderConfig: (newTransportOrderConfig: TransportOrderConfig) => changeTransportOrderConfig.mutateAsync(newTransportOrderConfig),
    };
};