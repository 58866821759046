import React, { ReactElement } from 'react';
import { Card, OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageTitle } from '../../components/PageTitle';
import { RefreshButton } from '../../components/RefreshButton';
import { Contractor as ContractorType } from '../../domain/contractor';
import { useContractorRepository } from '../../repository/contractors/useContractorRepository';
import { useRefetchContractorData } from '../../repository/contractors/useRefetchContractorData';
import {
    useTransportOrdersByContractorIdRepository,
} from '../../repository/transport-orders/useTransportOrdersByContractorIdRepository';
import { Error } from '../Error';
import { ContractorFiles } from './contractor-files/ContractorFiles';
import { ContractorTransportOrdersTable } from './contractor-orders/ContractorTransportOrdersTable';
import { ContractorActionsCard } from './ContractorActionsCard';
import { ContractorBody } from './ContractorBody';
import { ContractorLoadingSkeleton } from './ContractorLoadingSkeleton';

const tabIds = ['details', 'files'];
type TabId = 'details' | 'files'

const tabIdFromString = (potentialTabId?: string | null): TabId => {
    if (!potentialTabId || !tabIds.includes(potentialTabId)) {
        return 'details';
    }

    return potentialTabId as TabId;
};

type TabItem = {
    id: TabId;
    text: string;
    content: ReactElement;
};

export const ContractorWrapper = () => {
    const { contractorId } = useParams<{ contractorId: string }>();
    const {
        data: contractor,
        isPending,
        isLoading,
        isRefetching,
        isError
    } = useContractorRepository(contractorId!);

    const refetchContractor = useRefetchContractorData();

    if (isPending) {
        return <ContractorLoadingSkeleton />;
    }

    if (isError || !contractorId) {
        return <Error message={`Podwykonawca o id ${contractorId} nie istnieje`} />;
    }

    return <Contractor
        fetchedContractor={contractor}
        reloadContractor={() => refetchContractor(contractorId!)}
        isRefreshing={isLoading || isRefetching}
    />;
};

type ContractorProps = {
    fetchedContractor: ContractorType;
    reloadContractor: () => void;
    isRefreshing: boolean;
}

const Contractor = ({ fetchedContractor, reloadContractor, isRefreshing }: ContractorProps) => {
    const { hash } = useLocation();
    const navigate = useNavigate();

    const activeTabId = tabIdFromString(hash.replaceAll('#', ''));

    const tabs: Array<TabItem> = [
        {
            id: 'details',
            text: 'Szczegóły',
            content: <ContractorDetailsUnderTab fetchedContractor={fetchedContractor} />,
        },
        { id: 'files', text: 'Pliki', content: <ContractorFiles fetchedContractor={fetchedContractor} /> },
    ];

    return (
        <>
            <div className="d-flex flex-row">
                <PageTitle className="me-2">Podwykonawca</PageTitle>
                <RefreshButton isRefreshing={isRefreshing} refreshFunction={reloadContractor} />
            </div>
            <Tabs
                mountOnEnter
                unmountOnExit
                className="border-0"
                activeKey={activeTabId}
                onSelect={(eventKey) => navigate(`#${tabIdFromString(eventKey)}`)}
            >
                {tabs.map((tabItem) => (
                    <Tab key={tabItem.id} eventKey={tabItem.id} title={tabItem.text}>
                        {tabItem.content}
                    </Tab>
                ))}
            </Tabs>
        </>
    );
};

type ContractorDetailsUnderTabProps = {
    fetchedContractor: ContractorType;
}

const ContractorDetailsUnderTab = ({ fetchedContractor }: ContractorDetailsUnderTabProps) => {
    const navigate = useNavigate();

    const { data: transportOrders = [] } = useTransportOrdersByContractorIdRepository(fetchedContractor.id);

    const handleCreateNewTransportOrder = () => {
        navigate({
            pathname: '/new-transport-order',
            search: createSearchParams({
                contractorId: fetchedContractor.id,
            }).toString(),
        });
    };

    return (
        <>
            <div className="d-flex flex-row">
                <ContractorBody fetchedContractor={fetchedContractor} />
                <ContractorActionsCard
                    contractor={fetchedContractor}
                    onCreateNewTransportOrder={handleCreateNewTransportOrder}
                />
            </div>
            <OverlayTrigger placement="right-end" overlay={<Tooltip id="tooltip">Ostatnie 50 zleceń</Tooltip>}>
                <h3 className="mt-4 d-inline-block">Zlecone temu podwykonawcy *</h3>
            </OverlayTrigger>
            <Card>
                <Card.Body>
                    {transportOrders.length > 0 ? <ContractorTransportOrdersTable
                        transportOrders={transportOrders}
                    /> : 'Brak zleceń'}
                </Card.Body>
            </Card>
        </>
    );
};
