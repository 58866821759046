import React from 'react';

import { PageTitle } from '../../components/PageTitle';
import { TransportOrdersFeed } from '../transport-orders-feed/TransportOrdersFeed';
import { SpeditorProfit } from './SpeditorProfit';
import { useLoggedInUser } from '../users/LoggedInUserProvider';
import { SpeditorProfitLastMonth } from './SpeditorProfitLastMonth';

export const Home = () => {
    const { loggedInUser } = useLoggedInUser();

    return <>
        <PageTitle className="mb-0">
            Witaj, {loggedInUser.firstName}!
        </PageTitle>
        <SpeditorProfit className="mb-1" />
        <SpeditorProfitLastMonth className="mb-4" />
        <TransportOrdersFeed />
    </>;
};
