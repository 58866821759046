import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageTitle } from '../../components/PageTitle';
import { useAuthentication } from '../auth/AuthenticationProvider';

export const ChangePassword = () => {
    const [searchParams] = useSearchParams();
    const [confirmationCode, setConfirmationCode] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const { changePassword } = useAuthentication();
    const navigate = useNavigate();

    const email = searchParams.get('email') || '';

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password !== repeatPassword) {
            toast.error('Podane hasła są różne');
            return;
        }

        try {
            await changePassword(email, confirmationCode, password);
            toast.success('Pomyślnie zmieniono hasło');
            navigate('/');
        } catch (e) {
            toast.error('Nie udało się zmienić hasła - sprawdź dane i spróbuj jeszcze raz');
            console.error('Error while changing password', e);
        }
    };

    return (
        <Card className="border-0">
            <Card.Body>
                <h6>
                    <Link className="text-decoration-none" to="/">
                        {'<'} Powrót do strony logowania
                    </Link>
                </h6>
                <PageTitle>Zmień hasło</PageTitle>
                <Form className="w-25" onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Form.Label>Email</Form.Label>
                        <Form.Control disabled type="text" value={email} onChange={() => {
                        }} />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Kod zmiany hasła</Form.Label>
                        <Form.Control
                            autoComplete="confirmation-code"
                            name="confirmation-code"
                            type="text"
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                            placeholder="Kod został wysłany na podany adres email"
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Nowe hasło</Form.Label>
                        <Form.Control autoComplete="new-password" name="new-password" type="password" value={password}
                                      onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Powtórz hasło</Form.Label>
                        <Form.Control autoComplete="confirm-new-password" name="confirm-new-password" type="password"
                                      value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
                    </Form.Group>

                    <Button type="submit">Zmień hasło</Button>
                </Form>
            </Card.Body>
        </Card>
    );
};