import { Card, Form } from 'react-bootstrap';
import { FormElement } from '../../components/FormElement';
import { OneOrTwoColumns } from '../../components/OneOrTwoColumns';
import { TextFormGroup } from '../../components/TextFormGroup';
import { ContractorType } from './useContractorForm';

type ContractorDetailsProps = {
    contractor: ContractorType;
    direction?: 'column' | 'row'
}

export const ContractorDetails = ({ contractor, direction = 'row' }: ContractorDetailsProps) => (
    <Card className="flex-grow-1 mb-4">
        <Card.Body>
            <OneOrTwoColumns mode={direction}>
                <FormElement headingText="Szczegóły">
                    <TextFormGroup label="Nazwa" value={contractor.name.value} onChange={contractor.name.setValue} />
                    <Form.Group className="mb-3">
                        <Form.Label className="m-0 fw-bolder">Adres</Form.Label>
                        <Form.Control as="textarea" rows={8} placeholder="Adres"
                                      defaultValue={contractor.address.value}
                                      onChange={(e) => contractor.address.setValue(e.target.value)} />

                    </Form.Group>
                    <TextFormGroup className="w-50" label="Nip" value={contractor.nip.value}
                                   onChange={contractor.nip.setValue} />
                    <TextFormGroup className="w-50" label="Email" value={contractor.email.value}
                                   onChange={contractor.email.setValue} />
                    <TextFormGroup className="w-50" label="Numer telefonu" value={contractor.phoneNumber.value}
                                   onChange={contractor.phoneNumber.setValue} />
                </FormElement>
                <FormElement headingText="Dodatkowe informacje">
                    <Form.Control as="textarea" rows={8} placeholder="Dodatkowe informacje"
                                  defaultValue={contractor.additionalInfo.value}
                                  onChange={(e) => contractor.additionalInfo.setValue(e.target.value)} />
                </FormElement>
            </OneOrTwoColumns>
        </Card.Body>
    </Card>
);
