import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetContractorTransportOrderDto, toDomain } from './dto/getContractorTransportOrderDto';

const DEFAULT_TRANSPORT_ORDERS_BY_CONTRACTOR_ID_LIMIT = 50;

export const useTransportOrdersByContractorIdRepository = (contractorId: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['transportOrders', { contractor: { id: contractorId } }],
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}?contractorId=${contractorId}&limit=${DEFAULT_TRANSPORT_ORDERS_BY_CONTRACTOR_ID_LIMIT}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const dtos = await response.json() as Array<GetContractorTransportOrderDto>;
            return dtos.map(toDomain);
        },
    });

    return { ...query };
};
