import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';

type ActionItemProps = {
    children: ReactNode,
    onClick?: () => void,
    disabled?: boolean;
    className?: string;
}

export const ActionItem = ({ children, onClick, disabled = false, className }: ActionItemProps) => (
    <Button
        className={`p-0 text-decoration-none ${className}`}
        variant="link"
        disabled={disabled}
        onClick={onClick}>
        {children}
    </Button>
);
