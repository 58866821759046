import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

type ContractorFilePresignedUrlDto = {
    url: string;
}
export const useContractorPresignedFileUrlRepository = (contractorId: string, fileName: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['contractors', 'files', { id: contractorId }, 'presignedFileUrl', { fileName }],
        placeholderData: keepPreviousData,
        enabled: false,
        staleTime: 15 * 60 * 1000, // 15 minutes
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/contractors/${contractorId}/files/${fileName}/presigned-url`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json() as ContractorFilePresignedUrlDto;

            return data.url;
        }
    });

    const getPresignedUrl = async () => {
        let presignedUrl: string;

        if (!query.data || query.isStale) {
            const result = await query.refetch();
            presignedUrl = result.data!;
        } else {
            presignedUrl = query.data;
        }

        return presignedUrl;
    }

    return {
        ...query,
        getPresignedUrl,
    }
}