import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransportOrder } from '../../domain/transportOrder';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { CreateTransportOrderDto } from './dto/createTransportOrderDto';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';

export const useCreateTransportOrderRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const createTransportOrder = useMutation({
        mutationFn: (variable: CreateTransportOrderDto) =>
            createTransportOrderFunction(variable),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['transportOrders'] });
            queryClient.refetchQueries({ queryKey: ['transportOrders'] });
        }
    });

    const createDraftTransportOrder = useMutation({
        mutationFn: (variable: CreateTransportOrderDto) => createDraftTransportOrderFunction(variable),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['transportOrders'] });
            queryClient.refetchQueries({ queryKey: ['transportOrders'] });
        }
    });

    const createTransportOrderFunction = async (newTransportOrder: CreateTransportOrderDto): Promise<TransportOrder> => {
        const token = await getJwtToken();

        const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}`, {
            method: 'POST',
            body: JSON.stringify(newTransportOrder),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const dto = await response.json() as GetTransportOrderDto;

        return toDomain(dto);
    };

    const createDraftTransportOrderFunction = async (newTransportOrder: CreateTransportOrderDto): Promise<TransportOrder> => {
        const token = await getJwtToken();

        const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}?status=DRAFT`, {
            method: 'POST',
            body: JSON.stringify(newTransportOrder),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const dto = await response.json() as GetTransportOrderDto;

        return toDomain(dto);
    }

    return {
        createTransportOrder: (variables: CreateTransportOrderDto) => createTransportOrder.mutateAsync(variables),
        createDraftTransportOrder: (variables: CreateTransportOrderDto) => createDraftTransportOrder.mutateAsync(variables),
    };
};