export type JustValue<ValueType> = {
    value: ValueType;
}

export type MutableValue<ValueType> = JustValue<ValueType> & {
    setValue: (value: ValueType) => void,
}

export const OfValue = <ValueType>(value: ValueType): JustValue<ValueType> => ({
    value,
});

export const OfMutable = <ValueType>(value: ValueType, setValue: (value: ValueType) => void): MutableValue<ValueType> => ({
    value,
    setValue,
});