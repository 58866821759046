import React from 'react';
import { Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { TwoColumns } from '../../../components/TwoColumns';

export const TransportOrderLoadingSkeleton = () => (
    <>
        <div className="mb-4">
            <h2><Skeleton /></h2>
            <h6><Skeleton /></h6>
            <h6><Skeleton /></h6>
        </div>
        <div className="d-flex flex-row">
            <div className="flex-grow-1 pe-4">
                <div className="d-flex flex-row mb-3">
                    <Card className="flex-grow-1 me-2">
                        <Card.Body>
                            <h5><Skeleton /></h5>
                            <Skeleton />
                        </Card.Body>
                    </Card>
                    <Card className="flex-grow-1 ms-2">
                        <Card.Body>
                            <h5><Skeleton /></h5>
                            <p><Skeleton count={7} /></p>
                        </Card.Body>
                    </Card>
                </div>
                <Card>
                    <Card.Body>
                        <TwoColumns>
                            <div>
                                <h5><Skeleton /></h5>
                                <Skeleton count={2} />
                                <h5><Skeleton /></h5>
                                <Skeleton count={4} />
                                <h5><Skeleton /></h5>
                                <Skeleton count={2} />
                                <h5><Skeleton /></h5>
                                <Skeleton count={2} />
                            </div>
                            <div>
                                <h5><Skeleton /></h5>
                                <Skeleton count={2} />
                                <h5><Skeleton /></h5>
                                <Skeleton count={4} />
                                <h5><Skeleton /></h5>
                                <Skeleton count={2} />
                                <h5><Skeleton /></h5>
                                <Skeleton count={2} />
                            </div>
                        </TwoColumns>
                    </Card.Body>
                </Card>
            </div>
            <div>
                <Card>
                    <Card.Body>
                        <Skeleton width="210px" count={5} />
                    </Card.Body>
                </Card>
            </div>
        </div>
    </>
);