import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDER_FILES_PATH } from '../paths';

export const useRemoveTransportOrderFileRepository = (transportOrderId: string) => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const removeFile = useMutation({
        mutationFn: (fileName: string) => removeFileFunction(fileName),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['transportOrders', 'files'] });
        }
    });

    const removeFileFunction = async (filename: string) => {
        const token = await getJwtToken();

        await fetch(`${BACKEND_URL}/${TRANSPORT_ORDER_FILES_PATH(transportOrderId)}/${filename}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    return {
        removeFile: (fileName: string) => removeFile.mutateAsync(fileName),
    };
};
