import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { Variant } from 'react-bootstrap/types';
import { toast } from 'react-toastify';
import { TransportOrder } from '../../domain/transportOrder';
import { TransportOrderFeedStatus } from '../../domain/transportOrdersFeed';
import {
    useChangeTransportOrderStatusRepository,
} from '../../repository/transport-orders/useChangeTransportOrderStatusRepository';

const statuses: {
    [status in TransportOrderFeedStatus]: {
        label: string,
        variant: Variant,
        updateToStatus?: { status: TransportOrder['status'] }
    }
} = {
    toLoad: {
        label: 'Potwierdź załadunek',
        variant: 'warning',
        updateToStatus: {
            status: 'LOADED',
        },
    },
    loaded: {
        label: 'Załadowane',
        variant: 'primary',
    },
    toUnload: {
        label: 'Potwierdź rozładunek',
        variant: 'warning',
        updateToStatus: {
            status: 'UNLOADED',
        },
    },
    unloaded: {
        label: 'Rozładowane',
        variant: 'primary',
    },
    toUnloadAndNotYetLoaded: {
        label: 'Potwierdź rozładunek',
        variant: 'danger',
        updateToStatus: {
            status: 'UNLOADED',
        },
    },
};

type TransportOrderFeedStatusBadgeProps = {
    transportOrderId: string;
    status: TransportOrderFeedStatus;
}

export const TransportOrderFeedStatusBadge = ({ transportOrderId, status }: TransportOrderFeedStatusBadgeProps) => {
    const { changeTransportOrderStatus } = useChangeTransportOrderStatusRepository();

    const { label, variant, updateToStatus } = statuses[status];

    const handleStatusChangeClick = async () => {
        if (!updateToStatus) {
            return;
        }

        await changeTransportOrderStatus({ transportOrderId: transportOrderId, newStatus: updateToStatus.status });

        toast.success('Pomyślnie potwierdzono zlecenie');
    };

    return <Badge
        className={`${variant === 'warning' ? 'text-black' : ''} ${updateToStatus && 'fw-bold cursor-pointer py-2'}`}
        onClick={handleStatusChangeClick}
        bg={variant}
    >
        <span className="text-truncate">{label}</span>
    </Badge>;
};