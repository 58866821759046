import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { UserAccount } from '../../domain/userAccount';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useFindAllUsersRepository = () => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['users'],
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/users`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return await response.json() as Array<UserAccount>;
        },
    });

    return {
        ...query,
    };
};