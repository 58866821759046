import React from 'react';
import { Button } from 'react-bootstrap';

type RefreshButtonProps = {
    refreshFunction: () => void;
    isRefreshing: boolean;
    className?: string;
}

export const RefreshButton = ({ refreshFunction, isRefreshing, className = '' }: RefreshButtonProps) => (
    <Button className={`p-0 ${className}`} variant="link" onClick={refreshFunction}>
        <LoaderIcon animate={isRefreshing} />
    </Button>
);

const LoaderIcon = ({ animate }: { animate: boolean }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"
         className="width-1em height-1em">
        <path className={animate ? 'spin' : ''} d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"></path>
        <path className={animate ? 'spin' : ''}
            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"></path>
    </svg>
)