import { useQueryClient } from '@tanstack/react-query';

export const useRefetchContractorData = () => {
    const queryClient = useQueryClient();

    return async (contractorId: string) => {
        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ['contractors', { id: contractorId }]}),
            queryClient.invalidateQueries({ queryKey: ['contractors', 'files', { id: contractorId }]}),
        ])
    }
}
