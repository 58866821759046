import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

type UploadCompanyLogoMutationType = {
    companyLogo: File;
}

export const useUploadCompanyLogoRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const uploadFile = useMutation({
        mutationFn: ({ companyLogo }: UploadCompanyLogoMutationType) => uploadFileFunction(companyLogo),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['company-logo'] });
        },
    });

    const uploadFileFunction = async (file: File) => {
        const token = await getJwtToken();

        const formData = new FormData();

        formData.append('companyLogo', file);

        await fetch(`${BACKEND_URL}/settings/company-logo`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    return {
        uploadFile: (variables: UploadCompanyLogoMutationType) => uploadFile.mutateAsync(variables),
    };
};