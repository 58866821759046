import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';

export const useTransportOrdersByCreatorAndMonthRepository = (creatorId: string, month: number, year: number) => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ['transportOrders', { orderMonthYear: { month, year }, creator: { id: creatorId } }],
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}?creatorId=${creatorId}&orderYear=${year}&orderMonth=${month}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const dtos = await response.json() as Array<GetTransportOrderDto>;

            return dtos.map(toDomain);
        },
    });

    const refetch = () => {
        queryClient.invalidateQueries({
            queryKey: ['transportOrders', { orderMonthYear: { month, year }, creator: { id: creatorId } }],
        });
    };

    return {
        ...query,
        refetch,
    };
};