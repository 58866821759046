import { Alert, Modal, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { WarningTypes } from './newTransportOrderValidation';
import { WARNING_MESSAGES } from './validation-messages';

type CreateTransportOrderWithWarningsConfirmationModalProps = {
    warnings: Array<{ type: WarningTypes }>;
    submitButtonSpinner: boolean
    onSubmit: () => void;
    onCancel: () => void;
}

export const CreateTransportOrderWithWarningsConfirmationModal = ({
    warnings,
    submitButtonSpinner,
    onSubmit,
    onCancel,
}: CreateTransportOrderWithWarningsConfirmationModalProps) => (
    <Modal show size="lg">
        <Modal.Header>
            <Modal.Title>Czy chcesz kontynuować?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Alert variant="warning">Zlecenie może być wypełnione niepoprawnie</Alert>
            <ul>
                {warnings.map(({ type }) => <li>{WARNING_MESSAGES[type]}</li>)}
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onCancel}>
                Anuluj
            </Button>
            <Button disabled={submitButtonSpinner} variant="primary" onClick={onSubmit}>
                {submitButtonSpinner ? <Spinner /> : 'Zapisz i nadaj numer'}
            </Button>
        </Modal.Footer>
    </Modal>
);