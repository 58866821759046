import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransportOrderFeedItem, TransportOrderFeedResponse } from '../../domain/transportOrdersFeed';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';
import { updateChangedTransportOrder } from './onSuccessfulTransportOrderChange';

type ChangeTransportOrderLoadingCompletedMutationType = {
    transportOrderId: string,
    loadingItem: {
        index: number,
        completed: boolean
    };
}

export const useChangeTransportOrderLoadingCompletedRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changeTransportOrderLoadingCompleted = useMutation({
        mutationFn: (variable: ChangeTransportOrderLoadingCompletedMutationType) =>
            changeTransportOrderLoadingCompletedFunction(variable.transportOrderId, variable.loadingItem),
        onMutate: async (data) => {
            const transportOrderId = data.transportOrderId;
            const updateFeedItem = (feedItem: TransportOrderFeedItem) => {
                if (feedItem.id === transportOrderId) {
                    return {
                        ...feedItem,
                        elements: feedItem.elements.map((element, index) => {
                            if (index === data.loadingItem.index) {
                                return {
                                    ...element,
                                    completed: data.loadingItem.completed,
                                }
                            }
                            return element;
                        })
                    }
                }
                return feedItem;
            }


            await queryClient.setQueryData(['transportOrders', 'feed'], (feed: TransportOrderFeedResponse): TransportOrderFeedResponse => ({
                    ...feed,
                    loading: {
                        forToday: feed.loading.forToday.map(updateFeedItem),
                        forTomorrow: feed.loading.forTomorrow.map(updateFeedItem),
                        late: feed.loading.late.map(updateFeedItem),
                    },
                }
            ));
        },
        onSuccess: async (changedTransportOrder) => {
            updateChangedTransportOrder(changedTransportOrder, queryClient)
        },
    });

    const changeTransportOrderLoadingCompletedFunction = async (transportOrderId: string, loadingItem: {
        index: number,
        completed: boolean
    }) => {
        const token = await getJwtToken();

        const result = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}/${transportOrderId}`, {
            method: 'PATCH',
            body: JSON.stringify({
                loadingCompleted: {
                    index: loadingItem.index,
                    completed: loadingItem.completed,
                },
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const dto = await result.json() as GetTransportOrderDto;

        return toDomain(dto);
    };

    return {
        changeTransportOrderLoadingCompleted: (variable: ChangeTransportOrderLoadingCompletedMutationType) => changeTransportOrderLoadingCompleted.mutateAsync(variable),
    };
};