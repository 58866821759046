import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { TransportOrder } from '../../../../domain/transportOrder';
import { Contractor } from '../../../../domain/contractor';
import { FormElement } from '../../../../components/FormElement';

type ContractorType = TransportOrder['contractor'];

type TransportOrderContractorProps = {
    contractor: ContractorType;
    setContractor: (newValue: ContractorType) => void;
    contractors: Array<Contractor>;
}

const NO_CONTRACTOR_ID = 'no-contractor';
const NO_CONTACT_ID = 'no-contact';

export const TransportOrderContractorMutable = ({
    contractor,
    setContractor,
    contractors,
}: TransportOrderContractorProps) => {
    const findContractor = (contractorId: string) => contractors.find((contractor) => contractor.id === contractorId);

    const handleContractorSelect = (contractorId: string) => {
        const foundContractor = findContractor(contractorId);

        if (contractorId === NO_CONTRACTOR_ID || !foundContractor) {
            setContractor(undefined);
            return;
        }

        setContractor(foundContractor);
    };

    const handleContactSelect = (selectedContactId: string) => {
        if (contractor) {
            const foundContractor = findContractor(contractor.id) as Contractor;
            const foundContact = foundContractor.contacts.find(({ id: contactId }) => contactId === selectedContactId);

            setContractor({
                ...contractor,
                contact: foundContact,
            });
        }
    };

    const availableContacts = (() => {
        if (!contractor) {
            return [];
        }

        return (findContractor(contractor.id) as Contractor)?.contacts || [];
    })();

    const contractorsAsOptions: Array<{ value: string, label: string, blacklist: boolean }> =
        contractors.map((contractor) => ({
            label: contractor.name,
            value: contractor.id,
            blacklist: contractor.blacklist,
        }));

    const selectedContractorAsOption = contractor && {
        label: contractor.name,
        value: contractor.id,
    };

    return (
        <FormElement headingText="Podwykonawca">
            <Form.Group>
                <Form.Label className="m-0">Podwykonawca</Form.Label>
                <Select
                    value={selectedContractorAsOption}
                    className="w-75"
                    placeholder="Wybierz podwykonawcę"
                    isClearable
                    noOptionsMessage={() => 'Brak podwykonawców'}
                    options={contractorsAsOptions}
                    onChange={(newValue) => handleContractorSelect(newValue?.value || NO_CONTRACTOR_ID)}
                    styles={{
                        option: (styles, { data }) => {
                            // @ts-ignore
                            if (!data.blacklist) {
                                return styles;
                            }
                            return {
                                ...styles,
                                color: 'red',
                            };
                        },
                    }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label className="m-0">Osoba kontaktowa</Form.Label>
                <Form.Select
                    className="w-75"
                    value={contractor?.contact?.id || NO_CONTACT_ID}
                    onChange={(e) => handleContactSelect(e.target.value)}
                    disabled={!contractor}>
                    <option value={NO_CONTACT_ID}></option>
                    {availableContacts.map((contact) => (
                        <option key={contact.id} value={contact.id}>{contact.name} ({contact.phoneNumber})</option>
                    ))}
                </Form.Select>
            </Form.Group>
        </FormElement>
    );
};