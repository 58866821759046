import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { TransportOrdersFilters } from '../../domain/transportOrdersFilters';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';

const buildFilters = (filters: TransportOrdersFilters) => {
    let filtersQuery = '';

    const addToFiltersQuery = (filterValue: string) => {
        if (!filtersQuery) {
            filtersQuery = filterValue;
        } else {
            filtersQuery = `${filtersQuery}&${filterValue}`;
        }
    };

    if (filters.orderMonthYear) {
        const { year, month } = filters.orderMonthYear;
        addToFiltersQuery(`orderYear=${year}&orderMonth=${month}`);
    }

    if (filters.creator) {
        addToFiltersQuery(`creatorId=${filters.creator.id}`);
    }

    if (filters.contractor) {
        addToFiltersQuery(`contractorId=${filters.contractor.id}`);
    }

    if (filters.query) {
        addToFiltersQuery(`query=${filters.query.value}`);
    }

    return filtersQuery;
};

export const useTransportOrdersRepository = (filters?: TransportOrdersFilters) => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const hasFilters = filters && Object.keys(filters).length > 0;
    const query = useQuery({

        queryKey: hasFilters ? ['transportOrders', filters] : ['transportOrders'],
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}${filters ? `?${buildFilters(filters)}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const dtos = await response.json() as Array<GetTransportOrderDto>;

            return dtos.map(toDomain);
        },
    });

    const refetch = () => {
        queryClient.invalidateQueries({
            queryKey: ['transportOrders'],
        })
    }

    return {
        ...query,
        refetch
    };
};