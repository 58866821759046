import Badge from 'react-bootstrap/Badge';

import { TransportOrderStatus } from '../domain/transportOrder';
import { transportOrderStatusesMapping } from '../utils/transportOrderStatusesMapping';

type TransportOrderStatusBadgeProps = {
    status: TransportOrderStatus,
}

export const TransportOrderStatusBadge = ({ status }: TransportOrderStatusBadgeProps) => {
    const { variant, message } = transportOrderStatusesMapping[status];
    return (
        <Badge
            className={`d-inline-block text-uppercase lh-base ${variant === 'warning' ? 'text-black' : ''}`}
            bg={variant}>
            {message}
        </Badge>
    );
};
