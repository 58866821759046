import { useState } from 'react';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';

import { FormElement } from '../../../components/FormElement';
import { TransportOrder } from '../../../domain/transportOrder';

type ShipmentUnloadingType = TransportOrder['unloading'];
type ShipmentUnloadingItemType = ShipmentUnloadingType[0];

type ShipmentUnloadingProps = {
    shipmentUnloading: ShipmentUnloadingType;
    setShipmentUnloading: (newValue: ShipmentUnloadingType) => void;
    context: {
        isTransportOrderEdit: boolean;
    }
}

const DEFAULT_ACTIVE_TAB = '0-unloading-tab';


export const ShipmentUnloading = ({ shipmentUnloading, setShipmentUnloading, context }: ShipmentUnloadingProps) => {
    const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);

    const handleUnloadingItemChange = (changedShipmentItem: ShipmentUnloadingItemType, index: number) => {
        setShipmentUnloading(shipmentUnloading.map((unloadingItem, itemIndex) => {
            if (index === itemIndex) {
                return changedShipmentItem;
            }

            return unloadingItem;
        }));
    };

    const handleAddItemClick = () => {
        const shipmentUnloadingLength = shipmentUnloading.length;

        setShipmentUnloading([...shipmentUnloading, {
            address: '',
            date: new Date(),
            endDate: new Date(),
            time: '',
            unloadingNumber: '',
            additionalInfo: '',
            completed: false,
        }]);

        setActiveTab(`${shipmentUnloadingLength}-unloading-tab`);
    };

    const handleRemoveItemClick = (index: number) => {
        setShipmentUnloading(shipmentUnloading.filter((_, itemIndex) => itemIndex !== index));
        setActiveTab(DEFAULT_ACTIVE_TAB);
    };

    const TabTitle = ({ index }: { index: number }) => <>
        {index + 1}. rozładunek <XLg className="icon-link-hover size-20-hover" onClick={() => handleRemoveItemClick(index)} />
    </>;

    return (
        <FormElement headingText="Rozładunek">
            {shipmentUnloading.length === 1 ?
                <ShipmentUnloadingItem
                    unloadingItem={shipmentUnloading[0]}
                    onChange={(changedItem) => handleUnloadingItemChange(changedItem, 0)}
                    showCompleted={context.isTransportOrderEdit}
                /> :
                <Tabs
                    className="mb-2"
                    activeKey={activeTab}
                    onSelect={(tabKey) => setActiveTab(tabKey || DEFAULT_ACTIVE_TAB)}>
                    {shipmentUnloading.map((loadingItem, index) => (
                        <Tab title={<TabTitle index={index} />} eventKey={`${index}-unloading-tab`}>
                            <ShipmentUnloadingItem
                                unloadingItem={loadingItem}
                                onChange={(changedItem) => handleUnloadingItemChange(changedItem, index)}
                                showCompleted={context.isTransportOrderEdit}
                            />
                        </Tab>
                    ))}
                </Tabs>
            }
            <div className="d-flex justify-content-center">
                <Button variant="link" onClick={handleAddItemClick} className="text-decoration-none">
                    + dodaj kolejny rozładunek
                </Button>
            </div>
        </FormElement>
    );
};

const ShipmentUnloadingItem = ({ unloadingItem, onChange, showCompleted }: {
    unloadingItem: ShipmentUnloadingItemType;
    onChange: (changedItem: ShipmentUnloadingItemType) => void;
    showCompleted: boolean;
}) => {
    const { date, endDate, time, address, unloadingNumber, additionalInfo } = unloadingItem;
    const dateAsString = date.toISOString().split('T')[0];
    const endDateAsString = endDate.toISOString().split('T')[0];

    const handleShipmentChange = (newShipment: ShipmentUnloadingItemType) => {
        onChange(newShipment);
    };

    const handleDateChange = (newDate: Date) => {
        if (newDate.getTime() > endDate.getTime()) {
            handleShipmentChange({
                ...unloadingItem,
                date: newDate,
                endDate: newDate,
            });

            return;
        }

        handleShipmentChange({
            ...unloadingItem,
            date: newDate,
        });
    };

    const handleUnloadingCompletedChange = (completed: boolean) => {
        handleShipmentChange({
            ...unloadingItem,
            completed: completed,
        });
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <Form.Group className="mb-3">
                    <Form.Label className="m-0 fw-bold">Data</Form.Label>
                    <div className="d-flex flex-row align-items-baseline">
                        <Form.Control
                            className="w-auto"
                            type="date"
                            placeholder="Data rozładunku (od)"
                            value={dateAsString}
                            onChange={(e) => handleDateChange(new Date(e.target.value))}
                        />
                        <span className="mx-1">-</span>
                        <Form.Control
                            className="w-auto"
                            type="date"
                            placeholder="Data rozładunku (do)"
                            value={endDateAsString}
                            onChange={(e) => handleShipmentChange({
                                ...unloadingItem,
                                endDate: new Date(e.target.value),
                            })}
                        />
                    </div>
                </Form.Group>
                {showCompleted && <Form.Group className="mb-3">
                    <Form.Label className="m-0 fw-bold">Status</Form.Label>
                    <Form.Check
                        type="checkbox"
                        label="Rozładowane"
                        onChange={(e) => handleUnloadingCompletedChange(e.target.checked)}
                        checked={unloadingItem.completed}
                    />
                </Form.Group>}
            </div>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bold">Godziny rozładunku</Form.Label>
                <Form.Control
                    className="w-auto"
                    type="text"
                    placeholder="Godziny rozładunku"
                    value={time}
                    onChange={(e) => handleShipmentChange({
                        ...unloadingItem,
                        time: e.target.value,
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bold">Adres</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Adres rozładunku"
                    value={address}
                    onChange={(e) => handleShipmentChange({
                        ...unloadingItem,
                        address: e.target.value,
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bold">Numer rozładunku</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Numer rozładunku"
                    value={unloadingNumber}
                    onChange={(e) => handleShipmentChange({
                        ...unloadingItem,
                        unloadingNumber: e.target.value,
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="m-0 fw-bold">Uwagi</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Uwagi do rozładunku"
                    value={additionalInfo}
                    onChange={(e) => handleShipmentChange({
                        ...unloadingItem,
                        additionalInfo: e.target.value,
                    })}
                />
            </Form.Group>
        </>
    );
};
