import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PageTitle } from '../../components/PageTitle';
import { useAuthentication } from '../auth/AuthenticationProvider';

export const LoginForm = () => {
    const { logIn } = useAuthentication();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


    const handleUsernameChange = (value: string) => {
        setUsername(value);
    };

    const handlePasswordChange = (value: string) => {
        setPassword(value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await logIn(username, password);

            window.location.reload();
        } catch (e) {
            toast.error('Błąd podczas logowania. Spróbuj ponownie');
            console.error('Error while logging', e)
        }
    };

    return <Card className="border-0">
        <Card.Body>
            <PageTitle>Zaloguj się</PageTitle>
            <Form style={{ maxWidth: 400 }} onSubmit={(e) => handleSubmit(e)}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" value={username} onChange={(e) => handleUsernameChange(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Hasło</Form.Label>
                    <Form.Control
                        type="password" value={password}
                        onChange={(e) => handlePasswordChange(e.target.value)} />
                </Form.Group>
                <div className="d-flex justify-content-between">
                    <Button type="submit">Zaloguj</Button>
                    <Link className="mb-2" to='/forgotten-password'>
                        <Button className="p-0 text-decoration-none" variant="link">
                            Nie pamiętasz hasła?
                        </Button>
                    </Link>
                </div>
            </Form>
        </Card.Body>
    </Card>;
};