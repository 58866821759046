import { URLSearchParams } from 'node:url';
import { TransportOrdersFilters as TransportOrdersFiltersType } from '../../domain/transportOrdersFilters';

export const buildFiltersFromQuery = (serachParams: URLSearchParams): TransportOrdersFiltersType => {
    let filters: TransportOrdersFiltersType = {};

    const addFilter = (filterToAdd: Partial<TransportOrdersFiltersType>) => {
        filters = {
            ...filters,
            ...filterToAdd,
        }
    }

    const orderMonth = Number(serachParams.get('orderMonth'));
    const orderYear = Number(serachParams.get('orderYear'));
    const creatorId = serachParams.get('creatorId');
    const contractorId = serachParams.get('contractorId');
    const query = serachParams.get('query');

    if (orderMonth && orderYear) {
        addFilter({ orderMonthYear: { month: orderMonth, year: orderYear }});
    }

    if (creatorId) {
        addFilter({ creator: { id: creatorId } })
    }

    if (contractorId) {
        addFilter({ contractor: { id: contractorId } })
    }

    if (query) {
        addFilter({ query: { value: query } });
    }

    return filters;
};

export const buildQueryFromFilters = (filters?: TransportOrdersFiltersType) => {
    if (!filters) {
        return '';
    }

    let filtersQuery = '';

    const addToFiltersQuery = (filterValue: string) => {
        if (!filtersQuery) {
            filtersQuery = filterValue;
        } else {
            filtersQuery = `${filtersQuery}&${filterValue}`;
        }
    };

    if (filters.orderMonthYear) {
        const { year, month } = filters.orderMonthYear;
        addToFiltersQuery(`orderYear=${year}&orderMonth=${month}`);
    }

    if (filters.creator) {
        addToFiltersQuery(`creatorId=${filters.creator.id}`);
    }

    if (filters.contractor) {
        addToFiltersQuery(`contractorId=${filters.contractor.id}`);
    }

    if (filters.query) {
        addToFiltersQuery(`query=${filters.query.value}`);
    }

    return filtersQuery;
}