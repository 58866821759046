import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Contractor } from '../../domain/contractor';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { onSuccessfulContractorChange } from './onSuccessfulContractorChange';

export const useContractorBlacklistRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changeContractorBlacklist = async (contractorId: string, blacklist: boolean) => {
        const token = await getJwtToken();

        const result = await fetch(`${BACKEND_URL}/contractors/${contractorId}`, {
            method: 'PATCH',
            body: JSON.stringify({ blacklist }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        return await result.json() as Contractor;
    };

    const addContractorToBlacklist = useMutation({
        mutationFn: (contractorId: string) => changeContractorBlacklist(contractorId, true),
        onSuccess: (changedContractor) => onSuccessfulContractorChange(changedContractor, queryClient),
    });

    const removeContractorFromBlacklist = useMutation({
        mutationFn: (contractorId: string) => changeContractorBlacklist(contractorId, false),
        onSuccess: (changedContractor) => onSuccessfulContractorChange(changedContractor, queryClient),
    });

    return {
        addContractorToBlacklist: (contractorId: string) => addContractorToBlacklist.mutateAsync(contractorId),
        removeContractorFromBlacklist: (contractorId: string) => removeContractorFromBlacklist.mutateAsync(contractorId),
    };
};