import { Button, Card, Form, Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { FormElement } from '../../../components/FormElement';
import { ContractorType } from '../useContractorForm';

type ContractorDriver = ContractorType['drivers'];
type ContractorDriverValues = ContractorDriver['value'];
type ContractorDriverValue = ContractorDriverValues['0'];

type ContractorDriversProps = {
    drivers: ContractorDriver;
}

export const ContractorDrivers = ({ drivers }: ContractorDriversProps) => {
    const handleDriversChange = (changedDrivers: ContractorDriverValues) => {
        drivers.setValue(changedDrivers);
    };
    const handleDriverChange = (driverIndex: number, newDriver: ContractorDriverValue) => {
        const updatedDrivers: ContractorDriverValues = [
            ...drivers.value.filter((_, index) => index < driverIndex),
            newDriver,
            ...drivers.value.filter((_, index) => index > driverIndex),
        ];

        handleDriversChange(updatedDrivers);
    };

    const handleDriverRemoval = (driverIndex: number) => {
        const updatedDrivers: ContractorDriverValues = [
            ...drivers.value.filter((_, index) => index < driverIndex),
            ...drivers.value.filter((_, index) => index > driverIndex),
        ];

        handleDriversChange(updatedDrivers);
    };

    const handleAddNewDriver = () => {
        handleDriversChange([
            ...drivers.value,
            { id: uuidv4(), name: '', phoneNumber: '', identityCardNumber: '' },
        ]);
    };

    return <Card className="mb-4">
        <Card.Body>
            <FormElement headingText="Kierowcy">
                <Table striped bordered hover>
                    <ContractorDriversTableHead />
                    <tbody>
                    {drivers.value.map((driver, index) => (
                        <ContractorDriverItem
                            key={driver.id}
                            driverIndex={index}
                            driver={driver}
                            onChange={(newDriver) => handleDriverChange(index, newDriver)}
                            onRemove={() => handleDriverRemoval(index)}
                        />)
                    )}
                    </tbody>
                </Table>
                <div className="text-center">
                    <Button
                        className="p-0 text-center text-decoration-none"
                        variant="link"
                        onClick={handleAddNewDriver}>
                        + Dodaj kierowcę
                    </Button>
                </div>
            </FormElement>
        </Card.Body>
    </Card>;
};

type ContractorDriverItemProps = {
    driverIndex: number;
    driver: ContractorDriverValue;
    onChange: (newDriver: ContractorDriverValue) => void;
    onRemove: () => void
};

const ContractorDriverItem = ({ driverIndex, driver, onChange, onRemove }: ContractorDriverItemProps) => {
    const handleNameChange = (value: string) => {
        onChange({ ...driver, name: value });
    };
    const handlePhoneNumberChange = (value: string) => {
        onChange({ ...driver, phoneNumber: value });
    };
    const handleIdentityCardNumberChange = (value: string) => {
        onChange({ ...driver, identityCardNumber: value });
    };

    const handleRemoveButtonClick = () => {
        onRemove();
    };

    return (
        <tr>
            <td className="text-center align-middle width-0">
                {driverIndex + 1}
            </td>
            <td>
                <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Imie i nazwisko"
                    value={driver.name}
                    onChange={(e) => handleNameChange(e.target.value)} /></td>
            <td>
                <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Numer telefonu"
                    value={driver.phoneNumber}
                    onChange={(e) => handlePhoneNumberChange(e.target.value)} />
            </td>
            <td>
                <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Nr. dowodu"
                    value={driver.identityCardNumber}
                    onChange={(e) => handleIdentityCardNumberChange(e.target.value)} /></td>
            <td className="width-0">
                <Button
                    className="text-danger text-decoration-none"
                    variant="link"
                    onClick={handleRemoveButtonClick}>
                    Usuń
                </Button>
            </td>
        </tr>
    );
};

const ContractorDriversTableHead = () => (
    <thead>
    <tr>
        <th className="text-center"><span className="px-1">#</span></th>
        <th>Imie i nazwisko</th>
        <th>Numer telefonu</th>
        <th>Nr. dowodu</th>
        <th className="width-0"></th>
    </tr>
    </thead>
);
