import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TransportOrder } from '../../domain/transportOrder';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';

export const useTransportOrderRepository = (transportOrderId: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery<TransportOrder>({
        queryKey: ['transportOrders', { id: transportOrderId }],
        staleTime: 45 * 60 * 1000, // 45 minutes
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}/${transportOrderId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            const dto = await response.json() as GetTransportOrderDto;
            return toDomain(dto);
        },
    });

    return {
        ...query,
    };
};