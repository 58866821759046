import { Form } from 'react-bootstrap';

type TextFormGroupProps = {
     label: string;
    value: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    className?: string;
    inputClassName?: string
}

const noop = () => {
};

export const TextFormGroup = ({
        label,
        value,
        placeholder = label,
        onChange = noop,
        className = '',
        inputClassName = "w-100",
    }: TextFormGroupProps) => {
    const handleChange = (value: string) => {
        onChange(value);
    };

    return <Form.Group className={`mb-3 ${className}`}>
        <Form.Label className="m-0 fw-bolder">{label}</Form.Label>
        <Form.Control
            className={inputClassName}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
        />
    </Form.Group>;
};