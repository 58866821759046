import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';

export const useDownloadContractorFileRepository = (contractorId: string, filePath: string, fileName: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['contractors', 'files', { id: contractorId }, 'downloadFileUrl', { filePath, fileName }],
        placeholderData: keepPreviousData,
        enabled: false,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(filePath, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            return URL.createObjectURL(blob);
        }
    });

    const getDownloadContractorFileUrl = async () => {
        let downloadUrl: string;

        if (!query.data || query.isStale) {
            const result = await query.refetch();
            downloadUrl = result.data!;
        } else {
            downloadUrl = query.data;
        }

        return downloadUrl;
    }

    return {
        ...query,
        getDownloadContractorFileUrl
    }
}