import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { CompanyLogo } from '../../domain/companyLogo';

import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useCompanyLogoRepository = () => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['company-logo'],
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/settings/company-logo`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return (await response.json()) as CompanyLogo;
        },
    });

    return {
        ...query,
    }
}