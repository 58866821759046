import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

type UploadFilesToContractorMutationType = {
    contractorId: string;
    files: Array<File>;
}

export const useUploadFilesToContractorRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const uploadFiles = useMutation({
        mutationFn: (variable: UploadFilesToContractorMutationType) => uploadFilesFunction(variable.contractorId, variable.files),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['contractors', 'files'] });
        },
    });

    const uploadFilesFunction = async (contractorId: string, files: Array<File>) => {
        const token = await getJwtToken();

        const formData = new FormData();

        // Append all files to the formData instance
        files.forEach((file) => {
            formData.append('files', file);
        });

        await fetch(`${BACKEND_URL}/contractors/${contractorId}/files`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    return {
        uploadFiles: (variables: UploadFilesToContractorMutationType) => uploadFiles.mutateAsync(variables),
    };
};