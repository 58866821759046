import { ReactNode, useState } from 'react';
import { Variant } from 'react-bootstrap/types';

export type NotificationType = 'ContractorChangeNotification' | 'ContractorOnBlacklist';

export type Notification = {
    id: string;
    type: NotificationType;
    title: string;
    message: string | ReactNode;
    variant: Variant;
};

export const useTransportOrderNotifications = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const addNotification = (newNotification: Notification) => {
        setNotifications([
            ...notifications,
            newNotification
        ]);
    };

    const replaceOrAddNotificationOfType = (newNotification: Notification) => {
        setNotifications((prevNotifications) => {
            const filteredNotifications = prevNotifications.filter((notification) => notification.type !== newNotification.type);
            return [...filteredNotifications, newNotification];
        });
    }

    const removeNotificationsOfType = (type: NotificationType) => {
        setNotifications(notifications.filter((notification) => notification.type !== type));
    }

    return {
        notifications,
        addNotification,
        removeNotificationsOfType,
        replaceOrAddNotificationOfType,
    }
}
