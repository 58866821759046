import { Button, Card, Form, Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { FormElement } from '../../../components/FormElement';
import { ContractorType } from '../useContractorForm';

type ContractorVehicle = ContractorType['vehicles'];
type ContractorVehicleValues = ContractorVehicle['value'];
type ContractorVehicleValue = ContractorVehicleValues['0'];

type ContractorVehiclesProps = {
    vehicles: ContractorVehicle;
}

export const ContractorVehicles = ({ vehicles }: ContractorVehiclesProps) => {
    const handleVehiclesChange = (changedVehicles: ContractorVehicleValues) => {
        vehicles.setValue(changedVehicles);
    };
    const handleVehicleChange = (vehicleIndex: number, newVehicle: ContractorVehicleValue) => {
        const updatedVehicles: ContractorVehicleValues = [
            ...vehicles.value.filter((_, index) => index < vehicleIndex),
            newVehicle,
            ...vehicles.value.filter((_, index) => index > vehicleIndex),
        ];

        handleVehiclesChange(updatedVehicles);
    };

    const handleVehicleRemoval = (vehicleIndex: number) => {
        const updatedVehicles: ContractorVehicleValues = [
            ...vehicles.value.filter((_, index) => index < vehicleIndex),
            ...vehicles.value.filter((_, index) => index > vehicleIndex),
        ];

        handleVehiclesChange(updatedVehicles);
    };

    const handleAddNewVehicle = () => {
        handleVehiclesChange([
            ...vehicles.value,
            { id: uuidv4(), licensePlate: '' },
        ]);
    };

    return <Card className="mb-4">
        <Card.Body>
            <FormElement headingText="Samochody">
                <Table striped bordered hover>
                    <ContractorVehiclesTableHead />
                    <tbody>
                    {vehicles.value.map((vehicle, index) => (
                        <ContractorVehicleItem
                            key={vehicle.id}
                            vehicleIndex={index}
                            vehicle={vehicle}
                            onChange={(newVehicle) => handleVehicleChange(index, newVehicle)}
                            onRemove={() => handleVehicleRemoval(index)}
                        />)
                    )}
                    </tbody>
                </Table>
                <div className="text-center">
                    <Button
                        className="p-0 text-center text-decoration-none"
                        variant="link"
                        onClick={handleAddNewVehicle}>
                        + Dodaj samochód
                    </Button>
                </div>
            </FormElement>
        </Card.Body>
    </Card>;
};

type ContractorVehicleItemProps = {
    vehicleIndex: number;
    vehicle: ContractorVehicleValue;
    onChange: (newVehicle: ContractorVehicleValue) => void;
    onRemove: () => void
};

const ContractorVehicleItem = ({ vehicleIndex, vehicle, onChange, onRemove }: ContractorVehicleItemProps) => {
    const handleLicensePlateChange = (value: string) => {
        onChange({ ...vehicle, licensePlate: value });
    };

    const handleRemoveButtonClick = () => {
        onRemove();
    };

    return (
        <tr>
            <td className="text-center align-middle width-0">
                {vehicleIndex + 1}
            </td>
            <td>
                <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Numer rejestracyjny"
                    value={vehicle.licensePlate}
                    onChange={(e) => handleLicensePlateChange(e.target.value)} />
            </td>
            <td className="width-0">
                <Button
                    className="text-danger text-decoration-none"
                    variant="link"
                    onClick={handleRemoveButtonClick}>
                    Usuń
                </Button>
            </td>
        </tr>
    );
};

const ContractorVehiclesTableHead = () => (
    <thead>
    <tr>
        <th className="text-center"><span className="px-1">#</span></th>
        <th>Numer rejestracyjny</th>
        <th className="width-0"></th>
    </tr>
    </thead>
);
