import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useTransportOrdersRepository } from '../../repository/transport-orders/useTransportOrdersRepository';

type SearchSelectProps = {
    className?: string;
}

export const TransportOrderSelectByOrderId = ({ className = '' }: SearchSelectProps) => {
    const navigate = useNavigate();
    const { data: transportOrders = [] } = useTransportOrdersRepository();

    const handleItemClick = (value?: string) => {
        if (value) {
            navigate(value)
        }
    }

    const orderIdsOptions = transportOrders
        .filter(({ orderId }) => !!orderId)
        .map(({ id, orderId }) => ({
            value: `/transport-orders/${id}`,
            label: orderId,
        }));

    return <Select
        className={className}
        placeholder="Wyszukaj po numerze"
        noOptionsMessage={() => "Brak zleceń"}
        components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
        }}
        isSearchable
        options={orderIdsOptions}
        onChange={(newValue) => handleItemClick(newValue?.value)}
    />
}