import React from 'react';
import { Button, Form, Image, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useCompanyLogoRepository } from '../../../repository/company-logo/useCompanyLogoRepository';
import { useUploadCompanyLogoRepository } from '../../../repository/company-logo/useUploadCompanyLogoRepository';

export const CompanyLogo = () => {
    const { data } = useCompanyLogoRepository();
    const { uploadFile } = useUploadCompanyLogoRepository();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            await uploadFile({ companyLogo: file });

            toast.success('Pomyślnie zmieniono logo');
        }
    };

    if (!data) {
        return <Spinner />;
    }

    return (
        <>
            <p>
                <em>(*) Logo powinno być w orientacji poziomej i nie powinno mieć żadnych marginesów</em>
            </p>
            {data && <Image className="mb-2" style={{ maxWidth: 400, maxHeight: 400 }} src={data.base64} alt="image" />}
            <Form.Group>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="outline-primary" onClick={() => document.getElementById('fileInput')?.click()}>
                        {data ? 'Zmień logo' : 'Wybierz logo'}
                    </Button>
                </div>
                <Form.Control
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </Form.Group>
        </>
    );
};
