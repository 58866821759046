import { useEffect, useState } from 'react';

import { TransportOrder } from '../../../../domain/transportOrder';
import { useContractorsRepository } from '../../../../repository/contractors/useContractorsRepository';
import { Notification } from '../../edit/useTransportOrderNotifications';
import { checkContractor } from './checkContractor';
import { TransportOrderContractorMutable } from './TransportOrderContractorMutable';
import { TransportOrderContractorReadonly } from './TransportOrderContractorReadonly';

type ContractorType = TransportOrder['contractor'];

type TransportOrderContractorProps = {
    onUpdateContractorDetails: (newContractor?: { id: string, contactId?: string }) => void;
    contractor: ContractorType;
    setContractor: (newValue: ContractorType) => void;
    addNotification: (notification: Notification) => void;
    initiallyOnEditMode?: boolean;
}

export const TransportOrderContractor = ({
    contractor,
    setContractor,
    initiallyOnEditMode = false,
    onUpdateContractorDetails,
    addNotification,
}: TransportOrderContractorProps) => {
    const [editMode, setEditMode] = useState(initiallyOnEditMode || !contractor);
    const { data: contractors = [], isLoading } = useContractorsRepository();

    const updateContractorDetails = (newContractor: { id: string, contactId?: string } | undefined) => {
        onUpdateContractorDetails(newContractor);
        window.location.reload();
    };

    const handleChange = (newContractor: TransportOrder['contractor']) => {
        setContractor(newContractor);
        setEditMode(true);
    };

    useEffect(() => {
        if (contractors.length > 0 && contractor) {
            const contractorChecker = checkContractor(
                contractors,
                addNotification,
                handleChange,
                updateContractorDetails,
            );
            contractorChecker(contractor);
        }
    }, [contractors]);

    if (!editMode) {
        return <TransportOrderContractorReadonly
            contractor={contractor as NonNullable<ContractorType>}
            onContractorEditClick={() => setEditMode(true)}
        />;
    }

    const isReady = !!contractors && !isLoading;

    if (!isReady) {
        return null;
    }

    return <TransportOrderContractorMutable
        contractor={contractor}
        setContractor={setContractor}
        contractors={contractors}
    />;
};
