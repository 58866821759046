import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDER_FILES_PATH } from '../paths';

type TransportOrderFilePresignedUrlDTO = {
    url: string;
}
export const useTransportOrderPresignedFileUrlRepository = (transportOrderId: string, fileName: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['transportOrders', 'files', { id: transportOrderId }, 'presignedFileUrl', { fileName }],
        placeholderData: keepPreviousData,
        enabled: false,
        staleTime: 15 * 60 * 1000, // 15 minutes
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDER_FILES_PATH(transportOrderId)}/${fileName}/presigned-url`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json() as TransportOrderFilePresignedUrlDTO;

            return data.url;
        }
    });

    const getPresignedUrl = async () => {
        let presignedUrl: string;

        if (!query.data || query.isStale) {
            const result = await query.refetch();
            presignedUrl = result.data!;
        } else {
            presignedUrl = query.data;
        }

        return presignedUrl;
    }

    return {
        ...query,
        getPresignedUrl,
    }
}