import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { EditableLink } from '../../../components/EditableLink';
import { FormElement } from '../../../components/FormElement';
import { TransportOrder } from '../../../domain/transportOrder';
import { useFindAllUsersRepository } from '../../../repository/users/useFindAllUsersRepository';

type CreatedByProps = {
    creator: TransportOrder['creator'];
    setCreator: (newCreator: TransportOrder['creator']) => void;
}

export const CreatedBy = ({ creator, setCreator }: CreatedByProps) => {
    const [isEditMode, setIsEditMode] = useState(false);

    const { data: usersAccounts = [] } = useFindAllUsersRepository();

    const mappedUsersToCreators: Array<TransportOrder['creator']> = usersAccounts.map((user) => ({
        id: user.sub,
        name: `${user.firstName} ${user.lastname}`,
        email: user.email,
        phoneNumber: user.phoneNumber,
    }))

    const handleUserSelect = (userId: string) => {
        const newCreator = mappedUsersToCreators.find((userAccount) => userAccount.id === userId);

        if (!newCreator) {
            toast.warning('Nie znaleziono użytkownika. Odśwież stronę')
            return;
        }

        setCreator(newCreator);
    }

    return (
        <FormElement headingText="Utworzone przez">
            {!isEditMode && <EditableLink to="#" onEditClick={() => setIsEditMode(true)}>{creator.name}</EditableLink>}
            {isEditMode && <Form.Select
                className="w-75"
                value={creator.id}
                onChange={(e) => handleUserSelect(e.target.value)}
            >
                {mappedUsersToCreators.map((creator) => (
                    <option key={creator.id} value={creator.id}>{creator.name}</option>
                ))}
            </Form.Select>}
        </FormElement>
    );
}
