import { Form } from 'react-bootstrap';

import { FormElement } from '../../../components/FormElement';
import { TransportOrder } from '../../../domain/transportOrder';

type CommentProps = {
    comment: TransportOrder['comment'];
    setComment: (newValue: TransportOrder['comment']) => void;
}

export const Comment = ({ comment, setComment }: CommentProps) => (
    <FormElement headingText="Komentarz*">
        <Form.Label className="fst-italic text-secondary">(*) Niewidoczny na wygenerowanym pdf</Form.Label>
        <Form.Control
            as="textarea"
            rows={5}
            placeholder="Dodatkowy komentarz"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
        />
    </FormElement>
);