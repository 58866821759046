import { ReactNode } from 'react';
import { TwoColumns } from './TwoColumns';

type OneOrTwoColumnsProps = {
    children: [ReactNode, ReactNode];
    mode: 'column' | 'row'
}

export const OneOrTwoColumns = ({ mode, children: [first, second] }: OneOrTwoColumnsProps) => {
    if (mode === 'column') {
        return <>
            {first}
            {second}
        </>;
    }
    return <TwoColumns>
        {first}{second}
    </TwoColumns>;
};