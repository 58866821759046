import { Contractor } from '../../domain/contractor';
import { ContractorType } from './useContractorForm';

type ContractorChanges = {
    anyChanges: boolean;
    changes: Array<keyof ContractorType>;
}
export const useContractorChanged = (contractor: ContractorType, originalContractor: Contractor): ContractorChanges => {
    const checkStringField = (a: string, b: string, name: keyof ContractorType): Array<keyof ContractorType> => a !== b ? [name] : [];

    const checkContacts = (a: Contractor['contacts'], b: Contractor['contacts']): Array<keyof ContractorType> => {
        const contactsChanged = (() => {
            if (a.length !== b.length) {
                return true;
            }

            return a.some((aItem, index) => {
                const bItem = b[index];

                return aItem.name !== bItem.name || aItem.email !== bItem.email || aItem.phoneNumber !== bItem.phoneNumber;
            });
        })();

        return contactsChanged ? ['contacts'] : [];
    };

    const checkDrivers = (a: Contractor['drivers'], b: Contractor['drivers']): Array<keyof ContractorType> => {
        const driversChanged = (() => {
            if (a.length !== b.length) {
                return true;
            }

            return a.some((aItem, index) => {
                const bItem = b[index];

                return aItem.name !== bItem.name || aItem.phoneNumber !== bItem.phoneNumber || aItem.identityCardNumber !== bItem.identityCardNumber;
            });
        })();

        return driversChanged ? ['drivers'] : [];
    }

    const checkVehicles = (a: Contractor['vehicles'], b: Contractor['vehicles']): Array<keyof ContractorType> => {
        const vehiclesChanged = (() => {
            if (a.length !== b.length) {
                return true;
            }

            return a.some((aItem, index) => {
                const bItem = b[index];

                return aItem.licensePlate !== bItem.licensePlate;
            });
        })();

        return vehiclesChanged ? ['vehicles'] : [];
    }

    const checkTrailers = (a: Contractor['trailers'], b: Contractor['trailers']): Array<keyof ContractorType> => {
        const trailersChanged = (() => {
            if (a.length !== b.length) {
                return true;
            }

            return a.some((aItem, index) => {
                const bItem = b[index];

                return aItem.licensePlate !== bItem.licensePlate;
            });
        })();

        return trailersChanged ? ['trailers'] : [];
    }

    const changes: Array<keyof ContractorType> = [
        ...checkStringField(contractor.name.value, originalContractor.name, 'name'),
        ...checkStringField(contractor.address.value, originalContractor.address, 'address'),
        ...checkStringField(contractor.nip.value, originalContractor.nip, 'nip'),
        ...checkStringField(contractor.email.value, originalContractor.email, 'email'),
        ...checkStringField(contractor.phoneNumber.value, originalContractor.phoneNumber, 'phoneNumber'),
        ...checkStringField(contractor.additionalInfo.value, originalContractor.additionalInfo, 'additionalInfo'),
        ...checkContacts(contractor.contacts.value, originalContractor.contacts),
        ...checkDrivers(contractor.drivers.value, originalContractor.drivers),
        ...checkVehicles(contractor.vehicles.value, originalContractor.vehicles),
        ...checkTrailers(contractor.trailers.value, originalContractor.trailers),
    ];

    return {
        anyChanges: changes.length > 0,
        changes,
    };
};
