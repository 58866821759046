import { useState } from 'react';

import { TransportOrderConfig } from '../../../domain/transportOrderConfig';

export const useTransportOrderConfigForm = (initialTransportOrderConfig: TransportOrderConfig) => {
    const [transportOrderConfig, setTransportOrderConfig] = useState<TransportOrderConfig>(initialTransportOrderConfig || {});

    return {
        transportOrderConfig,
        setTransportOrderConfig,
    }
}
