import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ContractorFile } from '../../domain/contractorFile';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export type ContractorFileDto = {
    name: string;
    path: string;
    lastModified: number;
    byteSize: number;
}

export const useListFilesByContractorIdRepository = (contractorId: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['contractors', 'files', { id: contractorId }],
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/contractors/${contractorId}/files`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const filesFromResponse = await response.json() as Array<ContractorFileDto>;

            return filesFromResponse.map((file): ContractorFile => ({
                name: file.name,
                path: `${BACKEND_URL}${file.path}`,
                date: new Date(file.lastModified),
                byteSize: file.byteSize,
            }));
        }
    });

    return {
        ...query,
    }
};