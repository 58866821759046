import { Form } from 'react-bootstrap';

import { FormElement } from '../../../components/FormElement';
import { TransportOrder } from '../../../domain/transportOrder';

type AdditionalInfoProps = {
    additionalInfo: TransportOrder['additionalInfo'];
    setAdditionalInfo: (newValue: TransportOrder['additionalInfo']) => void;
    className?: string;
}

export const AdditionalInfo = ({ additionalInfo, setAdditionalInfo, className = '' }: AdditionalInfoProps) => (
    <FormElement className={className} headingText="Dodatkowe informacje">
        <Form.Control
            as="textarea"
            rows={8}
            placeholder="Dodatkowe informacje dotyczące zlecenia"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
        />
    </FormElement>
);