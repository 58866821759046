import { TextFormGroup } from '../../../components/TextFormGroup';
import { FormElement } from '../../../components/FormElement';
import { TransportOrder } from '../../../domain/transportOrder';

type VehicleType = TransportOrder['vehicle'];

type VehicleProps = {
    vehicle: VehicleType;
    setVehicle: (newValue: VehicleType) => void;
}

export const Vehicle = ({ vehicle, setVehicle }: VehicleProps) => (
    <FormElement className="w-75" headingText="Pojazd">
        <TextFormGroup
            label="Numer rejestracyjny samochodu"
            value={vehicle.carLicensePlate}
            onChange={(newCarLicensePlate) => setVehicle({ ...vehicle, carLicensePlate: newCarLicensePlate })}
        />
        <TextFormGroup
            label="Numer rejestracyjny naczepy"
            value={vehicle.trailerLicensePlate}
            onChange={(newTrailerLicensePlate) => setVehicle({
                ...vehicle,
                trailerLicensePlate: newTrailerLicensePlate,
            })}
        />
    </FormElement>
);
