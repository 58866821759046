import { TransportOrderConfig } from '../../../domain/transportOrderConfig';


type TransportOrderConfigChanges = {
    anyChanges: boolean;
    changes: Array<keyof TransportOrderConfig>;
}

export const useTransportOrderConfigChanged = (transportOrderConfig: TransportOrderConfig, originalTransportOrderConfig: TransportOrderConfig): TransportOrderConfigChanges => {
    const checkStringField = (a: string, b: string, name: keyof TransportOrderConfig): Array<keyof TransportOrderConfig> => a !== b ? [name] : [];

    const changes: Array<keyof TransportOrderConfig> = [
        ...checkStringField(transportOrderConfig.defaultAdditionalInfo, originalTransportOrderConfig.defaultAdditionalInfo, 'defaultAdditionalInfo'),
        ...checkStringField(transportOrderConfig.paymentAnnotation, originalTransportOrderConfig.paymentAnnotation, 'paymentAnnotation'),
        ...checkStringField(transportOrderConfig.paymentDaysAnnotation, originalTransportOrderConfig.paymentDaysAnnotation, 'paymentDaysAnnotation'),
    ];

    return {
        anyChanges: changes.length > 0,
        changes
    };
};