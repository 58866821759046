import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { FormElement } from '../../../components/FormElement';
import { TextareaFormGroup } from '../../../components/TextareaFormGroup';
import { TransportOrderConfig as TransportOrderConfigType } from '../../../domain/transportOrderConfig';
import {
    useChangeTransportOrderConfigRepository,
} from '../../../repository/settings/useChangeTransportOrderConfigRepository';
import { useTransportOrderConfigChanged } from './useTransportOrderConfigChanged';
import { useTransportOrderConfigForm } from './useTransportOrderConfigForm';

type TransportOrderPdfConfigProps = {
    originalTransportOrderConfig: TransportOrderConfigType;
}

export const TransportOrderConfig = ({ originalTransportOrderConfig }: TransportOrderPdfConfigProps) => {
    const { transportOrderConfig, setTransportOrderConfig } = useTransportOrderConfigForm(originalTransportOrderConfig);

    const { anyChanges } = useTransportOrderConfigChanged(transportOrderConfig, originalTransportOrderConfig);

    const { changeTransportOrderConfig } = useChangeTransportOrderConfigRepository();

    const handleTransportOrderConfigChange = (change: Partial<TransportOrderConfigType>) => {
        setTransportOrderConfig({
            ...transportOrderConfig,
            ...change,
        });
    }

    const handleSubmitButtonClick = async () => {
        await changeTransportOrderConfig(transportOrderConfig);

        toast.success('Pomyślnie zmieniono konfigurację zlecenia');
    };

    return <>
        <FormElement headingText="Konfiguracja zlecenia">
            <TextareaFormGroup
                className="w-50"
                label="Domyślne dodatkowe informacje o zleceniu"
                value={transportOrderConfig.defaultAdditionalInfo}
                onChange={(value) => handleTransportOrderConfigChange({ defaultAdditionalInfo: value })}
                placeholder="Domyślne dodatkowe informacje o zleceniu"
                rows={5}
            />
            <TextareaFormGroup
                className="w-50"
                label="Adnotacja do płatnosci"
                value={transportOrderConfig.paymentAnnotation}
                onChange={(value) => handleTransportOrderConfigChange({ paymentAnnotation: value })}
                rows={5}
            />
            <TextareaFormGroup
                className="w-50"
                label="Adnotacja do liczby dni płatności"
                value={transportOrderConfig.paymentDaysAnnotation}
                onChange={(value) => handleTransportOrderConfigChange({ paymentDaysAnnotation: value })}
                rows={5}
            />
        </FormElement>
        <div className="d-flex flex-row justify-content-end">
            <Button disabled={!anyChanges} variant="primary" onClick={handleSubmitButtonClick}>Zapisz</Button>
        </div>
    </>
}
