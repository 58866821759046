import React, { useState } from 'react';
import { PlusLg } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { LoadingSkeletonTableRows } from '../../components/LoadingSkeletonTableRows';
import { PageTitle } from '../../components/PageTitle';
import { RefreshButton } from '../../components/RefreshButton';
import { useClientsRepository } from '../../repository/clients/useClientsRepository';

import { ClientsTableHeader } from './ClientsTableHeader';
import { ClientTableRow } from './ClientsTableRow';
import { NewClientModal } from './NewClientModal';

export const Clients = () => {
    const [showNewClientForm, setShowNewClientForm] = useState(false);
    const { data: clients, refetch, isLoading, isRefetching } = useClientsRepository();

    const handleShowNewClientFormOpen = () => setShowNewClientForm(true);
    const handleShowNewClientFormClose = () => setShowNewClientForm(false);

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-row">
                    <PageTitle>Klienci</PageTitle>
                    <RefreshButton
                        className="ms-2"
                        isRefreshing={isLoading || isRefetching}
                        refreshFunction={refetch}
                    />
                </div>
                <Button onClick={handleShowNewClientFormOpen}><PlusLg />{' '}Nowy klient</Button>
            </div>
            <Table striped bordered hover>
                <ClientsTableHeader />
                <tbody>{!clients ? <LoadingSkeletonTableRows rows={5} columns={7} /> :
                    clients.map((client, index) => <ClientTableRow
                        key={`client-${client.id}`}
                        index={index}
                        client={client}
                    />)}</tbody>
            </Table>
            {clients?.length === 0 &&
                <p className="d-flex align-self-center">Brak klientów.{' '}
                    <Button className="p-0 border-0 ms-2 text-decoration-none" variant="link" onClick={handleShowNewClientFormOpen}>
                        Utwórz klienta
                    </Button>
                </p>}
            <NewClientModal show={showNewClientForm} onClose={handleShowNewClientFormClose} />
        </>
    );
};
