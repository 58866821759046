import { useEffect, useState } from 'react';

import { Client } from '../../domain/client';

export type ClientType = {
    id: { value?: string },
    name: { value: string, setValue: (value: string) => void },
    address: { value: string, setValue: (value: string) => void },
    nip: { value: string, setValue: (value: string) => void },
    phoneNumber: { value: string, setValue: (value: string) => void },
    email: { value: string, setValue: (value: string) => void },
    additionalInfo: { value: string, setValue: (value: string) => void },
    contacts: { value: Client['contacts'], setValue: ((value: Client['contacts']) => void) },
}

export const useClientForm = (client?: Client): ClientType => {
    const [name, setName] = useState(client?.name || '');
    const [address, setAddress] = useState(client?.address || '');
    const [nip, setNip] = useState(client?.nip || '');
    const [phoneNumber, setPhoneNumber] = useState(client?.phoneNumber || '');
    const [email, setEmail] = useState(client?.email || '');
    const [additionalInfo, setAdditionalInfo] = useState(client?.additionalInfo || '');
    const [contacts, setContacts] = useState(client?.contacts || []);


    useEffect(() => {
        if (client) {
            setName(client.name);
            setAddress(client.address);
            setNip(client.nip);
            setPhoneNumber(client.phoneNumber);
            setEmail(client.email);
            setAdditionalInfo(client.additionalInfo);
            setContacts(client.contacts);

        }
    }, [client])

    return {
        id: { value: client?.id },
        name: { value: name, setValue: (value) => setName(value) },
        address: { value: address, setValue: (value) => setAddress(value) },
        nip: { value: nip, setValue: (value) => setNip(value) },
        phoneNumber: { value: phoneNumber, setValue: (value) => setPhoneNumber(value) },
        email: { value: email, setValue: (value) => setEmail(value) },
        additionalInfo: { value: additionalInfo, setValue: (value) => setAdditionalInfo(value) },
        contacts: { value: contacts, setValue: (value) => setContacts(value) },
    };
};
