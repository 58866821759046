export const fileSizeTextFromBytes = (byteSize: number) => {
    if (byteSize < 1024) {
        return `${byteSize} B`;
    } else if (byteSize < 1024 * 1024) {
        return `${(byteSize / 1024).toFixed(2)} KB`;
    } else if (byteSize < 1024 * 1024 * 1024) {
        return `${(byteSize / (1024 * 1024)).toFixed(2)} MB`;
    } else {
        return `${(byteSize / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
};
