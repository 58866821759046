import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import {
    TransportOrderContractorLinkWithContactDetails
} from '../../components/TransportOrderContractorLinkWithContactDetails';
import { TransportOrderFeedItem } from '../../domain/transportOrdersFeed';
import {
    useChangeTransportOrderLoadingCompletedRepository,
} from '../../repository/transport-orders/useChangeTransportOrderLoadingCompletedRepository';
import {
    useChangeTransportOrderUnloadingCompletedRepository,
} from '../../repository/transport-orders/useChangeTransportOrderUnloadingCompletedRepository';
import { TransportOrderFeedStatusBadge } from './TransportOrderFeedStatusBadge';
import './TransportOrderFeedTable.scss';

type TransportOrderFeedTableRowProps = {
    item: TransportOrderFeedItem;
    dateIncluded: boolean;
    context: {
        shipmentType: 'loading' | 'unloading'
    };
}

export const TransportOrderFeedTableRow = ({ item, dateIncluded, context }: TransportOrderFeedTableRowProps) => {
    const isStatusDone = item.type === 'loaded' || item.type === 'unloaded';
    const [showAdditionalElements, setShowAdditionalElements] = useState(!isStatusDone);

    const { changeTransportOrderLoadingCompleted } = useChangeTransportOrderLoadingCompletedRepository();
    const { changeTransportOrderUnloadingCompleted } = useChangeTransportOrderUnloadingCompletedRepository();

    const dateToString = (date: Date) =>
        date.toLocaleDateString('pl-PL', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });

    const buildDate = (date: Date, dateEnd: Date) => {
        if (date.getDate() === dateEnd.getDate()) {
            return dateToString(date);
        }

        if (dateEnd) {
            return (<>{dateToString(date)} -<br />{dateToString(dateEnd)}</>);
        } else {
            return dateToString(date);
        }
    };

    const handleElementCheckboxChange = async (index: number, completed: boolean) => {
        if (context.shipmentType === 'loading') {
            await changeTransportOrderLoadingCompleted({
                transportOrderId: item.id,
                loadingItem: {
                    index,
                    completed,
                },
            });
        } else if (context.shipmentType === 'unloading') {
            await changeTransportOrderUnloadingCompleted({
                transportOrderId: item.id,
                unloadingItem: {
                    index,
                    completed,
                },
            });
        }
    };

    const truncate = (text: string, limit: number) => {
        if (text.length > limit) {
            return `${text.slice(0, limit)}...`;
        }
        return text;
    };

    const getAddressSummary = () => item.elements.map(({ address }) => `${truncate(address, 17)}`)
        .join(', ');

    return (
        <>
            <div className="grid-cell">
                <Link to={`/transport-orders/${item.id}`}>{item.orderId}</Link>
            </div>
            <div className="grid-cell text-center">
                <TransportOrderFeedStatusBadge
                    transportOrderId={item.id}
                    status={item.type}
                />
            </div>
            {dateIncluded && <div className="grid-cell">
                {buildDate(new Date(item.date), new Date(item.endDate))}
            </div>}
            <div className="grid-cell">
                {item.elements.length > 1 ?
                    <>
                        <Button className="p-0 me-2 text-decoration-none" variant="link" onClick={() => setShowAdditionalElements(!showAdditionalElements)}>
                            {showAdditionalElements ? <ChevronUp /> : <ChevronDown />} {item.elements.length} adresy
                        </Button>
                        {getAddressSummary()}
                    </> :
                    item.elements[0].address
                }
            </div>
            <div className="grid-cell text-nowrap text-truncate">
                {!item.contractor && <span className="fst-italic">Brak podwykonawcy</span>}
                {item.contractor &&
                    <TransportOrderContractorLinkWithContactDetails contractor={item.contractor} />
                }
            </div>
            {item.elements.length > 1 && showAdditionalElements && item.elements.map((element, index) => <>
                <div className="grid-cell"></div>
                <div className="grid-cell d-flex justify-content-around">
                    <Form.Check
                        className="fw-medium"
                        id={`${index}`}
                        checked={element.completed}
                        onChange={(e) => handleElementCheckboxChange(index, e.target.checked)}
                        label={element.completed ?
                            <span className="cursor-pointer">Potwierdzone</span> :
                            <span className="cursor-pointer">Potwierdź</span>
                        }
                    />
                </div>
                {dateIncluded && <div className="grid-cell">
                    {buildDate(new Date(element.date), new Date(element.endDate))}
                </div>}
                <div className="grid-cell">{element.address}</div>
                <div className="grid-cell"></div>
            </>)}
        </>
    );
};