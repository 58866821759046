import { ReactElement, ReactNode } from 'react';

type FormElementProps = {
    headingText?: string | ReactElement;
    children: ReactNode;
    className?: string
}

export const FormElement = ({ headingText, children, className = '' }: FormElementProps) => <div
    className={`mb-3 ${className}`}>
    {headingText && (typeof headingText === 'string') && <h4>{headingText}</h4>}
    {headingText && (typeof headingText !== 'string') && headingText}
    {children}
</div>;
