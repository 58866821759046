import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';
import { onSuccessfulTransportOrderChange } from './onSuccessfulTransportOrderChange';

type ChangeTransportOrderOrderIdMutationType = { transportOrderId: string, newOrderId: string };

export const useChangeTransportOrderOrderId = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changeTransportOrderOrderId = useMutation({
        mutationFn: (variable: ChangeTransportOrderOrderIdMutationType) => changeTransportOrderOrderIdFunction(variable.transportOrderId, variable.newOrderId),
        onSuccess: (changedTransportOrder) => onSuccessfulTransportOrderChange(changedTransportOrder, queryClient),
    });

    const changeTransportOrderOrderIdFunction = async (transportOrderId: string, newOrderId: string) => {
        const token = await getJwtToken();

        const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}/${transportOrderId}`, {
            method: 'PATCH',
            body: JSON.stringify({ orderId: newOrderId }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        const dto = await response.json() as GetTransportOrderDto;

        return toDomain(dto)
    };

    return {
        changeTransportOrderOrderId: (variables: ChangeTransportOrderOrderIdMutationType) => changeTransportOrderOrderId.mutateAsync(variables),
    };
};