import { Form } from 'react-bootstrap';
import { PlusLg } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link, useSearchParams } from 'react-router-dom';

import { PageTitle } from '../../components/PageTitle';
import { RefreshButton } from '../../components/RefreshButton';
import { TransportOrdersFilters as TransportOrdersFiltersType } from '../../domain/transportOrdersFilters';
import { useTransportOrdersRepository } from '../../repository/transport-orders/useTransportOrdersRepository';
import { LoadingSkeletonTableRows } from '../../components/LoadingSkeletonTableRows';
import { TRANSPORT_ORDERS_LIMIT } from './transport-orders-consts';
import { buildFiltersFromQuery, buildQueryFromFilters } from './transport-orders-filters';
import { TransportOrdersFilters } from './TransportOrdersFilters';
import { TransportOrdersTableHeader } from './TransportOrdersTableHeader';
import { TransportOrdersTableRow } from './TransportOrdersTableRow';


export const TransportOrders = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const showProfit = searchParams.get('showProfit');

    const filters = buildFiltersFromQuery(searchParams);

    const { data: transportOrders, refetch, isLoading, isRefetching } = useTransportOrdersRepository(filters);

    const handleItemChange = async () => {
        await refetch();
    };

    const handleFiltersChange = (newFilters?: TransportOrdersFiltersType) => {
        const buildFilters = () => {
            const builtQueryFromFilters = buildQueryFromFilters(newFilters);
            const builtShowProfit = showProfit ? `showProfit=${showProfit}` : '';

            if (!showProfit) {
                return builtQueryFromFilters;
            }

            if (!builtQueryFromFilters) {
                return builtShowProfit;
            }

            return `${builtQueryFromFilters}&${builtShowProfit}`;
        }

        setSearchParams(buildFilters());
    };

    const handleShowProfitCheckboxCheck = (newValue: boolean) => {
        setSearchParams((currentSearchParams) => {
            currentSearchParams.set('showProfit', newValue ? 'on' : 'off');
            return currentSearchParams;
        })
    }

    return <>
        <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex flex-row">
                <PageTitle>Zlecenia transportowe</PageTitle>
                <RefreshButton className="ms-2" isRefreshing={isLoading || isRefetching} refreshFunction={refetch} />
            </div>

            <div>
                <Link to="/new-transport-order">
                    <Button><PlusLg />{' '}Nowe zlecenie</Button>
                </Link>
            </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
            <TransportOrdersFilters className="mb-2" filters={filters} onFiltersChange={handleFiltersChange} />
            <div className="d-flex align-items-end">
                <Form.Check
                    type="checkbox"
                    label="Pokaż marżę"
                    onChange={(e) => handleShowProfitCheckboxCheck(e.target.checked)}
                    checked={showProfit === 'on'}
                />
            </div>
        </div>
        <Table striped bordered hover>
            <TransportOrdersTableHeader />
            <tbody>
            {!transportOrders ? <LoadingSkeletonTableRows rows={6} columns={8} /> :
                transportOrders.map((transportOrder) => <TransportOrdersTableRow
                    key={`transport-order-${transportOrder.id}`}
                    transportOrdersItem={transportOrder}
                    onItemChange={handleItemChange}
                    showProfit={showProfit === 'on'}
                />)}
            </tbody>
        </Table>
        {transportOrders?.length === 0 &&
            <p className="d-flex align-self-center">Brak zleceń.{' '}
                <Link to="/new-transport-order">
                    <Button className="p-0 border-0 ms-2 text-decoration-none" variant="link">Utwórz
                        zlecenie</Button>
                </Link>
            </p>}
        {transportOrders?.length === TRANSPORT_ORDERS_LIMIT && <p className="text-center fst-italic">
            Wyświetlono {TRANSPORT_ORDERS_LIMIT} ostatnich zleceń. Użyj filtrów lub wyszukiwarki by wyświetlić starsze zlecenia
        </p>}
    </>;
};
