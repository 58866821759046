import { ReactNode, useState } from 'react';
import { Files, Gear, House, People, Truck } from 'react-bootstrap-icons';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

type SidebarNavLinkProps = {
    icon: ReactNode,
    collapse: boolean,
    text: string,
    to: string
    className?: string,
}

const SidebarNavLink = ({ icon, collapse, text, to, className = '' }: SidebarNavLinkProps) => (
    <Nav.Link className={`text-nowrap ps-3 ${className}`} as={Link} to={to}>
        {icon}{' '}<span className={`ps-2 ${collapse ? 'd-none' : ''}`}>{text}</span>
    </Nav.Link>
);

export const Sidebar = () => {
    const [collapse, setCollapse] = useState(true);

    const handleCollapse = () => setCollapse(!collapse);

    const show = () => setCollapse(false);
    const hide = () => setCollapse(true);

    return <Nav
        className="flex-column h-100"
        onMouseOverCapture={show}
        onMouseOutCapture={hide}
    >
        <Nav.Item onClick={handleCollapse} className="bg-white p-3 pb-2 text-nowrap">
            <Truck className="fs-2" />{' '}
            <span className={`fs-5 text-nowrap ${collapse ? 'd-none' : ''}`}>RabbitTMS</span>
        </Nav.Item>
        <SidebarNavLink className="border-1 border-bottom" icon={<House className="fs-4" />} text="Home" to="/" collapse={collapse} />
        <SidebarNavLink icon={<Files className="fs-4" />} text="Zlecenia transportowe" to="/transport-orders" collapse={collapse} />
        <SidebarNavLink icon={<Truck className="fs-4" />} text="Przewoźnicy" to="/contractors" collapse={collapse} />
        <SidebarNavLink className="d-none" icon={<People className="fs-4" />} text="Klienci" to="/clients" collapse={collapse} />
        <SidebarNavLink className="border-1 border-top" icon={<Gear className="fs-4" />} text="Ustawienia" to="/settings" collapse={collapse} />
    </Nav>;
};
