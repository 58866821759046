import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Client } from '../../domain/client';

type ClientLinkProps = {
    className?: string,
    clientId: string,
    children: ReactNode
}
const ClientLink = ({ className, clientId, children }: ClientLinkProps) => (
    <Link
        onClick={e => e.stopPropagation()}
        className={`text-decoration-none ${className}`}
        to={`/clients/${clientId}`}
    >
        {children}
    </Link>
);

type ClientTableRowProps = {
    client: Client,
    index: number,
}

// TODO accept new transport order from clientId
export const ClientTableRow = ({ client, index }: ClientTableRowProps) => (
    <tr>
        <td className="text-center">{index + 1}</td>
        <td>
            <ClientLink clientId={client.id}>{client.name}</ClientLink>
        </td>
        <td>{client.address}</td>
        <td>{client.nip}</td>
        <td>{client.phoneNumber}</td>
        <td>{client.email}</td>
        <td className="width-0 text-nowrap">
            <Link to={`/new-transport-order?clientId=${client.id}`} className={`text-decoration-none`}>
                Nowe zlecenie
            </Link>
        </td>
    </tr>
);
