import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ContractorFile } from '../../../domain/contractorFile';
import {
    useContractorPresignedFileUrlRepository,
} from '../../../repository/contractor-files/useContractorPresignedFileUrlRepository';
import {
    useDownloadContractorFileRepository,
} from '../../../repository/contractor-files/useDownloadContractorFileRepository';
import {
    useRemoveContractorFileRepository,
} from '../../../repository/contractor-files/useRemoveContractorFileRepository';
import { fileSizeTextFromBytes } from '../../../utils/fileSizeTextFromBytes';

type ContractorFilesRowProps = {
    contractorId: string;
    file: ContractorFile;
}

export const ContractorFilesRow = ({ contractorId, file }: ContractorFilesRowProps) => {
    const { getPresignedUrl } = useContractorPresignedFileUrlRepository(contractorId, file.name);
    const { getDownloadContractorFileUrl } = useDownloadContractorFileRepository(contractorId, file.path, file.name);
    const { removeFile } = useRemoveContractorFileRepository(contractorId);

    const handleFileClick = async () => {
        const presignedUrl = await getPresignedUrl();

        window.open(presignedUrl, '_blank');
    };

    const handleFileDownload = async () => {
        const downloadUrl = await getDownloadContractorFileUrl();

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = file.name;
        link.click();
    };

    const handleRemoveFileClick = async () => {
        await removeFile(file.name);

        toast.success('Pomyślnie usunięto plik');
    };

    return (
        <tr key={file.path}>
            <td className="text-nowrap">{file.date.toLocaleString().replaceAll('/', '.')}</td>
            <td className="text-nowrap">{fileSizeTextFromBytes(file.byteSize)}</td>
            <td>
                <Button className="p-0" variant="link" onClick={handleFileClick}>
                    {file.name}
                </Button>
            </td>
            <td>
                <Button variant="link" className="p-0" onClick={handleFileDownload}>
                    Pobierz
                </Button>
            </td>
            <td className="text-nowrap">
                <Button className="p-0" variant="link" onClick={handleRemoveFileClick}>
                    Usuń
                </Button>
            </td>
        </tr>
    );
};
