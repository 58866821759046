import { ContractorTransportOrder } from '../../../domain/transportOrder';
import { GetTransportOrderDto } from './getTransportOrderDto';

export type GetContractorTransportOrderDto = GetTransportOrderDto & {
    contractor: NonNullable<GetTransportOrderDto['contractor']>
}

export const toDomain = (dto: GetContractorTransportOrderDto): ContractorTransportOrder => ({
    ...dto,
    creationDate: new Date(dto.creationDate),
    loading: dto.loading.map((loadingItem) => ({
        ...loadingItem,
        date: new Date(loadingItem.date),
        endDate: new Date(loadingItem.endDate),
    })),
    unloading: dto.unloading.map((unloadingItem) => ({
        ...unloadingItem,
        date: new Date(unloadingItem.date),
        endDate: new Date(unloadingItem.endDate),
    })),
});
