import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { TransportOrderFile } from '../../../../domain/transportOrderFile';
import {
    useDownloadTransportOrderFileRepository
} from '../../../../repository/transport-order-files/useDownloadTransportOrderFileRepository';
import {
    useRemoveTransportOrderFileRepository
} from '../../../../repository/transport-order-files/useRemoveTransportOrderFileRepository';
import {
    useTransportOrderPresignedFileUrlRepository
} from '../../../../repository/transport-order-files/useTransportOrderPresignedFileUrlRepository';
import { fileSizeTextFromBytes } from '../../../../utils/fileSizeTextFromBytes';

type TransportOrderFilesRowProps = {
    transportOrderId: string;
    file: TransportOrderFile;
}

export const TransportOrderFilesRow = ({ transportOrderId, file }: TransportOrderFilesRowProps) => {
    const { getDownloadTransportOrderFileUrl } = useDownloadTransportOrderFileRepository(transportOrderId, file.path, file.name);
    const { getPresignedUrl } = useTransportOrderPresignedFileUrlRepository(transportOrderId, file.name);
    const { removeFile } = useRemoveTransportOrderFileRepository(transportOrderId);

    const handleFileClick = async () => {
        const presignedUrl = await getPresignedUrl();

        window.open(presignedUrl, '_blank');
    };

    const handleFileDownload = async () => {
        const downloadUrl = await getDownloadTransportOrderFileUrl();

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = file.name;
        link.click();
    };

    const handleRemoveFileClick = async () => {
        await removeFile(file.name);

        toast.success('Pomyślnie usunięto plik');
    };

    return (
        <tr key={file.path}>
            <td className="text-nowrap">{file.date.toLocaleString().replaceAll('/', '.')}</td>
            <td className="text-nowrap">{fileSizeTextFromBytes(file.byteSize)}</td>
            <td>
                <Button className="p-0" variant="link" onClick={handleFileClick}>
                    {file.name}
                </Button>
            </td>
            <td>
                <Button variant="link" className="p-0" onClick={handleFileDownload}>
                    Pobierz
                </Button>
            </td>
            <td className="text-nowrap">
                <Button className="p-0" variant="link" onClick={handleRemoveFileClick}>
                    Usuń
                </Button>
            </td>
        </tr>
    );
};
