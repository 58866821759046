import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompanyDetails } from '../../domain/companyDetails';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useChangeCompanyDetailsRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changeCompanyDetails = useMutation({
        mutationFn: (newCompanyDetails: CompanyDetails) => changeCompanyDetailsFunction(newCompanyDetails),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['companySettings'] });
        }
    });

    const changeCompanyDetailsFunction = async (newCompanyDetails: CompanyDetails) => {
        const token = await getJwtToken();

        await fetch(`${BACKEND_URL}/settings`, {
            method: 'PATCH',
            body: JSON.stringify({
                companyDetails: newCompanyDetails,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
    };

    return {
        changeCompanyDetails: (newCompanyDetails: CompanyDetails) => changeCompanyDetails.mutateAsync(newCompanyDetails),
    };
};