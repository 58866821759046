import { CompanyDetails } from '../../../domain/companyDetails';


type CompanyDetailsChanges = {
    anyChanges: boolean;
    changes: Array<keyof CompanyDetails>;
}

export const useCompanyDetailsChanged = (companyDetails: CompanyDetails, originalCompanyDetails: CompanyDetails): CompanyDetailsChanges => {
    const checkStringField = (a: string, b: string, name: keyof CompanyDetails): Array<keyof CompanyDetails> => a !== b ? [name] : [];

    const changes: Array<keyof CompanyDetails> = [
        ...checkStringField(companyDetails.name, originalCompanyDetails.name, 'name'),
        ...checkStringField(companyDetails.address, originalCompanyDetails.address, 'address'),
        ...checkStringField(companyDetails.nip, originalCompanyDetails.nip, 'nip'),
        ...checkStringField(companyDetails.email, originalCompanyDetails.email, 'email'),
        ...checkStringField(companyDetails.phoneNumber, originalCompanyDetails.phoneNumber, 'phoneNumber'),
    ];

    return {
        anyChanges: changes.length > 0,
        changes
    };
};