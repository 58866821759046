import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { ActionItem } from '../../../components/ActionItem';
import { EditableComponent } from '../../../components/EditableComponent';
import { FormElement } from '../../../components/FormElement';
import { TextareaFormGroup } from '../../../components/TextareaFormGroup';
import { TransportOrderPolicy as TransportOrderPolicyType } from '../../../domain/transportOrderPolicy';
import {
    useChangeTransportOrderPolicyRepository,
} from '../../../repository/settings/useChangeTransportOrderPolicyRepository';
import { TransportOrderPolicyFormItem, useTransportOrderPolicyForm } from './useTransportOrderPolicyForm';


type TransportOrderPolicyProps = {
    originalTransportOrderPolicy: TransportOrderPolicyType;
}

export const TransportOrderPolicy = ({ originalTransportOrderPolicy }: TransportOrderPolicyProps) => {
    const { transportOrderPolicy, setTransportOrderPolicy } = useTransportOrderPolicyForm(originalTransportOrderPolicy);
    const { changeTransportOrderPolicy } = useChangeTransportOrderPolicyRepository();

    const handleChangeItem = (changedItem: TransportOrderPolicyFormItem) => {
        setTransportOrderPolicy(transportOrderPolicy.map((item) => {
            if (item.id === changedItem.id) {
                return changedItem;
            }
            return item;
        }));
    };

    const handleAddNewItemClick = () => {
        setTransportOrderPolicy([
            ...transportOrderPolicy,
            {
                name: '',
                value: '',
                editMode: true,
                id: uuidv4(),
            }
        ]);
    };

    const handleItemRemove = (itemToRemove: TransportOrderPolicyFormItem) => {
        setTransportOrderPolicy(transportOrderPolicy.filter(({ id }) => id !== itemToRemove.id))
    }

    const handleSubmitButtonClick = async () => {
        await changeTransportOrderPolicy(transportOrderPolicy.map(({ name, value }) => ({ name, value })));

        toast.success('Pomyślnie zmieniono OWU');
    };

    return <>
        <FormElement headingText="OWU">
            {transportOrderPolicy.map((policyItem) => (
                <div key={policyItem.id} className="d-flex flex-row">
                    <TextareaFormGroup
                        className="flex-grow-1"
                        label={<PolicyName
                            policyItem={policyItem}
                            onItemChange={(changedItem) => handleChangeItem(changedItem)} />}
                        value={policyItem.value}
                        rows={5}
                        placeholder="Treść"
                        onChange={(text) => handleChangeItem({...policyItem, value: text})}
                    />
                    <ActionItem className="ms-2" onClick={() => handleItemRemove(policyItem)}>
                        <Trash />
                    </ActionItem>
                </div>))}
        </FormElement>
        <div className="d-flex justify-content-center">
            <Button variant="link" onClick={() => handleAddNewItemClick()} className="text-decoration-none">
                + dodaj
            </Button>
        </div>
        <div className="d-flex flex-row justify-content-end">
            <Button variant="primary" onClick={handleSubmitButtonClick}>Zapisz</Button>
        </div>
    </>;
};

type PolicyNameProps = {
    policyItem: TransportOrderPolicyFormItem;
    onItemChange: (changedItem: TransportOrderPolicyFormItem) => void;
}

const PolicyName = ({ policyItem, onItemChange }: PolicyNameProps) => {
    const handleEditClick = () => {
        onItemChange({
            ...policyItem,
            editMode: true,
        });
    };

    const handleNameChange = (newName: string) => {
        onItemChange({
            ...policyItem,
            name: newName,
        });
    };

    if (policyItem.editMode) {
        return <Form.Control
            type="text"
            placeholder="Nagłówek"
            value={policyItem.name}
            onChange={(e) => handleNameChange(e.target.value)}
        />;
    }

    return <EditableComponent
        onEditClick={() => handleEditClick()}>
        {policyItem.name}
    </EditableComponent>;
};