import { createContext, ReactNode, useContext } from 'react';
import { UserAccount } from '../../domain/userAccount';
import { useFindUserDetailsRepository } from '../../repository/users/useFindUserDetailsRepository';

const LoggedInUserContext = createContext<{ loggedInUser: UserAccount } | null>(null);

type LoggedInUserProviderProps = {
    children: ReactNode;
}

export const LoggedInUserProvider = ({ children }: LoggedInUserProviderProps) => {
    const { data: loggedInUser } = useFindUserDetailsRepository();

    if (!loggedInUser) {
        return null;
    }

    const value = {
        loggedInUser
    };

    return <LoggedInUserContext.Provider value={value}>
        {children}
    </LoggedInUserContext.Provider>
}

export const useLoggedInUser = () => {
    const context = useContext(LoggedInUserContext);

    if (!context) {
        throw new Error('useLoggedInUser must be used within an LoggedInUserProvider');
    }

    return context;
}