import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useRemoveContractorFileRepository = (contractorId: string) => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const removeFile = useMutation({
        mutationFn: (fileName: string) => removeFileFunction(fileName),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['contractors', 'files', { id: contractorId }] });
        }
    });

    const removeFileFunction = async (filename: string) => {
        const token = await getJwtToken();

        await fetch(`${BACKEND_URL}/contractors/${contractorId}/files/${filename}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    return {
        removeFile: (fileName: string) => removeFile.mutateAsync(fileName),
    };
};
