import { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

type TwoColumnsProps = {
    children: [ReactNode, ReactNode];
    columnClassName?: string;
    className?: string;
}

export const TwoColumns = ({ children: [leftColumn, rightColumn], columnClassName, className = '' }: TwoColumnsProps) => (
    <Row className={className}>
        <Col className={`pe-2 ${columnClassName}`} xs="6">
            {leftColumn}
        </Col>
        <Col className={`ps-2 ${columnClassName}`} xs="6">
            {rightColumn}
        </Col>
    </Row>
);
