export const ContractorsTableHeader = () => (
    <thead>
    <tr>
        <th className="text-center">#</th>
        <th>Nazwa</th>
        <th>Adres</th>
        <th>Nip</th>
        <th>Telefon</th>
        <th>E-Mail</th>
        <th />
    </tr>
    </thead>
);
