import React, { useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { FormElement } from '../../../../components/FormElement';
import { TransportOrder } from '../../../../domain/transportOrder';
import {
    useListFilesByTransportOrderIdRepository,
} from '../../../../repository/transport-order-files/useListFilesByTransportOrderIdRepository';
import {
    useUploadFilesToTransportOrderRepository,
} from '../../../../repository/transport-order-files/useUploadFilesToTransportOrderRepository';
import { LoadingSkeletonTableRows } from '../../../../components/LoadingSkeletonTableRows';
import { TransportOrderFilesRow } from './TransportOrderFilesRow';

type DropzoneAreaProps = {
    onNewFileAdded: (numberOfFiles: number) => Promise<void>;
    transportOrderId: string;
    className?: string;
}

const DropzoneArea = ({ onNewFileAdded, transportOrderId, className }: DropzoneAreaProps) => {
    const [isUploading, setIsUploading] = useState(false);
    const { uploadFiles } = useUploadFilesToTransportOrderRepository();

    const onDrop = async (acceptedFiles: Array<File>) => {
        try {
            setIsUploading(true);
            await uploadFiles({ transportOrderId: transportOrderId, files: acceptedFiles });
            await onNewFileAdded(acceptedFiles.length);
        } catch (e) {
            console.log('Error while uploading files', e);
        }
        setIsUploading(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <div
            className={`d-flex justify-content-center border-1 border-secondary border-dotted p-2 py-4 ${!isUploading && 'cursor-pointer'} ${className || ''}`} {...getRootProps()}>
            {isUploading && <Spinner className="text-center" animation="border" role="status" />}
            {!isUploading && <>
                <input {...getInputProps()} />
                <p className="d-flex m-0">Przeciągnij pliki, lub kliknij + aby dodać</p>
            </>}
        </div>
    );
};

type TransportOrderFilesProps = {
    fetchedTransportOrder: TransportOrder;
}

export const TransportOrderFiles = ({ fetchedTransportOrder }: TransportOrderFilesProps) => {
    const { data: files } = useListFilesByTransportOrderIdRepository(fetchedTransportOrder.id);

    const filesList = files?.map((file) => ({
        name: file.name,
        path: file.path,
        date: file.date,
        byteSize: file.byteSize,
        checked: false,
    }));

    const handleNewFilesAdded = async (numberOfFiles: number) => {
        toast.success(`Pomyślnie dodano ${numberOfFiles > 1 ? 'pliki' : 'plik'}`);
    };

    return (
        <Card>
            <Card.Body>
                <FormElement headingText="Pliki">
                    <>
                        <Table bordered hover striped>
                            <thead>
                            <tr>
                                <th>Dodane</th>
                                <th>Rozmiar</th>
                                <th className="w-100">Nazwa</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {filesList === undefined ?
                                <LoadingSkeletonTableRows rows={5} columns={5} /> : filesList.map((file) => (
                                    <TransportOrderFilesRow transportOrderId={fetchedTransportOrder.id} file={file} />
                                ))}
                            </tbody>
                        </Table>
                        {filesList && <DropzoneArea
                            onNewFileAdded={handleNewFilesAdded}
                            className="mb-3"
                            transportOrderId={fetchedTransportOrder.id} />}
                    </>
                </FormElement>
            </Card.Body>
        </Card>
    );
};