import { Card } from 'react-bootstrap';
import { Copy } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Client } from '../../domain/client';

type ContractorActionsCardProps = {
    client: Client;
}

// TODO accept new transport order from clientId
export const ClientActionsCard = ({ client }: ContractorActionsCardProps) => (
    <Card className="mb-auto">
        <Card.Body>
            <ul className="list-unstyled mb-0">
                <li>
                    <Link className="p-0 text-decoration-none" to={`/new-transport-order?clientId=${client.id}`}>
                        <Copy className="me-2" />Nowe zlecenie
                    </Link>
                </li>
            </ul>
        </Card.Body>
    </Card>
);
