import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';

type EditableTextProps = {
    children: ReactNode
    onEditClick?: () => void;
    className?: string;
}

const noop = () => {
};

export const EditableComponent = ({ children, className, onEditClick = noop }: EditableTextProps) => (
    <div className={className}>
        {children}
        <div className="d-inline position-relative">
            <Button
                className="p-0 px-1 pb-4 position-absolute"
                onClick={onEditClick}
                style={{ top: -10 }}
                variant="link"
            >
                <Pencil />
            </Button>
        </div>
    </div>
);
