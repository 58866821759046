import { Dropdown } from 'react-bootstrap';
import { TransportOrderStatus } from '../domain/transportOrder';
import { transportOrderStatusesMapping } from '../utils/transportOrderStatusesMapping';
import { TransportOrderStatusBadge } from './TransportOrderStatusBadge';


const stornoTransitionItem: { status: TransportOrderStatus, message: string } = {
    status: 'STORNO',
    message: 'Storno',
};

const statusTransitions: {
    [Status in TransportOrderStatus]: Array<{
        message: string;
        status: TransportOrderStatus
    }>
} = {
    DRAFT: [{ status: 'CREATED', message: 'Nadaj numer' }, stornoTransitionItem],
    CREATED: [{ status: 'LOADED', message: 'Potwierdź załadunek' }, stornoTransitionItem],
    LOADED: [{ status: 'UNLOADED', message: 'Potwierdź rozładunek' }, stornoTransitionItem],
    UNLOADED: [{ status: 'DONE', message: 'Sfinalizuj' }, stornoTransitionItem],
    DONE: [],
    STORNO: [{ status: 'CREATED', message: 'Ponownie otwórz' }],
};

type EditableTransportOrderBadgeProps = {
    status: TransportOrderStatus,
    onStatusChange: (newStatus: TransportOrderStatus) => void;
}

export const EditableTransportOrderBadge = ({ status, onStatusChange }: EditableTransportOrderBadgeProps) => {
    const { variant, message } = transportOrderStatusesMapping[status];

    const transitions = statusTransitions[status];

    const handleStatusChangeClick = async (newStatus: TransportOrderStatus) => {
        onStatusChange(newStatus);
    };

    if (transitions.length === 0) {
        return <TransportOrderStatusBadge status={status} />;
    }

    return (
        <Dropdown onClick={(e) => {
            e.stopPropagation();
        }}>
            <Dropdown.Toggle
                className={`text-uppercase px-2 py-1 fs-12px fw-bold border-0 ${variant === 'info' ? 'text-white' : ''}`}
                variant={variant}
            >
                {message}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {transitions.map(({ status, message }) =>
                    <Dropdown.Item key={`${status}-${message}`} onClick={() => handleStatusChangeClick(status)}>{message}</Dropdown.Item>,
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};
