import { Button, Spinner } from 'react-bootstrap';

type NewTransportOrderButtonsProps = {
    onSaveDraftButtonClick: () => void;
    onSubmitButtonClick: () => void;
    transportOrderSubmitting: boolean;
    draftTransportOrderSubmitting: boolean;
}

export const NewTransportOrderButtons = ({
    onSaveDraftButtonClick,
    onSubmitButtonClick,
    transportOrderSubmitting,
    draftTransportOrderSubmitting
    }: NewTransportOrderButtonsProps) => (
    <div className={`d-flex flex-row justify-content-end align-items-baseline py-3 sticky-bottom bg-body-tertiary`}>
        <Button disabled={transportOrderSubmitting || draftTransportOrderSubmitting} className="me-3" variant="outline-primary" onClick={onSaveDraftButtonClick}>
            {draftTransportOrderSubmitting ? <Spinner /> : 'Zapisz jako szkic'}
        </Button>
        <Button disabled={transportOrderSubmitting || draftTransportOrderSubmitting} variant="primary" onClick={onSubmitButtonClick}>
            {transportOrderSubmitting ? <Spinner /> : 'Zapisz i nadaj numer'}
        </Button>
    </div>
);
