import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { TransportOrderFile } from '../../domain/transportOrderFile';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDER_FILES_PATH } from '../paths';

export type TransportOrderFileDto = {
    name: string;
    path: string;
    lastModified: number;
    byteSize: number;
}

export const useListFilesByTransportOrderIdRepository = (transportOrderId: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['transportOrders', 'files', { id: transportOrderId }],
        placeholderData: keepPreviousData,
        queryFn: async (): Promise<Array<TransportOrderFile>> => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDER_FILES_PATH(transportOrderId)}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const filesFromResponse = await response.json() as Array<TransportOrderFileDto>;

            return filesFromResponse.map((file): TransportOrderFile => ({
                name: file.name,
                path: `${BACKEND_URL}${file.path}`,
                date: new Date(file.lastModified),
                byteSize: file.byteSize,
            }));
        },
    });

    return {
        ...query,
    };
};