import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Contractor } from '../../domain/contractor';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';

export const useContractorRepository = (contractorId: string) => {
    const { getJwtToken } = useAuthentication();

    const query = useQuery({
        queryKey: ['contractors', { id: contractorId }],
        staleTime: 45 * 60 * 1000, // 45 minutes
        placeholderData: keepPreviousData,
        queryFn: async () => {
            const token = await getJwtToken();

            const response = await fetch(`${BACKEND_URL}/contractors/${contractorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`${response.status}`);
            }

            return await response.json() as Contractor;
        },
    });

    return {
        ...query,
    };
};