import { Button, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthentication } from '../modules/auth/AuthenticationProvider';
import { useLoggedInUser } from '../modules/users/LoggedInUserProvider';
import { useSettingsRepository } from '../repository/settings/useSettingsRepository';

export const LoggedInUserTopNavbar = () => {
    const { currentUser, logout } = useAuthentication();
    const { loggedInUser } = useLoggedInUser();
    const { data: settings } = useSettingsRepository();

    const handleLogoutClick = () => {
        logout();
        toast.success('Pomyślnie wylogowano')
    }

    const loggedInUserName = `${loggedInUser.firstName} ${loggedInUser.lastname}`

    return <Navbar className="d-flex flex-grow-1 bg-body-secondary px-3">
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-between">
            <Navbar.Text className="fw-bold">
                {settings?.companyDetails.name}
            </Navbar.Text>
            <Navbar.Text>
                {currentUser && <div className="d-flex flex-row">
                    <p className="m-0">Zalogowany: <Link to="#">{loggedInUserName}</Link></p>
                    <Button className="ms-2 p-0 border-0" variant="link" onClick={handleLogoutClick}>Wyloguj</Button>
                </div>}
                {!currentUser && `Zaloguj się`}
            </Navbar.Text>
        </Navbar.Collapse>
    </Navbar>;
};

export const NotLoggedInUserTopNavbar = () => {
    return <Navbar className="d-flex flex-grow-1 bg-body-secondary px-3">
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
                Zaloguj się
            </Navbar.Text>
        </Navbar.Collapse>
    </Navbar>;
};
