import { TextFormGroup } from '../../../components/TextFormGroup';
import { FormElement } from '../../../components/FormElement';
import { TransportOrder } from '../../../domain/transportOrder';

type DriverType = TransportOrder['driver'];

type DriverProps = {
    driver: DriverType;
    setDriver: (newValue: DriverType) => void;
}
export const Driver = ({ driver, setDriver }: DriverProps) => {
    const handleDriverChange = (updatedDriver: DriverType) => {
        setDriver(updatedDriver);
    };

    return (
        <FormElement className="w-75" headingText="Kierowca">
            <TextFormGroup
                label="Imie i nazwisko"
                value={driver.name}
                onChange={(newValue) => handleDriverChange({ ...driver, name: newValue })}
            />
            <TextFormGroup
                label="Numer kontaktowy"
                value={driver.phoneNumber}
                onChange={(newValue) => handleDriverChange({ ...driver, phoneNumber: newValue })}
            />
            <TextFormGroup
                label="Numer dowodu osobistego"
                value={driver.identityCardNumber}
                onChange={(newValue) => handleDriverChange({ ...driver, identityCardNumber: newValue })}
            />
        </FormElement>
    );
};