import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { FormElement } from '../../../components/FormElement';
import { TextareaFormGroup } from '../../../components/TextareaFormGroup';
import { TextFormGroup } from '../../../components/TextFormGroup';
import { CompanyDetails as CompanyDetailsType } from '../../../domain/companyDetails';
import { useChangeCompanyDetailsRepository } from '../../../repository/settings/useChangeCompanyDetailsRepository';
import { CompanyLogo } from './CompanyLogo';
import { useCompanyDetailsChanged } from './useCompanyDetailsChanged';
import { useCompanyDetailsForm } from './useCompanyDetailsForm';

type CompanyDetailsProps = {
    originalCompanyDetails: CompanyDetailsType;
}

export const CompanyDetails = ({ originalCompanyDetails }: CompanyDetailsProps) => {
    const { companyDetails, setCompanyDetails } = useCompanyDetailsForm(originalCompanyDetails);
    const { anyChanges } = useCompanyDetailsChanged(companyDetails, originalCompanyDetails);

    const { changeCompanyDetails } = useChangeCompanyDetailsRepository();

    const handleCompanyDetailsItemChange = (change: Partial<CompanyDetailsType>) => {
        setCompanyDetails({
            ...companyDetails,
            ...change,
        });
    };

    const handleSubmitButtonClick = async () => {
        await changeCompanyDetails(companyDetails);

        toast.success('Pomyślnie zmieniono dane firmowe');
    };

    return <>
        <div className="d-flex flex-row">
            <FormElement className="flex-grow-1" headingText="Dane firmy">
                <TextFormGroup
                    className="w-50"
                    label="Nazwa"
                    value={companyDetails.name}
                    onChange={(value) => handleCompanyDetailsItemChange({ name: value })}
                />
                <TextareaFormGroup
                    className="w-50"
                    label="Adres firmy"
                    value={companyDetails.address}
                    onChange={(value) => handleCompanyDetailsItemChange({ address: value })}
                    rows={3}
                />
                <TextFormGroup
                    className="w-50"
                    label="NIP"
                    value={companyDetails.nip}
                    onChange={(value) => handleCompanyDetailsItemChange({ nip: value })}
                />
                <TextFormGroup
                    className="w-50"
                    label="Numer telefonu"
                    value={companyDetails.phoneNumber}
                    onChange={(value) => handleCompanyDetailsItemChange({ phoneNumber: value })}
                />
                <TextFormGroup
                    className="w-50"
                    label="Adres email"
                    value={companyDetails.email}
                    onChange={(value) => handleCompanyDetailsItemChange({ email: value })}
                />
            </FormElement>
            <FormElement className="flex-grow-1" headingText="Logo">
                <CompanyLogo />
            </FormElement>
        </div>
        <div className="d-flex flex-row justify-content-end">
            <Button disabled={!anyChanges} variant="primary" onClick={handleSubmitButtonClick}>Zapisz</Button>
        </div>
    </>;
};
