import { ErrorTypes, WarningTypes } from './newTransportOrderValidation';

export const ERROR_MESSAGES: { [key in ErrorTypes]: string } = {
    LAST_LOADING_AFTER_LAST_UNLOADING: 'Data załadunku jest po dacie rozładunku',
    EMPTY_LOAD_DETAILS: 'Niepoprawnie wypełnione dane o ładunku - nazwa lub wartość jest pusta',
    LOADING_END_DATE_BEFORE_START_DATE: 'Niepoprawna data załadunku',
    UNLOADING_END_DATE_BEFORE_START_DATE: 'Niepoprawna data rozładunku',
};

export const WARNING_MESSAGES: { [key in WarningTypes]: string } = {
    LOADING_DATE_IN_PAST: 'Data załadunku jest w przeszłości',
    UNLOADING_DATE_IN_PAST: 'Data rozładunku jest w przeszłości',
    UNLOADING_DATE_TOO_MANY_DAYS_AFTER_LOADING: 'Data rozładunku jest odległa w porównaniu do załadunku',
    NO_LOAD_DETAILS_PROVIDED: 'Dane o załadunku są puste',
    LOADING_ADDRESS_EMPTY: 'Adres załadunku jest pusty',
    UNLOADING_ADDRESS_EMPTY: 'Adres rozładunku jest pusty',
    NO_CLIENT_VALUE: 'Brak ceny klienta. Uniemożliwi to oblicznie marży'
};
