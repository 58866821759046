import { useEffect, useState } from 'react';

import { TransportOrder } from '../../../domain/transportOrder';
import { JustValue, MutableValue, OfMutable, OfValue } from '../../../utils/formTypes';

export type TransportOrderFormType = {
    id: JustValue<string>,
    orderId: JustValue<string>,
    creationDate: JustValue<Date>,
    status: JustValue<TransportOrder['status']>,
    creator: MutableValue<TransportOrder['creator']>,
    contractor: MutableValue<TransportOrder['contractor']>,
    driver: MutableValue<TransportOrder['driver']>,
    vehicle: MutableValue<TransportOrder['vehicle']>,
    loading: MutableValue<TransportOrder['loading']>,
    unloading: MutableValue<TransportOrder['unloading']>,
    loadDetails: MutableValue<TransportOrder['loadDetails']>,
    freight: MutableValue<TransportOrder['freight']>,
    additionalInfo: MutableValue<string>,
    comment: MutableValue<string>,
}

export const toDomain = (transportOrderForm: TransportOrderFormType): TransportOrder => ({
    id: transportOrderForm.id.value,
    orderId: transportOrderForm.orderId.value,
    creationDate: transportOrderForm.creationDate.value,
    creator: transportOrderForm.creator.value,
    contractor: transportOrderForm.contractor.value,
    driver: transportOrderForm.driver.value,
    vehicle: transportOrderForm.vehicle.value,
    loading: transportOrderForm.loading.value,
    unloading: transportOrderForm.unloading.value,
    loadDetails: transportOrderForm.loadDetails.value,
    freight: transportOrderForm.freight.value,
    status: transportOrderForm.status.value,
    additionalInfo: transportOrderForm.additionalInfo.value,
    comment: transportOrderForm.comment.value,
});

export const useTransportOrderForm = (transportOrder: TransportOrder): TransportOrderFormType => {
    const [creator, setCreator] = useState(transportOrder.creator);
    const [contractor, setContractor] = useState(transportOrder.contractor);
    const [driver, setDriver] = useState(transportOrder.driver);
    const [vehicle, setVehicle] = useState(transportOrder.vehicle);
    const [loading, setLoading] = useState(transportOrder.loading);
    const [unloading, setUnloading] = useState(transportOrder.unloading);
    const [loadDetails, setLoadDetails] = useState(transportOrder.loadDetails);
    const [freight, setFreight] = useState(transportOrder.freight);
    const [additionalInfo, setAdditionalInfo] = useState(transportOrder.additionalInfo);
    const [comment, setComment] = useState(transportOrder.comment);

    useEffect(() => {
        setCreator(transportOrder.creator);
        setContractor(transportOrder.contractor);
        setDriver(transportOrder.driver);
        setVehicle(transportOrder.vehicle);
        setLoading(transportOrder.loading);
        setUnloading(transportOrder.unloading);
        setLoadDetails(transportOrder.loadDetails);
        setFreight(transportOrder.freight);
        setAdditionalInfo(transportOrder.additionalInfo);
        setComment(transportOrder.comment);
    }, [transportOrder]);


    return {
        id: OfValue(transportOrder.id),
        orderId: OfValue(transportOrder.orderId),
        creationDate: OfValue(transportOrder.creationDate),
        creator: OfMutable(creator, setCreator),
        contractor: OfMutable(contractor, setContractor),
        driver: OfMutable(driver, setDriver),
        vehicle: OfMutable(vehicle, setVehicle),
        loading: OfMutable(loading, setLoading),
        unloading: OfMutable(unloading, setUnloading),
        loadDetails: OfMutable(loadDetails, setLoadDetails),
        freight: OfMutable(freight, setFreight),
        status: OfValue(transportOrder.status),
        additionalInfo: OfMutable(additionalInfo, setAdditionalInfo),
        comment: OfMutable(comment, setComment),
    };
};
