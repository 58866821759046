import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import {
    Copy,
    FileEarmark,
    FileEarmarkCheck,
    FileEarmarkPlus,
    FileEarmarkText,
    FileEarmarkX,
    FiletypePdf,
    Trash3,
    Truck,
    TruckFlatbed,
} from 'react-bootstrap-icons';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionItem } from '../../../components/ActionItem';

import { TransportOrder } from '../../../domain/transportOrder';
import { useCompanyLogoRepository } from '../../../repository/company-logo/useCompanyLogoRepository';
import { useSettingsRepository } from '../../../repository/settings/useSettingsRepository';
import {
    useUploadFilesToTransportOrderRepository,
} from '../../../repository/transport-order-files/useUploadFilesToTransportOrderRepository';
import {
    useChangeTransportOrderStatusRepository,
} from '../../../repository/transport-orders/useChangeTransportOrderStatusRepository';
import {
    useRemoveTransportOrderRepository,
} from '../../../repository/transport-orders/useRemoveTransportOrderRepository';
import { TransportOrderPdfCreator } from './pdf/useTransportOrderPdfPrint';

type TransportOrderActionsCardProps = {
    transportOrder: TransportOrder;
}

export const TransportOrderActionsCard = ({ transportOrder }: TransportOrderActionsCardProps) => {
    const navigate = useNavigate();
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const { uploadFiles } = useUploadFilesToTransportOrderRepository();
    const { data: settings } = useSettingsRepository();
    const { data: logo } = useCompanyLogoRepository();

    const handlePDFCreationClick = () => {
        const { transportOrderPolicy, companyDetails, transportOrderConfig } = settings!;

        const pdfCreator = new TransportOrderPdfCreator(transportOrder, companyDetails, transportOrderConfig, transportOrderPolicy, logo!);

        pdfCreator.create();
        pdfCreator.download();

        pdfCreator.document.output()

        const pdfFile = new File(
            [pdfCreator.document.output('blob')],
            pdfCreator.getFilename()
        );

        uploadFiles({ transportOrderId: transportOrder.id, files: [pdfFile]});
    };

    const handleCreateSimilarClick = () => {
        navigate({
            pathname: '/new-transport-order',
            search: createSearchParams({
                transportOrderId: transportOrder.id,
            }).toString(),
        });
    };

    const toStornoDetails: ToStatusDetails = {
        status: 'STORNO',
        actionLabel: 'Storno',
        Icon: <FileEarmarkX className="me-1 width-20" />,
    }

    const toNextStatusDetails = STATUS_MAP[transportOrder.status]?.toStatus;
    const toPreviousStatusDetails = STATUS_MAP[transportOrder.status]?.toPreviousStatus;

    return <>
        <Card className="mb-auto">
            <Card.Body>
                <ul className="list-unstyled">
                    <li className="mb-2">
                        <ActionItem disabled={!(transportOrder.status !== 'DRAFT' && settings)} onClick={handlePDFCreationClick}>
                            <FiletypePdf className="me-1 width-20" />Pobierz PDF zlecenia
                        </ActionItem>
                    </li>
                    {toNextStatusDetails &&
                        <li className="mb-2">
                            <ChangeStatusActionItem
                                transportOrderId={transportOrder.id}
                                statusDetails={toNextStatusDetails} />
                        </li>
                    }
                    {!(['DRAFT', 'DONE', 'STORNO'].includes(transportOrder.status)) &&
                        <li className="mb-2">
                            <ChangeStatusActionItem
                                transportOrderId={transportOrder.id}
                                statusDetails={toStornoDetails} />
                        </li>
                    }
                    <li className="mb-2">
                        <ActionItem onClick={handleCreateSimilarClick}>
                            <Copy className="me-1 width-20" />Utwórz podobne zlecenie
                        </ActionItem>
                    </li>
                    {toPreviousStatusDetails &&
                        <li className="mb-2">
                            <ChangeStatusActionItem
                                transportOrderId={transportOrder.id}
                                statusDetails={toPreviousStatusDetails} />
                        </li>
                    }
                    {transportOrder.status === 'DRAFT' && <li className="mb-2">
                        <ActionItem
                            onClick={() => setRemoveModalOpen(true)}>
                            <Trash3 className="me-1 width-20" />Usuń zlecenie
                        </ActionItem>
                    </li>}
                </ul>
            </Card.Body>
        </Card>
        <RemoveTransportOrderModal
            show={removeModalOpen}
            onClose={() => setRemoveModalOpen(false)}
            transportOrderId={transportOrder.id}
        />
    </>;
};

type ToStatusDetails = {
    status: TransportOrder['status'];
    actionLabel: string;
    Icon: JSX.Element;
}

type StatusDetails = {
    toStatus?: ToStatusDetails;
    toPreviousStatus?: ToStatusDetails;
} | undefined;

const STATUS_MAP: { [key in TransportOrder['status']]: StatusDetails } = {
    DRAFT: {
        toStatus: {
            status: 'CREATED',
            actionLabel: 'Nadaj numer',
            Icon: <FileEarmarkPlus className="me-1 width-20" />,
        },
    },
    CREATED: {
        toStatus: {
            status: 'LOADED',
            actionLabel: 'Potwierdź załadunek',
            Icon: <Truck className="me-1 width-20" />,
        },
    },
    LOADED: {
        toStatus: {
            status: 'UNLOADED',
            actionLabel: 'Potwierdź rozładunek',
            Icon: <TruckFlatbed className="me-1 width-20" />,
        },
        toPreviousStatus: {
            status: 'CREATED',
            actionLabel: 'Cofnij do: W trakcie',
            Icon: <FileEarmarkText className="me-1 width-20" />,
        },
    },
    UNLOADED: {
        toStatus: {
            status: 'DONE',
            actionLabel: 'Sfinalizuj',
            Icon: <FileEarmarkCheck className="me-1 width-20" />,
        },
        toPreviousStatus: {
            status: 'LOADED',
            actionLabel: 'Cofnij do: Załadowane',
            Icon: <Truck className="me-1 width-20" />,
        },
    },
    DONE: {
        toPreviousStatus: {
            status: 'UNLOADED',
            actionLabel: 'Cofnij do: Rozładowane',
            Icon: <TruckFlatbed className="me-1 width-20" />,
        }
    },
    STORNO: {
        toStatus: {
            status: 'CREATED',
            actionLabel: 'Ponownie otwórz',
            Icon: <FileEarmark className="me-1 width-20" />,
        },
    },
};

type ChangeStatusActionItemProps = {
    transportOrderId: string;
    statusDetails: ToStatusDetails;
};

const ChangeStatusActionItem = (props: ChangeStatusActionItemProps) => {
    const {
        transportOrderId,
        statusDetails,
    } = props;

    const { changeTransportOrderStatus } = useChangeTransportOrderStatusRepository();

    if (!statusDetails) {
        return null;
    }

    const handleStatusChange = async () => {
        await changeTransportOrderStatus({ transportOrderId: transportOrderId, newStatus: statusDetails.status });
        toast.success('Pomyślnie zmieniono status zlecenia');
    };

    return <ActionItem onClick={handleStatusChange}>
        {statusDetails.Icon}{statusDetails.actionLabel}
    </ActionItem>;
};

type RemoveTransportOrderModalProps = {
    transportOrderId: string;
    show: boolean;
    onClose: () => void;
}
const RemoveTransportOrderModal = ({ transportOrderId, show, onClose }: RemoveTransportOrderModalProps) => {
    const navigate = useNavigate();
    const { removeTransportOrder } = useRemoveTransportOrderRepository();

    const handleRemoveTransportOrder = async () => {
        await removeTransportOrder(transportOrderId);

        toast.success('Pomyślnie usunięto zlecenie');
        onClose();

        navigate({
            pathname: '/transport-orders',
        });

    };

    return <Modal show={show} size="lg" onHide={() => onClose()}>
        <Modal.Header closeButton className="p-3">
            <Modal.Title>Usuń zlecenie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Czy na pewno chcesz usunąć zlecenie?
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onClose()}>
                Anuluj
            </Button>
            <Button variant="primary" onClick={handleRemoveTransportOrder}>
                Usuń zlecenie
            </Button>
        </Modal.Footer>
    </Modal>;
};