import { CognitoUser, AuthenticationDetails, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'eu-central-1_TB4VRy2P7',
    ClientId: '7cspdp56fv81qup7gsh5qj42ev',
};

const userPool = new CognitoUserPool(poolData);

export const useAuthenticationRepository = () => {
    const signIn = (username: string, password: string) => {
        const authenticationData = {
            Username: username,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        const userData = {
            Username: username,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: resolve,
                onFailure: (error) => reject(error),
            });
        });
    };

    const sendForgottenPasswordLink = (email: string): Promise<void> => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: (result) => resolve(result),
                onFailure: (error) => reject(error),
            })
        });
    };

    const changePassword = (email: string, confirmationCode: string, newPassword: string): Promise<void> => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(confirmationCode, newPassword, {
                onSuccess: (result) => {
                    console.log(result)
                    resolve()
                },
                onFailure: (error) => reject(error),
            });
        });
    };

    const signOut = () => {
        const currentUser = userPool.getCurrentUser();

        if (!currentUser) {
            console.error('No user present. Nothing to log-out');
            return;
        }

        currentUser.signOut();
    };

    const getCurrentUser = () => {
        return userPool.getCurrentUser();
    };

    const getJwtToken = (): Promise<string> => {
        const currentUser = userPool.getCurrentUser();

        if (!currentUser) {
            console.log('No user present');
            return Promise.reject(new Error('No user present'));
        }

        return new Promise((resolve, reject) => {
            currentUser.getSession((error: any, session: CognitoUserSession) => {
                if (error) {
                    console.error('Error while getting session of curent user', error);
                    reject(error);
                    return;
                }
                const token = session.getAccessToken().getJwtToken();

                resolve(token);
            });
        });
    };

    const refreshSession = () => new Promise((resolve, reject) => {
        const currentUser = userPool.getCurrentUser();

        if (!currentUser) {
            console.error('No user present');
            reject('No user present');
            return;
        }

        currentUser.getSession((error: any, session: CognitoUserSession) => {
            if (error) {
                console.error('Error while getting session of current user', error);
                reject(error);
                return;
            }

            currentUser.refreshSession(session.getRefreshToken(), (error: any, session: CognitoUserSession) => {
                if (error) {
                    console.error('Error while refreshing session', error);
                    reject(error);
                    return;
                }

                resolve(session);
            });
        });
    });

    return {
        signIn,
        signOut,
        sendForgottenPasswordLink,
        changePassword,
        getCurrentUser,
        getJwtToken,
        refreshSession,
    };
};
