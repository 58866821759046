import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageTitle } from '../../../components/PageTitle';
import { TwoColumns } from '../../../components/TwoColumns';
import { TransportOrder } from '../../../domain/transportOrder';
import { useContractorsRepository } from '../../../repository/contractors/useContractorsRepository';
import {
    useCreateTransportOrderRepository,
} from '../../../repository/transport-orders/useCreateTransportOrderRepository';
import { AdditionalInfo } from '../components/AdditionalInfo';
import { Comment } from '../components/Comment';
import { TransportOrderContractorMutable } from '../components/contractor/TransportOrderContractorMutable';
import { CreatedBy } from '../components/CreatedBy';
import { Driver } from '../components/Driver';
import { Freight, FreightProfit } from '../components/Freight';
import { LoadDetails } from '../components/LoadDetails';
import { ShipmentLoading } from '../components/ShipmentLoading';
import { ShipmentUnloading } from '../components/ShipmentUnloading';
import { Vehicle } from '../components/Vehicle';
import { CreateTransportOrderWithWarningsConfirmationModal } from './CreateTransportOrderWithWarningsConfirmationModal';
import { NewTransportOrderButtons } from './NewTransportOrderButtons';
import { newTransportOrderValidation, WarningTypes } from './newTransportOrderValidation';

import { useNewTransportOrderForm } from './useNewTransportOrderForm';
import { ERROR_MESSAGES } from './validation-messages';

type NewTransportOrderProps = {
    initialTransportOrder?: Partial<TransportOrder>;
}

export const NewTransportOrder = ({ initialTransportOrder }: NewTransportOrderProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDraftSubmitting, setIsDraftSubmitting] = useState(false);
    const [warnings, setWarnings] = useState<Array<{ type: WarningTypes }>>([]);

    const navigate = useNavigate();

    const { data: newTransportOrderForm, toCreationDto } = useNewTransportOrderForm(initialTransportOrder);
    const { createTransportOrder, createDraftTransportOrder } = useCreateTransportOrderRepository();
    const { data: contractors = [] } = useContractorsRepository();

    const submitForm = async () => {
        try {
            setIsSubmitting(true);
            const creationDto = toCreationDto(newTransportOrderForm);
            const { id, orderId } = await createTransportOrder(creationDto);

            toast.success(`Zlecenie utworzone pomyślnie. Nadany numer to ${orderId}`);

            navigate(`/transport-orders/${id}`);
        } catch (e) {
            toast.error('Błąd podczas tworzenia zlecenia: ' + e);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleNewTransportSubmitClick = async () => {
        const creationDto = toCreationDto(newTransportOrderForm);
        const validation = newTransportOrderValidation(creationDto);

        if (validation.errors.length > 0) {
            validation.errors.forEach(({ type }) => {
                toast.error(ERROR_MESSAGES[type], {
                    autoClose: 15 * 1000, // 15 seconds
                });
            });

            return;
        }

        if (validation.warnings.length > 0) {
            setWarnings(validation.warnings);
            return;
        }

        await submitForm();
    };

    const handleNewTransportDraftClick = async () => {
        try {
            setIsDraftSubmitting(true);
            const creationDto = toCreationDto(newTransportOrderForm);
            const { id } = await createDraftTransportOrder(creationDto);

            toast.success('Szkic zlecenia utworzony pomyślnie');
            navigate(`/transport-orders/${id}`);
        } catch (e) {
            toast.error('Błąd podczas tworzenia szkicu zlecenia: ' + e);
        } finally {
            setIsDraftSubmitting(false);
        }
    };

    return (
        <>
            {warnings.length > 0 && <CreateTransportOrderWithWarningsConfirmationModal
                warnings={warnings}
                submitButtonSpinner={isSubmitting}
                onSubmit={submitForm}
                onCancel={() => setWarnings([])}
            />}
            <div className="mb-4">
                <PageTitle className="mb-4">Nowe zlecenie</PageTitle>
            </div>
            <div className="d-flex flex-row">
                <Card className="mb-3 flex-grow-1 me-2">
                    <Card.Body>
                        <CreatedBy
                            creator={newTransportOrderForm.creator.value}
                            setCreator={newTransportOrderForm.creator.setValue}
                        />
                    </Card.Body>
                </Card>
                <Card className="mb-3 flex-grow-2 ms-2">
                    <Card.Body>
                        <TransportOrderContractorMutable
                            contractors={contractors}
                            contractor={newTransportOrderForm.contractor.value}
                            setContractor={newTransportOrderForm.contractor.setValue}
                        />
                    </Card.Body>
                </Card>
            </div>
            <TwoColumns>
                <Card className="mb-3">
                    <Card.Body>
                        <ShipmentLoading
                            shipmentLoading={newTransportOrderForm.loading.value}
                            setShipmentLoading={newTransportOrderForm.loading.setValue}
                            context={{ isTransportOrderEdit: false }}
                        />
                    </Card.Body>
                </Card>
                <Card className="mb-3">
                    <Card.Body>
                        <ShipmentUnloading
                            shipmentUnloading={newTransportOrderForm.unloading.value}
                            setShipmentUnloading={newTransportOrderForm.unloading.setValue}
                            context={{ isTransportOrderEdit: false }}
                        />
                    </Card.Body>
                </Card>
            </TwoColumns>
            <Card className="mb-3">
                <Card.Body>
                    <LoadDetails
                        loadDetails={newTransportOrderForm.loadDetails.value}
                        setLoadDetails={newTransportOrderForm.loadDetails.setValue}
                    />
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <TwoColumns>
                        <Driver
                            driver={newTransportOrderForm.driver.value}
                            setDriver={newTransportOrderForm.driver.setValue}
                        />
                        <Vehicle
                            vehicle={newTransportOrderForm.vehicle.value}
                            setVehicle={newTransportOrderForm.vehicle.setValue}
                        />
                    </TwoColumns>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <TwoColumns>
                        <Freight
                            freight={newTransportOrderForm.freight.value}
                            setFreight={newTransportOrderForm.freight.setValue} />
                        <FreightProfit
                            freight={newTransportOrderForm.freight.value}
                            setFreight={newTransportOrderForm.freight.setValue} />
                    </TwoColumns>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <AdditionalInfo
                        additionalInfo={newTransportOrderForm.additionalInfo.value}
                        setAdditionalInfo={newTransportOrderForm.additionalInfo.setValue}
                    />
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Comment
                        comment={newTransportOrderForm.comment.value}
                        setComment={newTransportOrderForm.comment.setValue}
                    />
                </Card.Body>
            </Card>
            <NewTransportOrderButtons
                transportOrderSubmitting={isSubmitting}
                draftTransportOrderSubmitting={isDraftSubmitting}
                onSubmitButtonClick={handleNewTransportSubmitClick}
                onSaveDraftButtonClick={handleNewTransportDraftClick}
            />
        </>
    );
};
